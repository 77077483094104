import React, { useState } from "react";
import Sidebar from "../Header/Sidebar";
import Spinner from "../../Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGears } from "@fortawesome/free-solid-svg-icons";
import AdminNavbar from "../Navbar/AdminNavbar";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";

function ResetPassword() {
  const [isChecked, setIsChecked] = useState([false, false]);

  const handleCheckboxChange = (index) => {
    const newCheckboxes = [...isChecked];
    newCheckboxes[index] = !newCheckboxes[index];
    setIsChecked(newCheckboxes);
  };

  
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({
    password: "",
    confirmPassword: "",
  });

  // Separate showPassword states for each password field
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const currentYear = moment().format("YYYY");


  return (
    <>
      <ToastContainer />

      <Spinner show={loading} />
      <div id="main-wrapper">
        <AdminNavbar />
        <Sidebar />
        <div className="content-body">
          <div className="container-fluid">
            <div className="row invoice-card-row  mt-4">
              <div className="col-xl-12 col-xxl-12 col-lg-12">
                <br></br>
                <div className="filter cm-content-box box-primary">
                  <div className="content-title">
                    <div className="fs-24">
                      <div className="py-3 bg-transparent">
                        <h5 className="mb-0 d-inline">Setting</h5>
                        <FontAwesomeIcon
                          icon={faGears}
                          className="fa-2x "
                          style={{ width: "25px" }}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="cm-content-body form excerpt"
                    style={{ overflowY: "scroll", height: "600px" }}
                  >
                    <div className="card-body">
                      <div className="py-3 bg-transparent">
                        <fieldset>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <label className="fw-bold mb-2 mt-2 user-info">
                                  Password
                                </label>
                                <div className="input-group">
                                  <input
                                    type={showPassword1 ? "text" : "password"}
                                    name="password"
                                    value={userInfo.password}
                                    className="form-control"
                                    placeholder="Enter Password"
                                  />
                                  <span
                                    className="input-group-text"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      setShowPassword1(!showPassword1)
                                    }
                                  >
                                    {showPassword1 ? (
                                      <span role="img" aria-label="Visible">
                                        👁️
                                      </span>
                                    ) : (
                                      <span role="img" aria-label="Hidden">
                                        🙈
                                      </span>
                                    )}
                                  </span>
                                </div>
                              </div>
                              <br />
                              <label className="fw-bold mb-2 mt-2 user-info">
                                Confirm Password
                              </label>
                              <div className="input-group">
                                <input
                                  type={showPassword2 ? "text" : "password"}
                                  name="confirmPassword"
                                  value={userInfo.confirmPassword}
                                  className="form-control"
                                  placeholder="Confirm Password"
                                />
                                <span
                                  className="input-group-text"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    setShowPassword2(!showPassword2)
                                  }
                                >
                                  {showPassword2 ? (
                                    <span role="img" aria-label="Visible">
                                      👁️
                                    </span>
                                  ) : (
                                    <span role="img" aria-label="Hidden">
                                      🙈
                                    </span>
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        </fieldset>

                        <form>
                          {/* ... rest of the form ... */}
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group float-end">
                                <br />
                                <div className="col-md-12 container text-center">
                                  <button
                                    type="submit"
                                    className="btn btn-secondary"
                                  >
                                    Reset Password
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="copyright">
            <p>
              Copyright © Designed &amp; Developed by{" "}
              <a href="#" target="_blank">
                Vixo
              </a>{" "}
              {currentYear}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
