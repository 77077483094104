
import React, { useState,useEffect } from "react";
import axios from "axios";
import { VixoAPI } from "../../settings";

const RightSidebar = () => {

    const [CustomerData, setCustomerData] = useState(null);

    const [BillableCalls, setBillableCalls] = useState(null);

    const [UserData, setUserData] = useState(null);


    useEffect(() => {
      LoadOrganization();
      const intervalId = setInterval(() => {
        LoadOrganization();
      }, 60000); 
      return () => clearInterval(intervalId);
    }, [])
    function LoadOrganization() {
      try {
        //setLoading(true);
        const SetDataById = `${VixoAPI.AdminDashboardAPI}getVixoDashboardDetail`;
        //setLoading(true);
        axios
          .get(SetDataById)
          .then((response) => {
            var response = response.data;
            if (response.statusCode === 200) {
              var Statdata = JSON.parse(JSON.stringify(response.data));
              setCustomerData(Statdata.totalCustomers);
              setBillableCalls(Statdata.totalBillableCalls);
              setUserData(Statdata.totalUsers)

             
            } else {
              //setError(response.message);
            }
          })
          .catch((error) => {
            //setLoading(false);
            throw error;
          })
          .finally(() => {
            //setLoading(false);
          });
      } catch (error) {
        //setLoading(false);
        throw error;
      }
    }
  return (
    <>
        <div
                className="col-xl-3 col-xxl-3 col-sm-12"
                style={{ marginTop: "6pc" }}
              >
                <div
                  style={{
                    height: "10pc",
                    backgroundColor: "rgb(226, 226, 245, 0.11)",
                  }}
                  className="card invoice-card gradient-bx  rounded position-unset"
                >
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className=" fs-18 mb-1">Total Users</p>
                        <div
                          className="d-flex flex-wrap"
                          style={{ flexDirection: "column" }}
                        >
                          <h2
                            className=" mb-0 me-3 invoice-num "
                            style={{ fontSize: "40px" }}
                          >
                           {UserData?.totalItems ?? "0"}
                          </h2>
                          <div className="d-flex">
                            <svg
                              width="28"
                              height="19"
                              viewBox="0 0 28 19"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M18.875 9.25C21.0787 11.6256 25.1753 16.0091 26.4375 17.5H1V1L10.625 13.375L18.875 9.25Z"
                                fill="url(#paint0_linear1)"
                              />
                              <path
                                d="M26.4375 17.5C25.1753 16.0091 21.0787 11.6256 18.875 9.25L10.625 13.375L1 1"
                                stroke="white"
                                stroke-width="2"
                              />
                              <defs>
                                <linearGradient
                                  id="paint0_linear1"
                                  x1="13.7188"
                                  y1="3.0625"
                                  x2="12.6875"
                                  y2="17.5"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop
                                    stop-color="white"
                                    stop-opacity="0.73"
                                    offset="0.1"
                                  />
                                  <stop
                                    offset="1"
                                    stop-color="white"
                                    stop-opacity="0"
                                  />
                                </linearGradient>
                              </defs>
                            </svg>
                            <div className="fs-14">
                              {" "}
                              <span style={{ color: "rgb(244, 107, 104)" }}>
                                {" "}
                                &nbsp; Upto {UserData?.progress ?? "0"}%
                              </span>{" "}
                              from the last week
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    height: "10pc",
                    backgroundColor: "rgb(226, 226, 245, 0.11)",
                  }}
                  className="card invoice-card gradient-bx   rounded position-unset"
                >
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className=" fs-18 mb-1">Customers</p>
                        <div
                          className="d-flex flex-wrap"
                          style={{ flexDirection: "column" }}
                        >
                          <h2
                            className=" mb-0 me-3 invoice-num"
                            style={{ fontSize: "40px" }}
                          >
                            {CustomerData?.totalItems ?? "0"}
                          </h2>
                          <div className="d-flex">
                            <svg
                              width="28"
                              height="19"
                              viewBox="0 0 28 19"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M18.875 9.25C21.0787 11.6256 25.1753 16.0091 26.4375 17.5H1V1L10.625 13.375L18.875 9.25Z"
                                fill="url(#paint0_linear1)"
                              />
                              <path
                                d="M26.4375 17.5C25.1753 16.0091 21.0787 11.6256 18.875 9.25L10.625 13.375L1 1"
                                stroke="white"
                                stroke-width="2"
                              />
                              <defs>
                                <linearGradient
                                  id="paint0_linear1"
                                  x1="13.7188"
                                  y1="3.0625"
                                  x2="12.6875"
                                  y2="17.5"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop
                                    stop-color="white"
                                    stop-opacity="0.73"
                                    offset="0.1"
                                  />
                                  <stop
                                    offset="1"
                                    stop-color="white"
                                    stop-opacity="0"
                                  />
                                </linearGradient>
                              </defs>
                            </svg>
                            <div className="fs-14">
                              {" "}
                              <span style={{ color: "rgb(104, 137, 244)" }}>
                                {" "}
                                &nbsp; Upto  {CustomerData?.progress ?? "0"}%
                              </span>{" "}
                              from the last week
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    height: "10pc",
                    backgroundColor: "rgb(226, 226, 245, 0.11)",
                  }}
                  className="card invoice-card gradient-bx   rounded position-unset"
                >
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className=" fs-18 mb-1">Total Billable Calls</p>
                        <div
                          className="d-flex flex-wrap"
                          style={{ flexDirection: "column" }}
                        >
                          <h2
                            className=" mb-0 me-3 invoice-num"
                            style={{ fontSize: "40px" }}
                          >
                            {BillableCalls?.totalItems ?? "0"}
                          </h2>
                          <div className="d-flex">
                            <svg
                              width="28"
                              height="19"
                              viewBox="0 0 28 19"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M18.875 9.25C21.0787 11.6256 25.1753 16.0091 26.4375 17.5H1V1L10.625 13.375L18.875 9.25Z"
                                fill="url(#paint0_linear1)"
                              />
                              <path
                                d="M26.4375 17.5C25.1753 16.0091 21.0787 11.6256 18.875 9.25L10.625 13.375L1 1"
                                stroke="white"
                                stroke-width="2"
                              />
                              <defs>
                                <linearGradient
                                  id="paint0_linear1"
                                  x1="13.7188"
                                  y1="3.0625"
                                  x2="12.6875"
                                  y2="17.5"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop
                                    stop-color="white"
                                    stop-opacity="0.73"
                                    offset="0.1"
                                  />
                                  <stop
                                    offset="1"
                                    stop-color="white"
                                    stop-opacity="0"
                                  />
                                </linearGradient>
                              </defs>
                            </svg>
                            <div className="fs-14">
                              {" "}
                              <span style={{ color: "rgb(206, 184, 58)" }}>
                                {" "}
                                &nbsp; Upto {BillableCalls?.progress ?? "0"}%
                              </span>{" "}
                              from the last week
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
    </>
  );
};

export default RightSidebar;
