import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AdminNavbar from "../Navbar/AdminNavbar";
import Sidebar from "../Header/Sidebar";
import "./Call.css";
import moment from "moment";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VixoAPI } from "../../settings";
import { ToastContainer, toast } from "react-toastify";
import DateComponent from "../VixoFormats/Date";
import Spinner from "../../Spinner";
import { format } from "date-fns";
import {useNavigate } from "react-router-dom";
import { CheckAllowComponentAndRedirect } from "../../Authentication";
import { APIHeader } from "../../APICallWrapper";
import { HasLoggedInUser } from "../../APICallWrapper";
const RecentActivities = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedValue, setSelectedValue] = useState();
  const [selectedOption, setSelectedOption] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [selectedOptionorgnization, setselectedOptionorgnization] =
    useState("");

  const [RecentActivtiyData, setRecentActivtiyData] = useState([]);
  const [AllOrganizations, SetAllOrganizations] = useState([]);

  const navigate = useNavigate();
  const ComponentName = "Recent Activities";

  // dropdowns
  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    CheckAllowComponentAndRedirect(ComponentName, navigate);
    GetAllOrganizationsFromAPI();
  }, []);

  useEffect(() => {
    handleDateRange();
  }, [selectedOption]);

  useEffect(() => {
    if (selectedOptionorgnization) {
      getRecentActivtiy(selectedOptionorgnization);
    }
  }, [selectedOptionorgnization, startDate, endDate]);
  useEffect(() => {
    setSelectedOption("1");
  }, []);

  const handleDateRange = () => {
    const today = new Date();
    switch (selectedOption) {
      case "1":
        // This Month
        setStartDate(new Date(today.getFullYear(), today.getMonth(), 1));
        setEndDate(today);
        break;
      case "2":
        // Last Month
        setStartDate(new Date(today.getFullYear(), today.getMonth() - 1, 1));
        setEndDate(new Date(today.getFullYear(), today.getMonth(), 0));
        break;
      case "3":
        // Last Three Months
        setStartDate(new Date(today.getFullYear(), today.getMonth() - 3, 1));
        setEndDate(new Date(today.getFullYear(), today.getMonth(), 0));
        break;
      case "4":
        // Last Six Months
        setStartDate(new Date(today.getFullYear(), today.getMonth() - 6, 1));
        setEndDate(new Date(today.getFullYear(), today.getMonth(), 0));
        break;
      case "5":
        // Last Nine Months
        setStartDate(new Date(today.getFullYear(), today.getMonth() - 9, 1));
        setEndDate(new Date(today.getFullYear(), today.getMonth(), 0));
        break;
      case "6":
        // This Year
        setStartDate(new Date(today.getFullYear(), 0, 1));
        setEndDate(new Date(today.getFullYear(), 11, 31));
        break;
      case "8":
        // Custom Date, do nothing as the date picker will handle it
        break;
      case "9":
        // All Time, set the earliest and latest possible dates
        setStartDate(new Date(2010, 0, 1));
        setEndDate(today);
        break;
      default:
    }
  };

  const handleDropdownChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
  };

  const handleOrganizationDropdownChange = (event) => {
    setselectedOptionorgnization(event.target.value);
  };

  useEffect(() => {
    // This will run when the component mounts
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id"); // Get the value of 'id' from the URL query string

    if (id) {
      setselectedOptionorgnization(id);
      getRecentActivtiy(id);
    }
  }, []);

  useEffect(() => {
    // Fetch organizations data when component mounts
    GetAllOrganizationsFromAPI();
  }, []); // Empty dependency array to run once on mount

  useEffect(() => {
    
    GetAllOrganizationsFromAPI();
  }, [selectedOptionorgnization]); 

  const setOrganizationsData = (apiResponseData) => {
    if (apiResponseData != null && apiResponseData.length > 0) {
      const organizations = [];
      apiResponseData.forEach((element) => {
        // Check if organization with the same ID already exists in the list
        const isExists = organizations.some((org) => org.Id === element.id);
        if (!isExists) {
          // Add organization to the list if not already exists
          organizations.push({
            Id: element.id,
            Display: element.name,
          });
        }
      });
      // Set the list of organizations in the state
      SetAllOrganizations(organizations);

      // Set default selected organization ID if not already set
      if (!selectedOptionorgnization && organizations.length > 0) {
        return
        setselectedOptionorgnization(organizations[0].Id); // Set the ID of the first organization by default
      }
    }
  };

  function GetAllOrganizationsFromAPI() {
     if (HasLoggedInUser()) {
       try {
         setLoading(true);
         const SetDataById = `${VixoAPI.AdminDashboardAPI}getAllOrganizationsLookup`;
         //setLoading(true);
         axios
           .get(SetDataById, {
             headers: APIHeader().headers,
           })
           .then((response) => {
             var response = response.data;
             if (response.statusCode === 200) {
               setOrganizationsData(response.data);

               toast.success(response.message);
             } else {
               //setError(response.message);
             }
           })
           .catch((error) => {
             //setLoading(false);
             throw error;
           })
           .finally(() => {
             setLoading(false);
           });
       } catch (error) {
         setLoading(false);
         throw error;
       }
     } else {
       navigate("/");
     }
    //Get Orgnizations from OrganiationAPI
  }

  function getRecentActivtiy(organizationid) {
    if (HasLoggedInUser()) {
      try {
        setLoading(true);

        const apiUrl = `${VixoAPI.AdminDashboardAPI}getRecentActivtiy`;

        const requestData = {
          StartDate: moment(startDate, "YY MMM DO").format("YYYY-MM-DD"),
          EndDate: moment(endDate, "YY MMM DO").format("YYYY-MM-DD"),
          OrganizationID: organizationid,
        };

        axios
          .post(apiUrl, requestData, {
            headers: APIHeader().headers,
          })
          .then((response) => {
            const responseData = response.data;

            if (responseData.statusCode === 200) {
              setRecentActivtiyData(responseData.data);
            } else {
              toast.error(responseData.Message);
            }
          })
          .catch((error) => {
            toast.error("An error occurred while fetching data.");
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
        console.error("Error:", error);
        toast.error("An error occurred.");
      }
    } else {
      navigate("/");
    }
  }

  return (
    <>
      <AdminNavbar />
      <Sidebar />
      <Spinner show={loading} />
      <div className="content-body">
        <div className="container-fluid">
          <div className="row invoice-card-row  mt-4">
            <div style={{ textAlignLast: "center" }}>
              <img id="logo" src="image/3.png" style={{ width: "300px" }} />
            </div>
            {/* months with date dropdown */}
            <div className="d-flex align-items-center gap-1 my-3">
              <div className="start-date-picker d-flex align-items-center gap-1">
                {(selectedOption === "8" ||
                  selectedOption === "1" ||
                  selectedOption === "2" ||
                  selectedOption === "3" ||
                  selectedOption === "4" ||
                  selectedOption === "5" ||
                  selectedOption === "6") && (
                  <>
                    <label style={{ color: "white", fontSize: "14px" }}>
                      Start Date:{" "}
                    </label>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      disabled={selectedOption !== "8"}
                      dateFormat="MM-dd-yy"
                    />
                  </>
                )}
              </div>

              <div className="end-date-picker d-flex align-items-center gap-1">
                {(selectedOption === "8" ||
                  selectedOption === "1" ||
                  selectedOption === "2" ||
                  selectedOption === "3" ||
                  selectedOption === "4" ||
                  selectedOption === "5" ||
                  selectedOption === "6") && (
                  <>
                    <label style={{ color: "white", fontSize: "14px" }}>
                      End Date:{" "}
                    </label>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      disabled={selectedOption !== "8"}
                      dateFormat="MM-dd-yy"
                    />
                  </>
                )}
              </div>

              <div className="row text-center" style={{ marginLeft: "2rem" }}>
                <div className="text-end">
                  <select
                    className="dropdowntabmob h-80 rounded form-control"
                    value={selectedOption}
                    onChange={handleDropdownChange}
                  >
                    <option value="1">This Month</option>
                    <option value="2">Last Month</option>
                    <option value="3">Last Three Month</option>
                    <option value="4">Last Six Month</option>
                    <option value="5">Last Nine Month</option>
                    <option value="6">This Year</option>
                    <option value="8">Custom Date</option>
                    <option value="9">All Time</option>
                  </select>
                </div>
              </div>
            </div>
            <br></br>
            <div className="filter cm-content-box box-primary">
              <div className="content-title" style={{ padding: "5px" }}>
                <div className="cpa  fs-24">
                  <h3 className="card-title fs-26">Recent Activities</h3>
                </div>

                {/* orgnization dropdown */}
                <div className="d-flex align-items-center justify-content-between">
                  <div
                    className="row text-center"
                    style={{ marginLeft: "2rem" }}
                  >
                    <div className="text-end">
                      <div>
                        <select
                          style={{ alignContent: "end", border: "20%" }}
                          className="dropdowntabmob h-80 rounded form-control"
                          value={selectedOptionorgnization}
                          onChange={handleOrganizationDropdownChange}
                        >
                          {AllOrganizations.map((organization) => (
                            <option
                              key={organization.Id}
                              value={organization.Id}
                            >
                              {organization.Display}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="input-group search-area "
                  style={{ width: "16%" }}
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here..."
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                  />
                  <span className="input-group-text">
                    <a
                      href="javascript:void(0)"
                      style={{ textDecoration: "none" }}
                    >
                      <i className="flaticon-381-search-2"></i>
                    </a>
                  </span>
                </div>
              </div>
            </div>

            <div className="filter cm-content-box box-primary">
              <div
                className="form excerpt"
                style={{ overflowY: "scroll", height: "470px" }}
              >
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-responsive-sm mb-0">
                      <thead>
                        <tr>
                          <th>Date</th>

                          <th>Title</th>
                          <th>sub Title</th>
                          <th>Activity Type</th>
                        </tr>
                      </thead>

                      <tbody>
                        {RecentActivtiyData && RecentActivtiyData.length > 0 ? (
                          RecentActivtiyData.filter((item) => {
                            const name = item.organizationName
                              ? item.organizationName.toLowerCase()
                              : "";
                            const title = item.title
                              ? item.title.toString().toLowerCase()
                              : "";
                            const subTitle = item.subTitle
                              ? item.subTitle.toLowerCase()
                              : "";
                            const activityType = item.activityType
                              ? item.activityType.toLowerCase()
                              : "";

                            return (
                              title.includes(searchQuery.toLowerCase()) ||
                              subTitle.includes(searchQuery.toLowerCase()) ||
                              activityType.includes(searchQuery.toLowerCase())
                            );
                          }).map((item, index) => (
                            <tr key={index}>
                              <td>
                                {/* <DateComponent value={item.createdDate} /> */}
                                {format(
                                  new Date(item.createdDate),
                                  "yyyy-MM-dd"
                                )}
                              </td>

                              <td>{item.title}</td>
                              <td>{item.subTitle}</td>
                              <td>{item.activityType}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="9" className="text-center fs-3">
                              No data found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecentActivities;
