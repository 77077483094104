import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import AdminNavbar from "../Navbar/AdminNavbar";
import Sidebar from "../Header/Sidebar";
import "./Call.css";
import moment from "moment";
import { toast } from "react-toastify";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { VixoAPI } from "../../settings";
import Spinner from "../../Spinner";
import { Link, useNavigate } from "react-router-dom";
import { APIHeader } from "../../APICallWrapper";
import { HasLoggedInUser } from "../../APICallWrapper";
import { EmailEventType } from "../../Lookup";
const VixoEmails = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [VixoEmails, setVixoEmails] = useState(null);
  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = useState("");
 const [EmailEventTypeLookup, setEmailEventTypeLookup] = useState([]);
  //  lookup
  useEffect(() => {
    LoadLookups();
  }, []);
  async function LoadLookups() {
    try {
      EmailEventType()
        .then((result) => {
          setEmailEventTypeLookup(result);
          if (result.length > 0) {
            setSelectedOption(result[0].id);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (ex) {
      console.error(ex + "EmailEventType Lookup Call is not successfull");
    }
  }

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };
  useEffect(() => {
    if (VixoEmails == null) {
      fetchVixoEmails();
    }
  }, []);

  function fetchVixoEmails() {
    if (HasLoggedInUser()) {
      const OrgAPIPath = `${VixoAPI.AdminDashboardAPI}GetEmailList`;
      setLoading(true);
      axios
        .get(OrgAPIPath, {
          headers: APIHeader().headers,
        })
        .then((response) => {
          const responseData = response.data;
          if (responseData.statusCode === 200) {
            setVixoEmails(responseData.data);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      navigate("/");
    }
  }
  return (
    <>
      <AdminNavbar />
      <Sidebar />
      <Spinner show={loading} />
      <div className="content-body">
        <div className="container-fluid">
          <div className="row invoice-card-row  mt-4">
            <div style={{ textAlignLast: "center" }}>
              <img id="logo" src="image/3.png" style={{ width: "300px" }} />
            </div>
            <br></br>
            <div className="filter cm-content-box box-primary">
              <div className="content-title" style={{ padding: "5px" }}>
                <div className="cpa  fs-24">
                  <h3 className="card-title fs-26">Vixo Emails</h3>
                </div>

                {/* <div className="row text-center" style={{ marginLeft: "2rem" }}>
                  <div className="text-end">
                    <select
                      className="dropdowntabmob h-80 rounded form-control"
                      value={selectedOption}
                      onChange={handleSelectChange}
                    >
                      {EmailEventTypeLookup != null &&
                        EmailEventTypeLookup.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.displayValue}
                          </option>
                        ))}
                    </select>
                  </div>
                </div> */}
                <div
                  className="input-group search-area "
                  style={{ width: "16%" }}
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here..."
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                  />
                  <span className="input-group-text">
                    <a
                      href="javascript:void(0)"
                      style={{ textDecoration: "none" }}
                    >
                      <i className="flaticon-381-search-2"></i>
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <div className="my-3 d-flex align-items-center justify-content-end">
              <Link
                to="/CreateEmail"
                style={{ backgroundColor: " rgb(148, 97, 142)" }}
                className="btn btn-primary col-md-auto"
              >
                Create Email
              </Link>
              {/* Pass showModal and handleModalClose as props to AddUser */}
            </div>
            <div className="filter cm-content-box box-primary">
              <div
                className="form excerpt"
                style={{ overflowY: "scroll", height: "470px" }}
              >
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-responsive-sm mb-0">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Subject</th>
                          <th>Event</th>
                          <th>Edit</th>
                        </tr>
                      </thead>
                      <tbody>
                        {VixoEmails && VixoEmails.length > 0 ? (
                          VixoEmails.filter((item) => {
                            const EmailId = item.id ? item.id.toString() : "";
                            const subject = item.subject
                              ? item.subject.toString().toLowerCase()
                              : "";
                            return (
                              EmailId.includes(searchQuery.toLowerCase()) ||
                              subject.includes(searchQuery.toLowerCase())
                            );
                          }).map((item, index) => (
                            <tr key={index}>
                              <td>{item.id}</td>
                              <td>{item.subject}</td>
                              <td>{item.emailEventTypeName}</td>
                              <td>
                                <Link to={`/emailedit?id=${item.id}`}>
                                  <FontAwesomeIcon
                                    icon={faPenToSquare}
                                    className="fa-1x"
                                  />
                                </Link>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="9" className="text-center fs-3">
                              No data found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default VixoEmails;
