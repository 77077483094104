import React, { useState, useEffect } from "react";
import RightSidebar from "./Layout/RightSidebar";
import Sidebar from "./Header/Sidebar";
import Spinner from "../Spinner";
import { format } from "date-fns";
import { VixoAPI } from "../settings";
import axios from "axios";
import AdminNavbar from "./Navbar/AdminNavbar";
import moment from "moment";
import {useNavigate } from "react-router-dom";
import {CheckAllowComponentAndRedirect} from "../Authentication"
import { APIHeader } from "../APICallWrapper";
import { HasLoggedInUser } from "../APICallWrapper";
const ExceptionLogs = () => {

  const navigate = useNavigate();
  const ComponentName='Events Record';

  const [loading, setLoading] = useState(false);
  const [LogsData, setLogsData] = useState(null);
  const currentYear = moment().format("YYYY");

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    LoadLogs();
    CheckAllowComponentAndRedirect(ComponentName,navigate);
  }, []);
  function LoadLogs() {
     if (HasLoggedInUser()) {
       try {
         setLoading(true);
         const SetLogsData = `${VixoAPI.AdminDashboardAPI}getAllEventRecords`;
         //setLoading(true);
         axios
           .get(SetLogsData, {
             headers: APIHeader().headers,
           })
           .then((response) => {
             var response = response.data;
             if (response.statusCode === 200) {
               var Statdata = JSON.parse(JSON.stringify(response.data));
               setLogsData(Statdata.reverse());
             } else {
               //setError(response.message);
             }
           })
           .catch((error) => {
             //setLoading(false);
             throw error;
           })
           .finally(() => {
             setLoading(false);
           });
       } catch (error) {
         setLoading(false);
         throw error;
       }
     } else {
       navigate("/");
     }
  }

  const [expandedLog, setExpandedLog] = useState(null);

  const toggleContent = (index) => {
    setExpandedLog((prevIndex) => (prevIndex === index ? null : index));
  };

//   const truncateContent = (content) => {
//     const maxLength = 100;
//     return content.length > maxLength
//       ? `${content.slice(0, maxLength)}...`
//       : content;
//   };

  return (
    <>
      <div id="main-wrapper">
        <AdminNavbar />
        <Sidebar />

        <Spinner show={loading} />

        <div className="container">
          <div className="row invoice-card-row mt-4" style={{ padding: "2pc" }}>
            <div className="col-xl-12 col-xxl-12 col-lg-12">
              <div style={{ textAlignLast: "center" }}>
                <img id="logo" src="image/3.png" style={{ width: "300px" }} />
              </div>
              <br></br>
              <div className="filter cm-content-box box-primary">
                <div className="content-title">
                  <div className="cpa  fs-24">
                    <h3 className="card-title fs-26">Record Events</h3>
                  </div>
                  <div className="input-group search-area searchbar">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search here..."
                      value={searchQuery}
                      onChange={handleSearchInputChange}
                    />
                    <span className="input-group-text">
                      <a
                        href="javascript:void(0)"
                        style={{ textDecoration: "none" }}
                      >
                        <i className="flaticon-381-search-2"></i>
                      </a>
                    </span>
                  </div>
                </div>
                <div
                  className="cm-content-body form excerpt"
                  style={{ overflowY: "scroll", height: "600px" }}
                >
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-responsive-sm mb-0">
                        <thead>
                          <tr className="bg-secondary">
                            <th className="bold-text text-center">
                              Controller
                            </th>
                            <th className="bold-text text-center">End Point</th>
                            <th className="bold-text text-center">Message</th>
                            <th className="bold-text text-center">Reference</th>
                            <th className="bold-text text-center">
                              OrganizationID
                            </th>
                            <th className="bold-text text-center">UserID</th>
                            <th className="bold-text text-center">UserName</th>
                            <th className="bold-text text-center">
                              CustomerId
                            </th>
                            <th className="bold-text text-center">
                              CustomerURL
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {LogsData && LogsData.length > 0 ? (
                            LogsData.filter((item) => {
                              const name = item.controllerName
                                ? item.controllerName.toLowerCase()
                                : "";
                              const controllerEndPoint = item.controllerEndPoint
                                ? item.controllerEndPoint.toLowerCase()
                                : "";
                                 const controllerEventMessage =
                                   item.controllerEventMessage
                                     ? item.controllerEventMessage.toLowerCase()
                                     : "";
                                      const controllerEventName =
                                        item.controllerEventName
                                          ? item.controllerEventName.toLowerCase()
                                          : "";

                              return (
                                name.includes(searchQuery.toLowerCase()) ||
                                controllerEventMessage.includes(
                                  searchQuery.toLowerCase()
                                ) ||
                                controllerEventName.includes(
                                  searchQuery.toLowerCase()
                                ) ||
                                controllerEndPoint.includes(
                                  searchQuery.toLowerCase()
                                )
                              );
                            }).map((Logs, index) => (
                              <tr key={index}>
                                <td>{Logs.controllerName}</td>
                                <td className="text-center">
                                  {Logs.controllerEndPoint}
                                </td>
                                <td className="text-left">
                                  {" "}
                                  {Logs.controllerEventMessage}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {" "}
                                  {Logs.controllerEventName}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  OrganizationID
                                </td>
                                <td style={{ textAlign: "center" }}>UserID</td>
                                <td style={{ textAlign: "center" }}>
                                  USerName
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  CustomerId
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  CustomerURL
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="4" className="text-center fs-4">
                                No data found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer">
          <div className="copyright">
            <p>
              Copyright © Designed &amp; Developed by{" "}
              <a href="#" target="_blank">
                Vixo
              </a>{" "}
              {currentYear}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExceptionLogs;
