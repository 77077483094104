import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import InputMask from "react-input-mask";
import axios from "axios";
import { ValidateEmailGlobal, ValidatePhoneGlobal } from "../../Validation";
import { toast } from "react-toastify";
import Spinner from "../../../Spinner";
import { VixoAPI } from "../../../settings";
import { APIHeader, HasLoggedInUser } from "../../../APICallWrapper";
import { useNavigate } from "react-router-dom";

const AddPartner = ({
  showModalPartner,
  handleModalClose,
  
}) => {
  const [loading, setLoading] = useState(false);
  const [errorType, setErrorType] = useState(null);
  const [formValid, setFormValid] = useState(false);
  const [partner, setPartner] = useState({
    url: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });

    useEffect(() => {
      const validateForm = () => {
        if (
          partner.url !== "" &&
          partner.firstName !== "" &&
          partner.lastName !== "" &&
          partner.email !== "" &&
          partner.phone !== ""
        ) {
          setFormValid(true);
        } else {
          setFormValid(false);
        }
      };
      validateForm();
    }, [partner]);


  const [phoneIsValid, setPhoneIsValid] = useState(true);
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [emailError, setEmailError] = useState("");
  const navigate = useNavigate();
  const clearData = () => {
    setPartner({
      url: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
    });
    setPhoneIsValid(true);
    setEmailIsValid(true);
    setEmailError("");
    setErrorType("");
    handleModalClose();
  };

  const validatePhone = (phone) => {
    const isValid = ValidatePhoneGlobal(phone);
    return isValid;
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    setPartner({ ...partner, phone: value });
    setPhoneIsValid(validatePhone(value));
  };

  const validateEmail = (email) => {
    const isValid = ValidateEmailGlobal(email);
    if (!isValid) {
      setEmailError("Enter a valid email address");
    } else {
      setEmailError("");
    }
    return isValid;
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setPartner({ ...partner, email: value });
    setEmailIsValid(validateEmail(value));
  };

  const handleAddSubmit = async (e) => {
      if (HasLoggedInUser()) {
        e.preventDefault();

        const data = {
          FirstName: partner.firstName,
          LastName: partner.lastName,
          Name: `${partner.firstName} ${partner.lastName}`,
          Email: partner.email,
          phone: partner.phone,
          UserEmail: partner.email,
          Website: partner.url,
          Password: "",
          PhonePrimary: partner.phone,
        };

        try {
          setLoading(true);
          const response = await axios.post(
            VixoAPI.OrganizationAPI + "createPartner",
            data,
            { headers: APIHeader().headers }
          );

          if (response.status === 200) {
            toast.success(response.data.message);
            clearData();
            handleModalClose();
          } else {
            setErrorType(response.data.message);
          }
        } catch (error) {
          setLoading(false);
          throw error;
        } finally {
          setLoading(false);
        }
      } else {
        navigate("/");
      }
  };

  return (
    <>
      <Spinner show={loading} />
      <Modal
        show={showModalPartner}
        onHide={() => {
          clearData();
          handleModalClose();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title className="mx-auto">
            <img
              src="image/3.png"
              className="logo-icon d-block mx-auto"
              alt="logo icon"
              style={{ width: "150px", height: "70px" }}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            <h5 className="text-center">Add Partner</h5>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Web Url
              </label>
              <input
                type="text"
                className="form-control"
                id="url"
                name="url"
                value={partner.url}
                onChange={(e) =>
                  setPartner({ ...partner, url: e.target.value })
                }
              />
            </div>
            <div className="mb-3 row">
              <div className="col">
                <label htmlFor="firstName" className="form-label">
                  First Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  name="firstName"
                  value={partner.firstName}
                  onChange={(e) =>
                    setPartner({ ...partner, firstName: e.target.value })
                  }
                />
              </div>
              <div className="col">
                <label htmlFor="lastName" className="form-label">
                  Last Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  name="lastName"
                  value={partner.lastName}
                  onChange={(e) =>
                    setPartner({ ...partner, lastName: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="text"
                className="form-control"
                id="email"
                name="email"
                value={partner.email}
                onChange={(e) => handleEmailChange(e)}
                style={{ borderColor: emailIsValid ? "initial" : "red" }}
              />
              {emailIsValid ? null : (
                <span className="error">{emailError}</span>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="phone" className="form-label">
                Phone
              </label>
              <InputMask
                mask="(999) 999-9999"
                type="tel"
                name="phone"
                className="form-control"
                id="phone"
                placeholder="(xxx) xxx-xxxx"
                value={partner.phone}
                onChange={(e) => handlePhoneChange(e)}
                style={{ borderColor: phoneIsValid ? "initial" : "red" }}
              />
            </div>
            {!phoneIsValid && (
              <span className="error">
                Enter a valid phone number exactly 10 digits.
              </span>
            )}
            <div className="text-center">
              <button
                type="button"
                className="btn btn-primary mx-2"
                onClick={handleAddSubmit}
                disabled={!formValid}
              >
                Save
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddPartner;
