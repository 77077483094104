export const Environment = {
  ENV: process.env.REACT_APP_ENV, // 'DEV' ,'PRODUCTION' ,LOCAL
};

export const VixoAPIProduction = {
  DashBoard: "https://dashboard.vixolink.com/",
  LocalVixoDashboardDebug: "http://localhost:3000/",
  OrganizationAPI: "https://vixoapimanagement2.azure-api.net/vixoorg/",
  AuthenticationAPI: "https://vixoapimanagement2.azure-api.net/vixoauth/",
  CustomerAPI: "https://vixoapimanagement2.azure-api.net/vixocustomer/",
  UserAPI: "https://vixoapimanagement2.azure-api.net/vixouser/",
  AccountAPI: "https://vixoapimanagement2.azure-api.net/vixoaccount/",
  DocumentAPI: "https://vixoapimanagement2.azure-api.net/vixodoc/",
  StatmentAPI: "https://vixoapimanagement2.azure-api.net/vixostatement/",
  OCRAPI: "https://vixoapimanagement2.azure-api.net/VixoOCR/",
  AlertsAPI: "https://vixoapimanagement2.azure-api.net/vixoemail/",
  FinicityAPI: "https://vixoapimanagement2.azure-api.net/vixofinicity",
  FundingAPI: "https://vixoapimanagement2.azure-api.net/vixofunding/",
  LookupAPI: "https://vixoapimanagement2.azure-api.net/vixolookup/",
  AdminDashboardAPI: "https://vixoapimanagement2.azure-api.net/admindashboard/",
  NotificationAPI: "https://vixonotification.azurewebsites.net/",
};


  export const VixoAPIDev = {
    DashBoard: "https://devdashboard.vixolink.com/",
    LocalVixoDashboardDebug: "http://localhost:3000/",
    OrganizationAPI: "https://vixoapimanagement3.azure-api.net/vixoorg/",
    AuthenticationAPI: "https://vixoapimanagement3.azure-api.net/vixoauth/",
    CustomerAPI: "https://vixoapimanagement3.azure-api.net/vixocustomer/",
    UserAPI: "https://vixoapimanagement3.azure-api.net/vixouser/",
    AccountAPI: "https://vixoapimanagement3.azure-api.net/vixoaccount/",
    DocumentAPI: "https://vixoapimanagement3.azure-api.net/vixodoc/",
    StatmentAPI: "https://vixoapimanagement3.azure-api.net/vixostatement/",
    OCRAPI: "https://vixoapimanagement3.azure-api.net/VixoOCR/",
    AlertsAPI: "https://vixoapimanagement3.azure-api.net/vixoemail/",
    FinicityAPI: "https://vixoapimanagement3.azure-api.net/vixofinicity",
    FundingAPI: "https://vixoapimanagement3.azure-api.net/vixofunding/",
    LookupAPI: "https://vixoapimanagement3.azure-api.net/vixolookup/",
    AdminDashboardAPI:
      "https://vixoapimanagement3.azure-api.net/admindashboard/",
    NotificationAPI: "https://vixonotification.azurewebsites.net/",
  };

  export const VixoAPIStaging = {
    DashBoard: "https://stagingdashboard.vixolink.com/",
    LocalVixoDashboardDebug: "http://localhost:3000/",
    OrganizationAPI: "https://vixoapimanagement4.azure-api.net/vixoorg/",
    AuthenticationAPI: "https://vixoapimanagement4.azure-api.net/vixoauth/",
    CustomerAPI: "https://vixoapimanagement4.azure-api.net/vixocustomer/",
    UserAPI: "https://vixoapimanagement4.azure-api.net/vixouser/",
    AccountAPI: "https://vixoapimanagement4.azure-api.net/vixoaccount/",
    DocumentAPI: "https://vixoapimanagement4.azure-api.net/vixodoc/",
    StatmentAPI: "https://vixoapimanagement4.azure-api.net/vixostatement/",
    OCRAPI: "https://vixoapimanagement4.azure-api.net/VixoOCR/",
    AlertsAPI: "https://vixoapimanagement4.azure-api.net/vixoemail/",
    FinicityAPI: "https://vixoapimanagement4.azure-api.net/vixofinicity",
    FundingAPI: "https://vixoapimanagement4.azure-api.net/vixofunding/",
    LookupAPI: "https://vixoapimanagement4.azure-api.net/vixolookup/",
    AdminDashboardAPI:
      "https://vixoapimanagement4.azure-api.net/admindashboard/",
    NotificationAPI: "https://vixonotification.azurewebsites.net/",
  };

  export const VixoAPILocal = {
    DashBoard: "https://devdashboard.vixolink.com/",
    LocalVixoDashboardDebug: "http://localhost:3000/",
    OrganizationAPI: "https://vixoapimanagement3.azure-api.net/vixoorg/",
    AuthenticationAPI: "https://vixoapimanagement3.azure-api.net/vixoauth/",
    CustomerAPI: "https://vixoapimanagement3.azure-api.net/vixocustomer/",
    UserAPI: "https://vixoapimanagement3.azure-api.net/vixouser/",
    AccountAPI: "https://vixoapimanagement3.azure-api.net/vixoaccount/",
    DocumentAPI: "https://vixoapimanagement3.azure-api.net/vixodoc/",
    StatmentAPI: "https://vixoapimanagement3.azure-api.net/vixostatement/",
    OCRAPI: "https://vixoapimanagement3.azure-api.net/VixoOCR/",
    AlertsAPI: "https://vixoapimanagement3.azure-api.net/vixoemail/",
    FinicityAPI: "https://vixoapimanagement3.azure-api.net/vixofinicity",
    FundingAPI: "https://vixoapimanagement3.azure-api.net/vixofunding/",
    LookupAPI: "https://vixoapimanagement3.azure-api.net/vixolookup/",
    AdminDashboardAPI:
      "https://vixoapimanagement3.azure-api.net/admindashboard/",
    NotificationAPI: "https://vixonotification.azurewebsites.net/",
  };


  export const VixoAPI = {
    CustomeDashboard: GetEnvironmentBasedAPI().CustomeDashboard,
    DashBoard: GetEnvironmentBasedAPI().DashBoard,
    OrganizationAPI: GetEnvironmentBasedAPI().OrganizationAPI,
    NotificationAPI: GetEnvironmentBasedAPI().NotificationAPI,
    AuthenticationAPI: GetEnvironmentBasedAPI().AuthenticationAPI,
    CustomerAPI: GetEnvironmentBasedAPI().CustomerAPI,
    UserAPI: GetEnvironmentBasedAPI().UserAPI,
    AccountAPI: GetEnvironmentBasedAPI().AccountAPI,
    DocumentAPI: GetEnvironmentBasedAPI().DocumentAPI,
    StatmentAPI: GetEnvironmentBasedAPI().StatmentAPI,
    OCRAPI: GetEnvironmentBasedAPI().OCRAPI,
    AlertsAPI: GetEnvironmentBasedAPI().AlertsAPI,
    FundingAPI: GetEnvironmentBasedAPI().FundingAPI,
    LookupAPI: GetEnvironmentBasedAPI().LookupAPI,
    BillingAPI: GetEnvironmentBasedAPI().BillingAPI,
    ClearantAPI: GetEnvironmentBasedAPI().ClearantAPI,
    ClearntPublicKey: GetEnvironmentBasedAPI().ClearntPublicKey,
    CleartantAPIKey: GetEnvironmentBasedAPI().CleartantAPIKey,
    FinicityAPI: GetEnvironmentBasedAPI().FinicityAPI,
    AdminDashboardAPI: GetEnvironmentBasedAPI().AdminDashboardAPI,
  };
  



  export function GetEnvironmentBasedAPI() {
    if (
      Environment.ENV !=null &&
      Environment.ENV.trim().toUpperCase() == "LOCAL"
    ) {
      return VixoAPILocal;
    } else if (Environment.ENV.trim().toUpperCase() == "STAGING") {
      return VixoAPIStaging;
    } else if (
      (Environment.ENV != null &&
        Environment.ENV.trim().toUpperCase() == "DEV") ||
      Environment.ENV.trim().toUpperCase() == "DEVELOPMENT"
    ) {
      return VixoAPIDev;
    } else {
      return VixoAPIProduction;
    }
  }