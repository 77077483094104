import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { VixoAPI } from "../../settings";
import Spinner from "../../Spinner";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { ValidateEmailGlobal } from "../Validation";


export default function ForgetPassword() {
  const [errorType, setErrorType] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  // Define success message style
  const successMessageStyle = {
    backgroundColor: 'green', // Customize the background color as needed
    color: 'white',           // Customize the text color as needed
    padding: '10px',          // Add padding for better visibility
    marginBottom: '10px',     // Add margin to separate it from other content
  };
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({
    email: ""
  });
 
  // Define the 'key' state to store the value from the URL
  const [key, setKey] = useState(null);

  // Extract 'key' from the URL query parameters when the component mounts
  const location = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const keyFromParams = params.get("key");
    if (keyFromParams) {
      setKey(keyFromParams);
    }
  }, [location]);

  const handleUserInfoChange = (e) => {
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
  };
  const [emailError, setEmailError] = useState('');

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (!validateEmail(userInfo.email)) {
      return;
    }

    try {
      const ForgotAPIPath = VixoAPI.AdminDashboardAPI + 'forgot';
      setLoading(true);
      axios
        .get(ForgotAPIPath, {
          params: {
            email: userInfo.email,
          },
        })
        .then((response) => {
          var responseData = response.data;
          if (responseData.statusCode === 200) {
            // Instead of setting successMessage, show a toast notification
            toast.success(responseData.message);
          } else {
            setError(responseData.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      throw error;
    }
  };

  const validateEmail = (email) => {
    
    var IsValid = ValidateEmailGlobal(email);
  
   
    if (!IsValid) {
      
      setEmailError('Enter a valid email address');
    }
  
    // Return the boolean value (true if valid, false if not)
    return IsValid;
  }



  function setData(message) {
    // Display success message
    toast.success(message);
    setErrorType(null); // Clear error message
  }
 

  function setError(message) {
    setErrorType(message);
  }

  return (
    <>
      {/* {errorType && <ErrorComponent errorType={errorType} />} */}
      <Spinner show={loading} />
      {successMessage && (
        <div className="alert alert-success" style={successMessageStyle} role="alert">
          {successMessage}
        </div>
      )}
      <main className="authentication-content">
        <div
          className="container-fluid d-flex align-items-center justify-content-center vh-100"
          style={{ overflow: "hidden" }}
        >
          <div className="authentication-card">
            <div className="card shadow rounded-0 overflow-hidden">
              <div className="row g-0">
                <div className="col-lg-12">
                  <div className="card-body p-4 p-sm-5">
                    <form className="form-body">
                      <span className="m-5">
                        {" "}
                        <img
                          src="image/3.png"
                          className="logo-icon"
                          alt="logo icon"
                          style={{ width: "90%"}}
                        />
                      </span>
                      <h2 className="fw-bold">Forgot Password</h2>
                      <br />

                      {/* Password input */}
                      <label className="fw-bold mb-2 mt-2">Email</label>

                      <div className="input-group">
                      
                      <input
                        type="text"
                        name="email"
                        className={`form-control ${emailError ? 'is-invalid' : ''}`}
                        value={userInfo.email}
                        placeholder="Enter Email Address"
                        onChange={handleUserInfoChange}
                        style={{ padding: '10px' }}
                      />
                      {emailError && <div className="invalid-feedback">{emailError}</div>}
                      <br></br>
                      </div>
                      <br/>
                      <button
                        className="btn btn-primary form-control"
                        onClick={handleResetPassword}
                        disabled={loading}
                      >
                       Forget Password
                      </button>
                    </form>
                    <br />
                    <div className="col-md-12">
                      Do you have an account?&nbsp;&nbsp;
                      <Link to="/" style={{ textDecoration: "none" }}>
                        Sign In
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </main>
    </>
  );
}
