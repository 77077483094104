import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Spinner from "../../Spinner";
import { VixoAPI } from "../../settings";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { APIHeader } from "../../APICallWrapper";
import { HasLoggedInUser } from "../../APICallWrapper";
const Signin = () => {
  const [loading, setLoading] = useState(false);
  const [errorType, setErrorType] = useState(null);
  const [userInfo, setUserInfo] = useState({
    email: "",
    password: "",
  });

  const [emailError, setEmailError] = useState(""); // State for email validation error
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [formSubmitted, setFormSubmitted] = useState(false); // State to track form submission
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  // const handleUserInfoChange = (e) => {
  //   const { name, value } = e.target;

  //   setUserInfo({
  //     ...userInfo,
  //     [name]: value,
  //   });

  //   // Reset emailError when user is correcting input
  //   if (emailError) {
  //     setEmailError("");
  //   }

  //   // Validate email and set emailError
  //   if (name === "email") {
  //     setEmailError("Please enter a valid email address");
  //   }
  // };

  const handleUserInfoChange = (e) => {
    const { name, value } = e.target;

    setUserInfo({
      ...userInfo,
      [name]: value,
    });

    // Reset emailError
    setEmailError("");

    // Validate email and set emailError if it's not valid
    if (name === "email" && !isValidEmail(value)) {
      setEmailError("Please enter a valid email address");
    }
  };
  const navigate = useNavigate();
  const [User, setUser] = useState(null);
  useEffect(() => {
    const loggedInUser = localStorage.getItem("User");
    var userObject = JSON.parse(loggedInUser);

    if (userObject != null) {
      try {
        setUser(userObject);
        const loggedInOrg = localStorage.getItem("Org");
        var orgObject = JSON.parse(loggedInOrg);
        if (!orgObject || orgObject == null) {
          getUserId(userObject.id);
        }

        
      } catch (exception) {
      }
    } else {
      navigate("/");
    }
  }, []);
  const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._+%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email) && email.endsWith(".com");
  };

  const handleSignIn = async (e) => {
     
    e.preventDefault();
    // if (!isValidEmail(userInfo.email)) {
    //   setEmailError("Please enter a valid email.");
    //   return;
    // }

    try {
      const SigninAPIPath = VixoAPI.AdminDashboardAPI + "loginAdmin";
      setLoading(true);
      const data = {
        UserName: userInfo.email,
        Password: userInfo.password,
      };

      const response = await axios.post(SigninAPIPath, data);
      const responseData = response.data;

      if (responseData.statusCode === 200) {
        getUserId(responseData.data.id);
        navigate("/MutliFactor");
        localStorage.setItem("User", JSON.stringify(responseData.data));
        
      } else {
        // toast.error(responseData.message, {
        //   position: toast.POSITION.BOTTOM_RIGHT,
        // //   autoClose: 3000,
        // });
        setErrorType(responseData.message);
        setIsErrorModalOpen(true);
      }
    } catch (error) {
      setErrorType(error.message);
      setIsErrorModalOpen(true);
    } finally {
      setLoading(false);
      setFormSubmitted(true);
    }
 
  };
  function getUserId(UserID) {
    if (HasLoggedInUser()) {
    try {
      const AdminAPIPath = `${VixoAPI.AdminDashboardAPI}get?id=${UserID}`;
      setLoading(true);
      axios
        .get(AdminAPIPath, {
        headers: APIHeader().headers,
      })
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            
          }
        })
        .catch((error) => {
          setLoading(false);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });

      setLoading(false);
      setFormSubmitted(true); // Mark the form as submitted
    } catch (error) {
      setErrorType(error.message);
      setLoading(false);
    }
     }else{
    navigate("/")
  }
  }

  ////end organization using id
  return (
    <>
      <Spinner show={loading} />

      <main className="authentication-content">
        <div
          className="container-fluid d-flex align-items-center justify-content-center vh-100 "
          style={{ overflow: "hidden" }}
        >
          <div className="authentication-card">
            <div
              className="card shadow  overflow-hidden"
              style={{ borderRadius: "7%" }}
            >
              <div className="row g-0">
                <div className="col-lg-12">
                  <div className="card-body p-4 p-sm-5">
                    <form className="form-body">
                      <span className="m-5">
                        <img
                          src="image/3.png"
                          className="logo-icon"
                          alt="logo icon"
                          style={{ width: "90%" }}
                        />
                      </span>
                      <h2 className="fw-bold mb-3">Sign in</h2>

                      <label className="fw-bold mb-2">Email</label>

                      <input
                        type="text"
                        name="email"
                        value={userInfo.email}
                        className={`form-control mt-2 mb-2 ${
                          emailError ? "is-invalid" : ""
                        }`}
                        placeholder="Enter Email Address"
                        onChange={handleUserInfoChange}
                      />
                      {emailError && (
                        <div className="invalid-feedback">{emailError}</div>
                      )}

                      <label className="fw-bold mb-2 mt-2">Password</label>

                      <div className="input-group">
                        <input
                          type={showPassword ? "text" : "password"}
                          name="password"
                          value={userInfo.password}
                          className="form-control"
                          placeholder="Enter Password"
                          onChange={handleUserInfoChange}
                        />
                        <span
                          className="input-group-text"
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <span role="img" aria-label="Visible">
                              👁️
                            </span>
                          ) : (
                            <span role="img" aria-label="Hidden">
                              🙈
                            </span>
                          )}
                        </span>
                      </div>
                      <br />

                      <p className="text-end">
                        <Link to="/forget" style={{ textDecoration: "none" }}>
                          Forgot Password
                        </Link>
                      </p>

                      {
                        // Render the button only if the form hasn't been submitted
                        <button
                          className="btn btn-primary form-control my-signin-button"
                          onClick={handleSignIn}
                          disabled={!userInfo.password}
                        >
                          Sign In
                        </button>
                      }


                      {isErrorModalOpen && (
                        <div
                          className="modal"
                          tabIndex="-1"
                          role="dialog"
                          style={{ display: "block" }}
                        >
                          <div className="modal-dialog " role="document">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title">Error</h5>
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                  onClick={() => setIsErrorModalOpen(false)}
                                >
                                  {/* <span aria-hidden="true">&times;</span> */}
                                </button>
                              </div>
                              <div className="modal-body">
                                <p style={{ color: "red" }}>{errorType}</p>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  onClick={() => setIsErrorModalOpen(false)}
                                  style={{ background: "rgb(11, 94, 215)" }}
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* <div
                        className="card-footer py-3 bg-transparent error-container"
                        style={{ color: "red" }}
                      >
                        {errorType && (
                          <div className="" role="alert">
                            {errorType.split(",").map((part, index) => (
                              <div key={index}>{part.trim()}</div>
                            ))}
                          </div>
                        )}
                      </div> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Signin;
