import React, { useEffect } from "react";
import AdminNavbar from "../Navbar/AdminNavbar";
import Sidebar from "../Header/Sidebar";
import moment from "moment";
import { CheckAllowComponentAndRedirect } from "../../Authentication";

import {useNavigate } from "react-router-dom";

function Event() {
    const currentYear = moment().format("YYYY");
    const navigate = useNavigate();
    const ComponentName='Relic';

    useEffect(() => {

      CheckAllowComponentAndRedirect(ComponentName,navigate);
    } , []);
  
    return (
      <>
        <div id="main-wrapper">
          <AdminNavbar />
          <Sidebar />
          <div className="container">
            <div className="row invoice-card-row mt-4">
              <div className="col-xl-12 col-xxl-12 col-lg-12">
                <div className="">
                  <div className="px-2">
                    <div
                      className="cm-content-box px-2"
                      style={{ height: "425px" }}
                    >
                      <iframe
                        title="New Relic Chart"
                        src="https://chart-embed.service.newrelic.com/herald/b32194b3-d26e-4c0d-a9d2-ddc857d2c641?height=400px&timepicker=true"
                        width="50%"
                        height="600px"
                        frameBorder="0"
                        scrolling="no"
                      ></iframe>
                      <iframe
                        title="New Relic Chart"
                        src="https://chart-embed.service.newrelic.com/herald/1cd2adb6-6942-455b-bc41-a8d74d6f064e?height=400px&timepicker=true"
                        width="50%"
                        height="600px"
                        frameBorder="0"
                        scrolling="no"
                      ></iframe>
                    </div>
                    <div
                      className="cm-content-box px-2"
                      style={{ height: "425px" }}
                    >
                      <iframe
                        title="New Relic Chart"
                        src="https://chart-embed.service.newrelic.com/herald/e05b6561-171b-48a3-a19b-ae9db0c0fd1f?height=400px&timepicker=true"
                        width="33%"
                        height="600px"
                        frameBorder="0"
                        scrolling="no"
                      ></iframe>
                      <iframe
                        title="New Relic Chart"
                        src="https://chart-embed.service.newrelic.com/herald/5979c796-d867-4bb4-bb38-a0e36a3a0fbf?height=400px&timepicker=true"
                        width="33%"
                        height="600px"
                        frameBorder="0"
                        scrolling="no"
                      ></iframe>
                      <iframe
                        title="New Relic Chart"
                        src="https://chart-embed.service.newrelic.com/herald/e05b6561-171b-48a3-a19b-ae9db0c0fd1f?height=400px&timepicker=true"
                        width="33%"
                        height="600px"
                        frameBorder="0"
                        scrolling="no"
                      ></iframe>
                    </div>
                    <div
                      className="cm-content-box px-2"
                      style={{ height: "450px" }}
                    >
                      <iframe
                        title="New Relic Chart"
                        src="https://chart-embed.service.newrelic.com/herald/c9d1dabd-9376-4c93-b27f-3b43ed96bad1?height=400px&timepicker=true"
                        width="100%"
                        height="600px"
                        frameBorder="0"
                        scrolling="no"
                      ></iframe>
                    </div>
                    <div
                      className="cm-content-box px-2"
                      style={{ height: "425px" }}
                    >
                      <iframe
                        title="New Relic Chart"
                        src="https://chart-embed.service.newrelic.com/herald/6d79b31c-bb87-4f42-8069-aa5abf0ecb8e?height=400px&timepicker=true"
                        width="50%"
                        height="600px"
                        frameBorder="0"
                        scrolling="no"
                      ></iframe>
                      <iframe
                        title="New Relic Chart"
                        src="https://chart-embed.service.newrelic.com/herald/01eb7a69-900b-45a6-b550-50b763f90764?height=400px&timepicker=true"
                        width="50%"
                        height="600px"
                        frameBorder="0"
                        scrolling="no"
                      ></iframe>
                    </div>
                    <div
                      className="cm-content-box px-2"
                      style={{ height: "425px" }}
                    >
                      <iframe
                        title="New Relic Chart"
                        src="https://chart-embed.service.newrelic.com/herald/78e6ae01-fcb7-4f4a-86fc-66fba61d79a8?height=400px&timepicker=true"
                        width="50%"
                        height="600px"
                        frameBorder="0"
                        scrolling="no"
                      ></iframe>
                      <iframe
                        title="New Relic Chart"
                        src="https://chart-embed.service.newrelic.com/herald/b6a5e36a-0d85-4b99-88a7-c4515b7accd2?height=400px&timepicker=true"
                        width="50%"
                        height="600px"
                        frameBorder="0"
                        scrolling="no"
                      ></iframe>
                    </div>
                    <div
                      className="cm-content-box px-2"
                      style={{ height: "425px" }}
                    >
                      <iframe
                        title="New Relic Chart"
                        src="https://chart-embed.service.newrelic.com/herald/b585fb7a-abba-4223-ad5d-a4aed9c825bb?height=400px&timepicker=true"
                        width="50%"
                        height="600px"
                        frameBorder="0"
                        scrolling="no"
                      ></iframe>
                      <iframe
                        title="New Relic Chart"
                        src="https://chart-embed.service.newrelic.com/herald/2f1ab525-b4ef-4b26-b8ca-804d1028f13a?height=400px&timepicker=true"
                        width="50%"
                        height="600px"
                        frameBorder="0"
                        scrolling="no"
                      ></iframe>
                    </div>
                    <div
                      className="cm-content-box px-2"
                      style={{ height: "450px" }}
                    >
                      <iframe
                        title="New Relic Chart"
                        src="https://chart-embed.service.newrelic.com/herald/07a4f8f8-c715-4f41-8fc7-53e55aa82105?height=400px&timepicker=true"
                        width="100%"
                        height="600px"
                        frameBorder="0"
                        scrolling="no"
                      ></iframe>
                    </div>
                    <div
                      className="cm-content-box px-2"
                      style={{ height: "450px" }}
                    >
                      <iframe
                        title="New Relic Chart"
                        src="https://chart-embed.service.newrelic.com/herald/7f7a0cd1-ea01-4618-aca4-3c0670e12d01?height=400px&timepicker=true"
                        width="100%"
                        height="600px"
                        frameBorder="0"
                        scrolling="no"
                      ></iframe>
                    </div>
                    <div
                      className="cm-content-box px-2"
                      style={{ height: "450px" }}
                    >
                      <iframe
                        title="New Relic Chart"
                        src="https://chart-embed.service.newrelic.com/herald/3679f3ad-9425-4257-b9c4-d9b4cdab182e?height=400px&timepicker=true"
                        width="100%"
                        height="600px"
                        frameBorder="0"
                        scrolling="no"
                      ></iframe>
                    </div>
                    <div
                      className="cm-content-box px-2"
                      style={{ height: "450px" }}
                    >
                      <iframe
                        title="New Relic Chart"
                        src="https://chart-embed.service.newrelic.com/herald/4e73d203-6afb-4fa1-bae6-19b9cec1505b?height=400px&timepicker=true"
                        width="100%"
                        height="600px"
                        frameBorder="0"
                        scrolling="no"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer">
          <div className="copyright">
            <p>
              Copyright © Designed &amp; Developed by{" "}
              <a href="#" target="_blank">
                Vixo
              </a>{" "}
              {currentYear}
            </p>
          </div>
        </div>
      </>
    );
}
   
export default Event;