import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignal } from "@fortawesome/free-solid-svg-icons";
import { faScroll } from "@fortawesome/free-solid-svg-icons";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { faMoneyCheckDollar } from "@fortawesome/free-solid-svg-icons";
import { faGauge } from "@fortawesome/free-solid-svg-icons";
import { faBuildingColumns } from "@fortawesome/free-solid-svg-icons";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import {IsComponentAllowToRole} from '../../Authentication'
import {

  faCaretUp,
  faUser,

  faTrash,
  faFileContract,
  faGears,
} from "@fortawesome/free-solid-svg-icons";
import { createPortal } from "react-dom";
import DropDown from "../User/DropDown";


import { faSitemap } from "@fortawesome/free-solid-svg-icons";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { faUsers, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faCreditCard,
  faClockRotateLeft,
} from "@fortawesome/free-solid-svg-icons";
function Sidebar() {
 
  const [isHamburgerActive, setHamburgerActive] = useState(false);

  const toggleHamburger = () => {
    setHamburgerActive(!isHamburgerActive);
    const menuToggle = document.getElementById("main-wrapper");
    if (isHamburgerActive) {
      menuToggle.classList.remove("menu-toggle", "show");
    } else {
      menuToggle.classList.add("menu-toggle", "show");
    }
  };

  const [showDropDown, setshowDropDown] = useState(false);

  const toggleDropUp = () => {
    setshowDropDown(!showDropDown);
  };

  const dropupButtonRef = useRef(null);

  const [User, setUser] = useState({});

  const navigate = useNavigate();
  useEffect(() => {
    LoadData();
  }, []);

  const LoadData = () => {
    const loggedInUser = localStorage.getItem("User");
    var userObject = JSON.parse(loggedInUser);
    if (userObject != null) {
      setUser(userObject);
    } else {
      localStorage.clear();
      navigate("/");
    }
  };
  return (
    <div>
      <div className="nav-header">
        <a href="#" className="brand-logo"></a>
        <div className="nav-control">
          <div
            className={`hamburger ${isHamburgerActive ? "is-active" : ""}`}
            onClick={toggleHamburger}
          >
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
          </div>
        </div>
      </div>

      <div className="dlabnav">
        <div className="dlabnav-scroll">
          <ul className="metismenu" id="menu">
            {IsComponentAllowToRole("Dashboard") ? (
              <li data-toggle="tooltip" data-placement="top" title="Dashboard">
                <Link to="/Dashboard" aria-expanded="false">
                  {/* <i className="flaticon-025-dashboard"></i> */}
                  <FontAwesomeIcon icon={faGauge} />
                  <span className="nav-text">Dashboard</span>
                </Link>
              </li>
            ) : (
              ""
            )}

            {IsComponentAllowToRole("All Organization") ? (
              <li
                data-toggle="tooltip"
                data-placement="top"
                title="All Organization"
              >
                <Link to="/AllOrganization" aria-expanded="false">
                  {/* <i className="flaticon-022-copy"></i> */}
                  <FontAwesomeIcon icon={faSitemap} />
                  <span className="nav-text">All Organization</span>
                </Link>
              </li>
            ) : (
              ""
            )}

            {IsComponentAllowToRole("Vixo Emails") ? (
              <li
                data-toggle="tooltip"
                data-placement="top"
                title="Vixo Emails"
              >
                <Link to="/vixoemail" aria-expanded="false">
                  {/* <i className="flaticon-022-copy"></i> */}
                  <FontAwesomeIcon icon={faEnvelope} />
                  <span className="nav-text">Vixo Emails</span>
                </Link>
              </li>
            ) : (
              ""
            )}

            {IsComponentAllowToRole("Recent Activities") ? (
              <li
                data-toggle="tooltip"
                data-placement="top"
                title="Recent Activities"
              >
                <Link
                  style={{ color: "rgb(1,1,1)", textDecoration: "none" }}
                  to={"/recentactivities"}
                >
                  {/* <i class="fa fa-credit-card" aria-hidden="true"></i> */}

                  <FontAwesomeIcon icon={faClockRotateLeft} />
                </Link>
              </li>
            ) : (
              ""
            )}

            {IsComponentAllowToRole("Features") ? (
              <li data-toggle="tooltip" data-placement="top" title="Features">
                <Link
                  style={{ color: "rgb(1,1,1)", textDecoration: "none" }}
                  to={"/features"}
                >
                  {/* <i class="fa fa-credit-card" aria-hidden="true"></i> */}

                  <FontAwesomeIcon icon={faGears} />
                </Link>
              </li>
            ) : (
              ""
            )}
            {IsComponentAllowToRole("Funding Request") ? (
              <li
                data-toggle="tooltip"
                data-placement="top"
                title="Funding Request"
              >
                <Link to="/FundingRequest" aria-expanded="false">
                  {/* <i className="flaticon-041-graph"></i> */}
                  <FontAwesomeIcon icon={faCartShopping} />
                  <span className="nav-text">Payment</span>
                </Link>
              </li>
            ) : (
              ""
            )}

            {IsComponentAllowToRole("Exception Logs") ? (
              <li
                data-toggle="tooltip"
                data-placement="top"
                title="Exception Logs"
              >
                <Link to="/ExceptionLogs" aria-expanded="false">
                  {/* <i class="fa fa-clipboard" aria-hidden="true"></i> */}
                  <FontAwesomeIcon icon={faSignal} />
                  <span className="nav-text">Logs</span>
                </Link>
              </li>
            ) : (
              ""
            )}
            {IsComponentAllowToRole("Relic") ? (
              <li data-toggle="tooltip" data-placement="top" title="Relic">
                <Link to="/Relic" aria-expanded="false">
                  {/* <i class="fa fa-line-chart" aria-hidden="true"></i> */}
                  <FontAwesomeIcon icon={faScroll} />
                  <span className="nav-text">New Relic</span>
                </Link>
              </li>
            ) : (
              ""
            )}
            {IsComponentAllowToRole("Events Record") ? (
              <li
                data-toggle="tooltip"
                data-placement="top"
                title="Events Record"
              >
                <Link to="/EventsRecord" aria-expanded="false">
                  {/* <i class="fa fa-calendar" aria-hidden="true"></i> */}
                  <FontAwesomeIcon icon={faCalendarDays} />
                  <span className="nav-text">Events Record</span>
                </Link>
              </li>
            ) : (
              ""
            )}

            {IsComponentAllowToRole("Consent") ? (
              <li data-toggle="tooltip" data-placement="top" title="Consent">
                <Link to="/Consent" aria-expanded="false">
                  {/* <i class="fa fa-calendar" aria-hidden="true"></i> */}
                  <FontAwesomeIcon icon={faFileContract} />
                  <span className="nav-text">Consent</span>
                </Link>
              </li>
            ) : (
              ""
            )}

            {IsComponentAllowToRole("User Management") ? (
              <li
                data-toggle="tooltip"
                data-placement="top"
                title="User Management"
              >
                <Link
                  style={{ color: "rgb(1,1,1)", textDecoration: "none" }}
                  to={"/UserManagement"}
                >
                  <FontAwesomeIcon icon={faUsers} />
                  {/* <i class="fa fa-users" aria-hidden="true"></i> */}
                </Link>
              </li>
            ) : (
              ""
            )}

            {IsComponentAllowToRole("Billabele Call") ? (
              <li
                data-toggle="tooltip"
                data-placement="top"
                title="Billabele Call"
              >
                <Link
                  style={{ color: "rgb(1,1,1)", textDecoration: "none" }}
                  to={"/billablecall"}
                >
                  {/* <i class="fa fa-credit-card" aria-hidden="true"></i> */}
                  <FontAwesomeIcon icon={faCreditCard} />
                </Link>
              </li>
            ) : (
              ""
            )}

            {IsComponentAllowToRole("Received Payment") === true ? (
              <li
                data-toggle="tooltip"
                data-placement="top"
                title="Received Payment"
              >
                <Link
                  style={{ color: "rgb(1,1,1)", textDecoration: "none" }}
                  to={"/receivedpayment"}
                >
                  <FontAwesomeIcon icon={faMoneyCheckDollar} />
                  {/* <i class="fa fa-usd" aria-hidden="true"></i> */}
                </Link>
              </li>
            ) : (
              ""
            )}

            {IsComponentAllowToRole("Open Bank") ? (
              <li data-toggle="tooltip" data-placement="top" title="Open Bank">
                <Link
                  style={{ color: "rgb(1,1,1)", textDecoration: "none" }}
                  to={"/openbank"}
                >
                  {/* <i class="fa fa-university" aria-hidden="true"></i> */}
                  <FontAwesomeIcon icon={faBuildingColumns} />
                </Link>
              </li>
            ) : (
              ""
            )}

            {IsComponentAllowToRole("Ocr") ? (
              <li data-toggle="tooltip" data-placement="top" title="Ocr">
                <Link
                  style={{ color: "rgb(1,1,1)", textDecoration: "none" }}
                  to={"/Ocrs"}
                >
                  <FontAwesomeIcon icon={faFile} />
                  {/* <i class="fa fa-file" aria-hidden="true"></i> */}
                </Link>
              </li>
            ) : (
              ""
            )}

            {IsComponentAllowToRole("Delete Organization") ? (
              <li
                data-toggle="tooltip"
                data-placement="top"
                title="Delete Organization"
              >
                <Link
                  style={{ color: "rgb(1,1,1)", textDecoration: "none" }}
                  to={"/deleteorganization"}
                >
                  {/* <i class="fa fa-trash" aria-hidden="true"></i> */}
                  <FontAwesomeIcon icon={faTrash} />
                </Link>
              </li>
            ) : (
              ""
            )}
          </ul>

          <div className="container mb-3 mt-3" style={{ zIndex: 1000 }}>
            <ul>
              <li className="">
                <div
                  style={{
                    position: "absolute ",
                    right: "5px",
                  }}
                >
                  <li className=" dropup-btn" onClick={toggleDropUp}>
                    {/* <OverlayTrigger placement="top" overlay={renderTooltip}> */}

                    <button
                      ref={dropupButtonRef}
                      className="btn btn-primary d-sm-inline-block d-none"
                      style={{
                        width: "100%",
                        backgroundColor: "#94618e",
                        borderColor: " #94618e",
                      }}
                    >
                      <sapn style={{ fontSize: "12px", marginTop: "11px" }}>
                        <FontAwesomeIcon icon={faUser} />{" "}
                        <span style={{ margin: "0 5px" }}>
                          {User.firstName}
                        </span>
                        <FontAwesomeIcon icon={faCaretUp} />
                      </sapn>
                    </button>
                    {/* </OverlayTrigger> */}
                    {showDropDown &&
                      createPortal(
                        <DropDown referenceElement={dropupButtonRef.current} />,
                        document.body
                      )}
                  </li>
                </div>
              </li>
            </ul>{" "}
          </div>

          <div className="copyright">
            <p>
              <strong>Vixo Admin Dashboard</strong> © 2023 All Rights Reserved
            </p>
            <p className="fs-12">
              Made with <span className="heart"></span> by Vixo Link
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
