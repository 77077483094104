import React, { useState, useEffect } from "react";
import Sidebar from "../Header/Sidebar";
import moment from "moment";
import axios from "axios";
import { VixoAPI } from "../../settings";
import RightSidebar from "./RightSidebar";
import AdminNavbar from "../Navbar/AdminNavbar";
import Spinner from "../../Spinner";
import { format } from "date-fns";
import "../User/AllUser.css";
import { useSelector, useDispatch } from "react-redux";
import {
  selectNotification,
  addNotification,
} from "../Notifications/Slices/sendNotificationSlice";
import { Toaster, toast } from "react-hot-toast";
import { Alert } from "react-bootstrap";
import * as signalR from "@microsoft/signalr";
import Sparkles from "react-sparkle";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { APIHeader } from "../../APICallWrapper";
import { HasLoggedInUser } from "../../APICallWrapper";
import { CheckAllowComponentAndRedirect} from "../../Authentication";
function Dashbaord() {
  const navigate = useNavigate();
  const ComponentName='Dashbaord';



  const [dashboardData, setDashboardData] = useState({});
  const [recentOrg, setrecentOrg] = useState({});
  const [loading, setLoading] = useState(null);
  const notification = useSelector(selectNotification);
  const [notifications, setNotifications] = useState({});
  const [selectedOption, setSelectedOption] = useState("1");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const currentYear = moment().format("YYYY");
    useEffect(() => {
    LoadOrganization();
    loadRecentOrg();

    const intervalId = setInterval(() => {
      LoadOrganization();
    }, 60000);
    return () => clearInterval(intervalId);
  }, []);
  const dispatch = useDispatch();
  useEffect(() => {
    CheckAllowComponentAndRedirect(ComponentName,navigate);
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${VixoAPI.NotificationAPI}notificationHub`) // Adjust the URL based on your server setup
      .build();

    connection
      .start()
      .catch((error) => console.error("SignalR Connection Error:", error));

    connection.on("ReceiveNotification", (receivedNotification) => {
      // sendNotificationToRedux(receivedNotification);
      const { organiationID, cutomerId, notificationType, referenceID } =
        receivedNotification;
      // alert("received Notification From Dashboard.js Admin" , receivedNotification)
      sendNotificationToRedux(receivedNotification);

      // Handle the notification data as needed in your React application
    });

    function sendNotificationToRedux(receivedNotification) {
      console.log("Sending Notification to Redux:", receivedNotification);
      setNotifications(receivedNotification);
      dispatch(addNotification(receivedNotification));
      console.log("After dispatch AddNotification Func"(receivedNotification));
    }

    return () => {
      if (connection.state === signalR.HubConnectionState.Connected) {
        connection.stop();
      }
    };
  }, [dispatch]);
  function LoadOrganization() {
      if (HasLoggedInUser()) {
        const DissAllowRoles = [2, 4];
        // IsRoleAllowed(DissAllowRoles,navigate);
        try {
          //setLoading(true);
          const SetDataById = `${VixoAPI.AdminDashboardAPI}getVixoDashboardDetail`;
          //setLoading(true);
          axios
            .get(SetDataById, {
              headers: APIHeader().headers,
            })
            .then((response) => {
              var response = response.data;

              if (response.statusCode === 200) {
                var Statdata = JSON.parse(JSON.stringify(response.data));

                setDashboardData(Statdata);
              } else {
                //setError(response.message);
              }
            })
            .catch((error) => {
              //setLoading(false);
              throw error;
            })
            .finally(() => {
              //setLoading(false);
            });
        } catch (error) {
          //setLoading(false);
          throw error;
        }
      } else {
        navigate("/");
      }
  }
  useEffect(() => {
    if (notifications != null &&
      notification.notificationType === 20 ) {
      GotNewAlert(notifications);
    }
  }, [notifications]);

  useEffect(() => {
    loadRecentOrg();
  }, []);

  function getorganizationId(OrganizationID) {
    try {
      const OrgAPIPath = `${VixoAPI.OrganizationAPI}get?ID=${OrganizationID}`;
      setLoading(true);
      axios
        .get(OrgAPIPath)
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            loadRecentOrg();
          }
        })
        .catch((error) => {
          setLoading(false);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const sparkleStyle = {
    fontSize: '1.5em',
    marginRight: '0.5em', 
    color: '#4d4d4d', 
  };

  function GotNewAlert(notifications) {
    if (notifications != null && notifications.notificationType == 20) {
      const organizationID = notifications.organiationID;
      const title = notifications.title;
      const description = notifications.detail;
      const notificationSound = new Audio(
        "NotificationSound/Notification1.mp3"
      );
      notificationSound.addEventListener("loadeddata", () => {
        // Play notification sound
        notificationSound.play();
      });

      toast.success(
        <div>
       <span role="img" aria-label="sparkle" style={sparkleStyle}>✨</span>
          <span style={{ color: "#000000" }}>{description}</span>
        </div>,
        {
          style: {
            color: "#ffffff", // Text color
          },
        }
      );
      AddOrganizationToRecentOrganiations(organizationID);
    }
  }
  function AddOrganizationToRecentOrganiations(OrganiationID) {
    //Call Get API for Organization
    //When you get Organization Add to array
    //recentOrg
    getorganizationId(OrganiationID);
  }
  function loadRecentOrg() {
     if (HasLoggedInUser()) {
       try {
         setLoading(true);
         const SetDataById = `${VixoAPI.AdminDashboardAPI}getRecentOrganization`;
         //setLoading(true);
         axios
           .get(SetDataById, {
             headers: APIHeader().headers,
           })
           .then((response) => {
             var response = response.data;
             if (response.statusCode === 200) {
               var Statdata = JSON.parse(JSON.stringify(response.data.data));

               var filteredData = Statdata.filter(
                 (recentorg) => !recentorg.softDelete
               );

               if (filteredData != null && filteredData?.length > 0) {
                 setrecentOrg(filteredData.reverse());
               }
               toast.success(response.message);
             } else {
               //setError(response.message);
             }
           })
           .catch((error) => {
             //setLoading(false);
             throw error;
           })
           .finally(() => {
             setLoading(false);
           });
       } catch (error) {
         setLoading(false);
         throw error;
       }
     } else {
       navigate("/");
     }
  }

  // dropdown
  const handleDropdownChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    if (selectedValue === "1") {
      // This Month
      const today = new Date();
      const firstDayOfMonth = new Date(
        today.getFullYear(),
        today.getMonth(),
        1
      );
      const lastDayOfMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        0
      );
      setStartDate(firstDayOfMonth);
      setEndDate(lastDayOfMonth);
    } else if (selectedValue === "2") {
      // Last Month
      const today = new Date();
      const lastMonthFirstDay = new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        1
      );
      const lastMonthLastDay = new Date(
        today.getFullYear(),
        today.getMonth(),
        0
      );
      setStartDate(lastMonthFirstDay);
      setEndDate(lastMonthLastDay);
    } else if (selectedValue === "3") {
      // Last Three Months
      const today = new Date();
      const firstDayOfLastThreeMonths = new Date(
        today.getFullYear(),
        today.getMonth() - 3,
        1
      );
      const lastDayOfLastThreeMonths = new Date(
        today.getFullYear(),
        today.getMonth(),
        0
      );
      setStartDate(firstDayOfLastThreeMonths);
      setEndDate(lastDayOfLastThreeMonths);
    } else if (selectedValue === "4") {
      // Last Six Months
      const today = new Date();
      const firstDayOfLastSixMonths = new Date(
        today.getFullYear(),
        today.getMonth() - 6,
        1
      );
      const lastDayOfLastSixMonths = new Date(
        today.getFullYear(),
        today.getMonth(),
        0
      );
      setStartDate(firstDayOfLastSixMonths);
      setEndDate(lastDayOfLastSixMonths);
    } else if (selectedValue === "5") {
      // Last Nine Months
      const today = new Date();
      const firstDayOfLastNineMonths = new Date(
        today.getFullYear(),
        today.getMonth() - 9,
        1
      );
      const lastDayOfLastNineMonths = new Date(
        today.getFullYear(),
        today.getMonth(),
        0
      );
      setStartDate(firstDayOfLastNineMonths);
      setEndDate(lastDayOfLastNineMonths);
    } else if (selectedValue === "6") {
      // This Year
      const today = new Date();
      const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
      const lastDayOfYear = new Date(today.getFullYear(), 11, 31);
      setStartDate(firstDayOfYear);
      setEndDate(lastDayOfYear);
    } else if (selectedValue !== "8") {
      setStartDate(new Date());
      setEndDate(new Date());
    } else if (selectedValue === "9") {
      const earliestDate = new Date(0);
      const today = new Date();
      setStartDate(earliestDate);
      setEndDate(today);
    }
  };
  return (
    <>
      <div id="main-wrapper">
        <AdminNavbar />
        <Sidebar />
        <div className="content-body">
          <div className="container-fluid">
            <div className="row invoice-card-row mb-4">
              <div className=" col-lg-9 col-xl-12">
                <div style={{ textAlignLast: "center" }}>
                  <img id="logo" src="image/3.png" style={{ width: "300px" }} />
                </div>
                <div className="d-flex align-items-center gap-1  ">
                  <div className="start-date-picker d-flex align-items-center gap-1 ">
                    {(selectedOption === "8" ||
                      selectedOption === "1" ||
                      selectedOption === "2" ||
                      selectedOption === "3" ||
                      selectedOption === "4" ||
                      selectedOption === "5" ||
                      selectedOption === "6") && (
                      <>
                        <label style={{ color: "white", fontSize: "14px" }}>
                          {" "}
                          Start Date:{" "}
                        </label>
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          disabled={selectedOption !== "8"}
                        />
                      </>
                    )}
                  </div>

                  <div className="end-date-picker d-flex align-items-center gap-1">
                    {(selectedOption === "8" ||
                      selectedOption === "1" ||
                      selectedOption === "2" ||
                      selectedOption === "3" ||
                      selectedOption === "4" ||
                      selectedOption === "5" ||
                      selectedOption === "6") && (
                      <>
                        <label style={{ color: "white", fontSize: "14px" }}>
                          End Date:{" "}
                        </label>
                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          disabled={selectedOption !== "8"}
                        />
                      </>
                    )}
                  </div>

                  <div
                    className="row text-center"
                    style={{ marginLeft: "2rem" }}
                  >
                    <div className="text-end">
                      <select
                        className="dropdowntabmob h-80 rounded form-control"
                        value={selectedOption}
                        onChange={handleDropdownChange}
                      >
                        <option value="1">This Month</option>
                        <option value="2">Last Month</option>
                        <option value="3">Last Three Month</option>
                        <option value="4">Last Six Month</option>
                        <option value="5">Last Nine Month</option>
                        <option value="6">This Year</option>
                        <option value="8">Custom Date</option>
                        <option value="9">All Time</option>
                      </select>
                    </div>
                  </div>
                </div>
                <br></br>

                <div className="row">
                  <div className="col-md-4 md-4">
                    <Link
                      to="/AllOrganization"
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                        display: "block",
                      }}
                    >
                      <div
                        style={{
                          height: "10pc",
                          backgroundColor: "rgb(226, 226, 245, 0.11)",
                        }}
                        className="card invoice-card gradient-bx  rounded position-unset"
                      >
                        <div className="card-body billable-card">
                          <div className="media align-items-center">
                            <div className="media-body">
                              <p className=" fs-18 mb-1">Total Organizations</p>
                              <div
                                className="d-flex flex-wrap"
                                style={{ flexDirection: "column" }}
                              >
                                <h2
                                  className=" mb-0 me-3 invoice-num "
                                  style={{ fontSize: "40px" }}
                                >
                                  {dashboardData?.totalOrganizations
                                    ?.totalItems ?? "0"}
                                </h2>
                                <div className="d-flex">
                                  <svg
                                    width="28"
                                    height="19"
                                    viewBox="0 0 28 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M18.875 9.25C21.0787 11.6256 25.1753 16.0091 26.4375 17.5H1V1L10.625 13.375L18.875 9.25Z"
                                      fill="url(#paint0_linear1)"
                                    />
                                    <path
                                      d="M26.4375 17.5C25.1753 16.0091 21.0787 11.6256 18.875 9.25L10.625 13.375L1 1"
                                      stroke="white"
                                      stroke-width="2"
                                    />
                                    <defs>
                                      <linearGradient
                                        id="paint0_linear1"
                                        x1="13.7188"
                                        y1="3.0625"
                                        x2="12.6875"
                                        y2="17.5"
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop
                                          stop-color="white"
                                          stop-opacity="0.73"
                                          offset="0.1"
                                        />
                                        <stop
                                          offset="1"
                                          stop-color="white"
                                          stop-opacity="0"
                                        />
                                      </linearGradient>
                                    </defs>
                                  </svg>
                                  <div className="fs-14">
                                    {" "}
                                    <span
                                      style={{ color: "rgb(244, 107, 104)" }}
                                    >
                                      {" "}
                                      &nbsp; Upto{" "}
                                      {dashboardData?.totalOrganizations
                                        ?.progress ?? "0"}
                                      %
                                    </span>{" "}
                                    from the last week
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-4 md-4">
                    <Link
                      to="#"
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                        display: "block",
                      }}
                    >
                      <div
                        style={{
                          height: "10pc",
                          backgroundColor: "rgb(226, 226, 245, 0.11)",
                        }}
                        className="card invoice-card gradient-bx  rounded position-unset"
                      >
                        <div className="card-body billable-card">
                          <div className="media align-items-center">
                            <div className="media-body">
                              <p className=" fs-18 mb-1">Total Users</p>
                              <div
                                className="d-flex flex-wrap"
                                style={{ flexDirection: "column" }}
                              >
                                <h2
                                  className=" mb-0 me-3 invoice-num "
                                  style={{ fontSize: "40px" }}
                                >
                                  {dashboardData?.totalUsers?.totalItems ?? "0"}
                                </h2>
                                <div className="d-flex">
                                  <svg
                                    width="28"
                                    height="19"
                                    viewBox="0 0 28 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M18.875 9.25C21.0787 11.6256 25.1753 16.0091 26.4375 17.5H1V1L10.625 13.375L18.875 9.25Z"
                                      fill="url(#paint0_linear1)"
                                    />
                                    <path
                                      d="M26.4375 17.5C25.1753 16.0091 21.0787 11.6256 18.875 9.25L10.625 13.375L1 1"
                                      stroke="white"
                                      stroke-width="2"
                                    />
                                    <defs>
                                      <linearGradient
                                        id="paint0_linear1"
                                        x1="13.7188"
                                        y1="3.0625"
                                        x2="12.6875"
                                        y2="17.5"
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop
                                          stop-color="white"
                                          stop-opacity="0.73"
                                          offset="0.1"
                                        />
                                        <stop
                                          offset="1"
                                          stop-color="white"
                                          stop-opacity="0"
                                        />
                                      </linearGradient>
                                    </defs>
                                  </svg>
                                  <div className="fs-14">
                                    {" "}
                                    <span
                                      style={{ color: "rgb(244, 107, 104)" }}
                                    >
                                      {" "}
                                      &nbsp; Upto{" "}
                                      {dashboardData?.totalUsers?.progress ??
                                        "0"}
                                      %
                                    </span>{" "}
                                    from the last week
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="col-md-4 md-4">
                    <Link
                      to="#"
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                        display: "block",
                      }}
                    >
                      <div
                        style={{
                          height: "10pc",
                          backgroundColor: "rgb(226, 226, 245, 0.11)",
                        }}
                        className="card invoice-card gradient-bx  rounded position-unset"
                      >
                        <div className="card-body billable-card">
                          <div className="media align-items-center">
                            <div className="media-body">
                              <p className=" fs-18 mb-1">Total Customers</p>
                              <div
                                className="d-flex flex-wrap"
                                style={{ flexDirection: "column" }}
                              >
                                <h2
                                  className=" mb-0 me-3 invoice-num "
                                  style={{ fontSize: "40px" }}
                                >
                                  {dashboardData?.totalCustomers?.totalItems ??
                                    "0"}
                                </h2>
                                <div className="d-flex">
                                  <svg
                                    width="28"
                                    height="19"
                                    viewBox="0 0 28 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M18.875 9.25C21.0787 11.6256 25.1753 16.0091 26.4375 17.5H1V1L10.625 13.375L18.875 9.25Z"
                                      fill="url(#paint0_linear1)"
                                    />
                                    <path
                                      d="M26.4375 17.5C25.1753 16.0091 21.0787 11.6256 18.875 9.25L10.625 13.375L1 1"
                                      stroke="white"
                                      stroke-width="2"
                                    />
                                    <defs>
                                      <linearGradient
                                        id="paint0_linear1"
                                        x1="13.7188"
                                        y1="3.0625"
                                        x2="12.6875"
                                        y2="17.5"
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop
                                          stop-color="white"
                                          stop-opacity="0.73"
                                          offset="0.1"
                                        />
                                        <stop
                                          offset="1"
                                          stop-color="white"
                                          stop-opacity="0"
                                        />
                                      </linearGradient>
                                    </defs>
                                  </svg>
                                  <div className="fs-14">
                                    {" "}
                                    <span
                                      style={{ color: "rgb(244, 107, 104)" }}
                                    >
                                      {" "}
                                      &nbsp; Upto{" "}
                                      {dashboardData?.totalCustomers
                                        ?.progress ?? "0"}
                                      %
                                    </span>{" "}
                                    from the last week
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-4 md-4">
                    <Link
                      to="/openbank"
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                        display: "block",
                      }}
                    >
                      <div
                        style={{
                          height: "10pc",
                          backgroundColor: "rgb(226, 226, 245, 0.11)",
                        }}
                        className="card invoice-card gradient-bx  rounded position-unset"
                      >
                        <div className="card-body billable-card">
                          <div className="media align-items-center">
                            <div className="media-body">
                              <p className=" fs-18 mb-1">Open Bank</p>
                              <div
                                className="d-flex flex-wrap"
                                style={{ flexDirection: "column" }}
                              >
                                <h2
                                  className=" mb-0 me-3 invoice-num "
                                  style={{ fontSize: "40px" }}
                                >
                                  {dashboardData?.totalOpenBankOCR
                                    ?.totalItems ?? "0"}
                                </h2>
                                <div className="d-flex">
                                  <svg
                                    width="28"
                                    height="19"
                                    viewBox="0 0 28 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M18.875 9.25C21.0787 11.6256 25.1753 16.0091 26.4375 17.5H1V1L10.625 13.375L18.875 9.25Z"
                                      fill="url(#paint0_linear1)"
                                    />
                                    <path
                                      d="M26.4375 17.5C25.1753 16.0091 21.0787 11.6256 18.875 9.25L10.625 13.375L1 1"
                                      stroke="white"
                                      stroke-width="2"
                                    />
                                    <defs>
                                      <linearGradient
                                        id="paint0_linear1"
                                        x1="13.7188"
                                        y1="3.0625"
                                        x2="12.6875"
                                        y2="17.5"
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop
                                          stop-color="white"
                                          stop-opacity="0.73"
                                          offset="0.1"
                                        />
                                        <stop
                                          offset="1"
                                          stop-color="white"
                                          stop-opacity="0"
                                        />
                                      </linearGradient>
                                    </defs>
                                  </svg>
                                  <div className="fs-14">
                                    {" "}
                                    <span
                                      style={{ color: "rgb(244, 107, 104)" }}
                                    >
                                      {" "}
                                      &nbsp; Upto{" "}
                                      {dashboardData?.totalOpenBankOCR
                                        ?.progress ?? "0"}
                                      %
                                    </span>{" "}
                                    from the last week
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-4 md-4">
                    <Link
                      to="#"
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                        display: "block",
                      }}
                    >
                      <div
                        style={{
                          height: "10pc",
                          backgroundColor: "rgb(226, 226, 245, 0.11)",
                        }}
                        className="card invoice-card gradient-bx  rounded position-unset"
                      >
                        <div className="card-body billable-card">
                          <div className="media align-items-center">
                            <div className="media-body">
                              <p className=" fs-18 mb-1">
                                Total Finicity Accounts
                              </p>
                              <div
                                className="d-flex flex-wrap"
                                style={{ flexDirection: "column" }}
                              >
                                <h2
                                  className=" mb-0 me-3 invoice-num "
                                  style={{ fontSize: "40px" }}
                                >
                                  {dashboardData?.totalFinicityAccount
                                    ?.totalItems ?? "0"}
                                </h2>
                                <div className="d-flex">
                                  <svg
                                    width="28"
                                    height="19"
                                    viewBox="0 0 28 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M18.875 9.25C21.0787 11.6256 25.1753 16.0091 26.4375 17.5H1V1L10.625 13.375L18.875 9.25Z"
                                      fill="url(#paint0_linear1)"
                                    />
                                    <path
                                      d="M26.4375 17.5C25.1753 16.0091 21.0787 11.6256 18.875 9.25L10.625 13.375L1 1"
                                      stroke="white"
                                      stroke-width="2"
                                    />
                                    <defs>
                                      <linearGradient
                                        id="paint0_linear1"
                                        x1="13.7188"
                                        y1="3.0625"
                                        x2="12.6875"
                                        y2="17.5"
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop
                                          stop-color="white"
                                          stop-opacity="0.73"
                                          offset="0.1"
                                        />
                                        <stop
                                          offset="1"
                                          stop-color="white"
                                          stop-opacity="0"
                                        />
                                      </linearGradient>
                                    </defs>
                                  </svg>
                                  <div className="fs-14">
                                    {" "}
                                    <span
                                      style={{ color: "rgb(244, 107, 104)" }}
                                    >
                                      {" "}
                                      &nbsp; Upto{" "}
                                      {dashboardData?.totalFinicityAccount
                                        ?.progress ?? "0"}
                                      %
                                    </span>{" "}
                                    from the last week
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-4 md-4">
                    <Link
                      to="#"
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                        display: "block",
                      }}
                    >
                      <div
                        style={{
                          height: "10pc",
                          backgroundColor: "rgb(226, 226, 245, 0.11)",
                        }}
                        className="card invoice-card gradient-bx  rounded position-unset"
                      >
                        <div className="card-body billable-card">
                          <div className="media align-items-center">
                            <div className="media-body">
                              <p className=" fs-18 mb-1">
                                Total Plaid Accounts
                              </p>
                              <div
                                className="d-flex flex-wrap"
                                style={{ flexDirection: "column" }}
                              >
                                <h2
                                  className=" mb-0 me-3 invoice-num "
                                  style={{ fontSize: "40px" }}
                                >
                                  {dashboardData?.totalPlaidAccount
                                    ?.totalItems ?? "0"}
                                </h2>
                                <div className="d-flex">
                                  <svg
                                    width="28"
                                    height="19"
                                    viewBox="0 0 28 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M18.875 9.25C21.0787 11.6256 25.1753 16.0091 26.4375 17.5H1V1L10.625 13.375L18.875 9.25Z"
                                      fill="url(#paint0_linear1)"
                                    />
                                    <path
                                      d="M26.4375 17.5C25.1753 16.0091 21.0787 11.6256 18.875 9.25L10.625 13.375L1 1"
                                      stroke="white"
                                      stroke-width="2"
                                    />
                                    <defs>
                                      <linearGradient
                                        id="paint0_linear1"
                                        x1="13.7188"
                                        y1="3.0625"
                                        x2="12.6875"
                                        y2="17.5"
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop
                                          stop-color="white"
                                          stop-opacity="0.73"
                                          offset="0.1"
                                        />
                                        <stop
                                          offset="1"
                                          stop-color="white"
                                          stop-opacity="0"
                                        />
                                      </linearGradient>
                                    </defs>
                                  </svg>
                                  <div className="fs-14">
                                    {" "}
                                    <span
                                      style={{ color: "rgb(244, 107, 104)" }}
                                    >
                                      {" "}
                                      &nbsp; Upto{" "}
                                      {dashboardData?.totalPlaidAccount
                                        ?.progress ?? "0"}
                                      %
                                    </span>{" "}
                                    from the last week
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="col-md-4 md-4">
                    <Link
                      to="/Ocrs"
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                        display: "block",
                      }}
                    >
                      <div
                        style={{
                          height: "10pc",
                          backgroundColor: "rgb(226, 226, 245, 0.11)",
                        }}
                        className="card invoice-card gradient-bx  rounded position-unset"
                      >
                        <div className="card-body billable-card">
                          <div className="media align-items-center">
                            <div className="media-body">
                              <p className=" fs-18 mb-1">OCR</p>
                              <div
                                className="d-flex flex-wrap"
                                style={{ flexDirection: "column" }}
                              >
                                <h2
                                  className=" mb-0 me-3 invoice-num "
                                  style={{ fontSize: "40px" }}
                                >
                                  {dashboardData?.totalVixolinkOCR
                                    ?.totalItems ?? "0"}
                                </h2>
                                <div className="d-flex">
                                  <svg
                                    width="28"
                                    height="19"
                                    viewBox="0 0 28 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M18.875 9.25C21.0787 11.6256 25.1753 16.0091 26.4375 17.5H1V1L10.625 13.375L18.875 9.25Z"
                                      fill="url(#paint0_linear1)"
                                    />
                                    <path
                                      d="M26.4375 17.5C25.1753 16.0091 21.0787 11.6256 18.875 9.25L10.625 13.375L1 1"
                                      stroke="white"
                                      stroke-width="2"
                                    />
                                    <defs>
                                      <linearGradient
                                        id="paint0_linear1"
                                        x1="13.7188"
                                        y1="3.0625"
                                        x2="12.6875"
                                        y2="17.5"
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop
                                          stop-color="white"
                                          stop-opacity="0.73"
                                          offset="0.1"
                                        />
                                        <stop
                                          offset="1"
                                          stop-color="white"
                                          stop-opacity="0"
                                        />
                                      </linearGradient>
                                    </defs>
                                  </svg>
                                  <div className="fs-14">
                                    {" "}
                                    <span
                                      style={{ color: "rgb(244, 107, 104)" }}
                                    >
                                      {" "}
                                      &nbsp; Upto{" "}
                                      {dashboardData?.totalVixolinkOCR
                                        ?.progress ?? "0"}
                                      %
                                    </span>{" "}
                                    from the last week
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-4 md-4">
                    <Link
                      to="#"
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                        display: "block",
                      }}
                    >
                      <div
                        style={{
                          height: "10pc",
                          backgroundColor: "rgb(226, 226, 245, 0.11)",
                        }}
                        className="card invoice-card gradient-bx  rounded position-unset"
                      >
                        <div className="card-body billable-card">
                          <div className="media align-items-center">
                            <div className="media-body">
                              <p className=" fs-18 mb-1">
                                Total Vixo OCR Requests
                              </p>
                              <div
                                className="d-flex flex-wrap"
                                style={{ flexDirection: "column" }}
                              >
                                <h2
                                  className=" mb-0 me-3 invoice-num "
                                  style={{ fontSize: "40px" }}
                                >
                                  {dashboardData?.totalVixoLinkRequests
                                    ?.totalItems ?? "0"}
                                </h2>
                                <div className="d-flex">
                                  <svg
                                    width="28"
                                    height="19"
                                    viewBox="0 0 28 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M18.875 9.25C21.0787 11.6256 25.1753 16.0091 26.4375 17.5H1V1L10.625 13.375L18.875 9.25Z"
                                      fill="url(#paint0_linear1)"
                                    />
                                    <path
                                      d="M26.4375 17.5C25.1753 16.0091 21.0787 11.6256 18.875 9.25L10.625 13.375L1 1"
                                      stroke="white"
                                      stroke-width="2"
                                    />
                                    <defs>
                                      <linearGradient
                                        id="paint0_linear1"
                                        x1="13.7188"
                                        y1="3.0625"
                                        x2="12.6875"
                                        y2="17.5"
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop
                                          stop-color="white"
                                          stop-opacity="0.73"
                                          offset="0.1"
                                        />
                                        <stop
                                          offset="1"
                                          stop-color="white"
                                          stop-opacity="0"
                                        />
                                      </linearGradient>
                                    </defs>
                                  </svg>
                                  <div className="fs-14">
                                    {" "}
                                    <span
                                      style={{ color: "rgb(244, 107, 104)" }}
                                    >
                                      {" "}
                                      &nbsp; Upto{" "}
                                      {dashboardData?.totalVixoLinkRequests
                                        ?.progress ?? "0"}
                                      %
                                    </span>{" "}
                                    from the last week
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-4 md-4">
                    <Link
                      to="#"
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                        display: "block",
                      }}
                    >
                      <div
                        style={{
                          height: "10pc",
                          backgroundColor: "rgb(226, 226, 245, 0.11)",
                        }}
                        className="card invoice-card gradient-bx  rounded position-unset"
                      >
                        <div className="card-body billable-card">
                          <div className="media align-items-center">
                            <div className="media-body">
                              <p className=" fs-18 mb-1">
                                Total OCROLUS Requests
                              </p>
                              <div
                                className="d-flex flex-wrap"
                                style={{ flexDirection: "column" }}
                              >
                                <h2
                                  className=" mb-0 me-3 invoice-num "
                                  style={{ fontSize: "40px" }}
                                >
                                  {dashboardData?.totalOcrolusOcr?.totalItems ??
                                    "0"}
                                </h2>
                                <div className="d-flex">
                                  <svg
                                    width="28"
                                    height="19"
                                    viewBox="0 0 28 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M18.875 9.25C21.0787 11.6256 25.1753 16.0091 26.4375 17.5H1V1L10.625 13.375L18.875 9.25Z"
                                      fill="url(#paint0_linear1)"
                                    />
                                    <path
                                      d="M26.4375 17.5C25.1753 16.0091 21.0787 11.6256 18.875 9.25L10.625 13.375L1 1"
                                      stroke="white"
                                      stroke-width="2"
                                    />
                                    <defs>
                                      <linearGradient
                                        id="paint0_linear1"
                                        x1="13.7188"
                                        y1="3.0625"
                                        x2="12.6875"
                                        y2="17.5"
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop
                                          stop-color="white"
                                          stop-opacity="0.73"
                                          offset="0.1"
                                        />
                                        <stop
                                          offset="1"
                                          stop-color="white"
                                          stop-opacity="0"
                                        />
                                      </linearGradient>
                                    </defs>
                                  </svg>
                                  <div className="fs-14">
                                    {" "}
                                    <span
                                      style={{ color: "rgb(244, 107, 104)" }}
                                    >
                                      {" "}
                                      &nbsp; Upto{" "}
                                      {dashboardData?.totalOcrolusOcr
                                        ?.progress ?? "0"}
                                      %
                                    </span>{" "}
                                    from the last week
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-4 md-4">
                    <Link
                      to="/receivedpayment"
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                        display: "block",
                      }}
                    >
                      <div
                        style={{
                          height: "10pc",
                          backgroundColor: "rgb(226, 226, 245, 0.11)",
                        }}
                        className="card invoice-card gradient-bx  rounded position-unset"
                      >
                        <div className="card-body billable-card">
                          <div className="media align-items-center">
                            <div className="media-body">
                              <p className=" fs-18 mb-1">Received Payment</p>
                              <div
                                className="d-flex flex-wrap"
                                style={{ flexDirection: "column" }}
                              >
                                <h2
                                  className=" mb-0 me-3 invoice-num "
                                  style={{ fontSize: "40px" }}
                                >
                                  {dashboardData?.totalReceivedPayment
                                    ?.totalItems ?? "0"}
                                </h2>
                                <div className="d-flex">
                                  <svg
                                    width="28"
                                    height="19"
                                    viewBox="0 0 28 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M18.875 9.25C21.0787 11.6256 25.1753 16.0091 26.4375 17.5H1V1L10.625 13.375L18.875 9.25Z"
                                      fill="url(#paint0_linear1)"
                                    />
                                    <path
                                      d="M26.4375 17.5C25.1753 16.0091 21.0787 11.6256 18.875 9.25L10.625 13.375L1 1"
                                      stroke="white"
                                      stroke-width="2"
                                    />
                                    <defs>
                                      <linearGradient
                                        id="paint0_linear1"
                                        x1="13.7188"
                                        y1="3.0625"
                                        x2="12.6875"
                                        y2="17.5"
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop
                                          stop-color="white"
                                          stop-opacity="0.73"
                                          offset="0.1"
                                        />
                                        <stop
                                          offset="1"
                                          stop-color="white"
                                          stop-opacity="0"
                                        />
                                      </linearGradient>
                                    </defs>
                                  </svg>
                                  <div className="fs-14">
                                    {" "}
                                    <span
                                      style={{ color: "rgb(244, 107, 104)" }}
                                    >
                                      {" "}
                                      &nbsp; Upto{" "}
                                      {dashboardData?.totalReceivedPayment
                                        ?.progress ?? "0"}
                                      %
                                    </span>{" "}
                                    from the last week
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="col-md-4 md-4 ">
                    <Link
                      to="/billablecall"
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                        display: "block",
                      }}
                    >
                      <div
                        style={{
                          height: "10pc",
                          backgroundColor: "rgb(226, 226, 245, 0.11)",
                        }}
                        className="card invoice-card gradient-bx  rounded position-unset"
                      >
                        <div className="card-body billable-card ">
                          <div className="media align-items-center">
                            <div className="media-body">
                              <p className=" fs-18 mb-1">
                                Total Billable Calls
                              </p>
                              <div
                                className="d-flex flex-wrap"
                                style={{ flexDirection: "column" }}
                              >
                                <h2
                                  className=" mb-0 me-3 invoice-num "
                                  style={{ fontSize: "40px" }}
                                >
                                  {dashboardData?.totalBillableCalls
                                    ?.totalItems ?? "0"}
                                </h2>
                                <div className="d-flex">
                                  <svg
                                    width="28"
                                    height="19"
                                    viewBox="0 0 28 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M18.875 9.25C21.0787 11.6256 25.1753 16.0091 26.4375 17.5H1V1L10.625 13.375L18.875 9.25Z"
                                      fill="url(#paint0_linear1)"
                                    />
                                    <path
                                      d="M26.4375 17.5C25.1753 16.0091 21.0787 11.6256 18.875 9.25L10.625 13.375L1 1"
                                      stroke="white"
                                      stroke-width="2"
                                    />
                                    <defs>
                                      <linearGradient
                                        id="paint0_linear1"
                                        x1="13.7188"
                                        y1="3.0625"
                                        x2="12.6875"
                                        y2="17.5"
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop
                                          stop-color="white"
                                          stop-opacity="0.73"
                                          offset="0.1"
                                        />
                                        <stop
                                          offset="1"
                                          stop-color="white"
                                          stop-opacity="0"
                                        />
                                      </linearGradient>
                                    </defs>
                                  </svg>
                                  <div className="fs-14">
                                    {" "}
                                    <span
                                      style={{ color: "rgb(244, 107, 104)" }}
                                    >
                                      {" "}
                                      &nbsp; Upto{" "}
                                      {dashboardData?.totalBillableCalls
                                        ?.progress ?? "0"}
                                      %
                                    </span>{" "}
                                    from the last week
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-4 md-4">
                    <Link
                      to="#"
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                        display: "block",
                      }}
                    >
                      <div
                        style={{
                          height: "10pc",
                          backgroundColor: "rgb(226, 226, 245, 0.11)",
                        }}
                        className="card invoice-card gradient-bx  rounded position-unset"
                      >
                        <div className="card-body billable-card">
                          <div className="media align-items-center">
                            <div className="media-body">
                              <p className=" fs-18 mb-1">
                                Total DocuSign Evenlops
                              </p>
                              <div
                                className="d-flex flex-wrap"
                                style={{ flexDirection: "column" }}
                              >
                                <h2
                                  className=" mb-0 me-3 invoice-num "
                                  style={{ fontSize: "40px" }}
                                >
                                  {dashboardData?.totalDocuSignEnvelope
                                    ?.totalItems ?? "0"}
                                </h2>
                                <div className="d-flex">
                                  <svg
                                    width="28"
                                    height="19"
                                    viewBox="0 0 28 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M18.875 9.25C21.0787 11.6256 25.1753 16.0091 26.4375 17.5H1V1L10.625 13.375L18.875 9.25Z"
                                      fill="url(#paint0_linear1)"
                                    />
                                    <path
                                      d="M26.4375 17.5C25.1753 16.0091 21.0787 11.6256 18.875 9.25L10.625 13.375L1 1"
                                      stroke="white"
                                      stroke-width="2"
                                    />
                                    <defs>
                                      <linearGradient
                                        id="paint0_linear1"
                                        x1="13.7188"
                                        y1="3.0625"
                                        x2="12.6875"
                                        y2="17.5"
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop
                                          stop-color="white"
                                          stop-opacity="0.73"
                                          offset="0.1"
                                        />
                                        <stop
                                          offset="1"
                                          stop-color="white"
                                          stop-opacity="0"
                                        />
                                      </linearGradient>
                                    </defs>
                                  </svg>
                                  <div className="fs-14">
                                    {" "}
                                    <span
                                      style={{ color: "rgb(244, 107, 104)" }}
                                    >
                                      {" "}
                                      &nbsp; Upto{" "}
                                      {dashboardData?.totalDocuSignEnvelope
                                        ?.progress ?? "0"}
                                      %
                                    </span>{" "}
                                    from the last week
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-4 md-4">
                    <Link
                      to="#"
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                        display: "block",
                      }}
                    >
                      <div
                        style={{
                          height: "10pc",
                          backgroundColor: "rgb(226, 226, 245, 0.11)",
                        }}
                        className="card invoice-card gradient-bx  rounded position-unset"
                      >
                        <div className="card-body billable-card">
                          <div className="media align-items-center">
                            <div className="media-body">
                              <p className=" fs-18 mb-1">
                                Total Plaid Identity Requests Created
                              </p>
                              <div
                                className="d-flex flex-wrap"
                                style={{ flexDirection: "column" }}
                              >
                                <h2
                                  className=" mb-0 me-3 invoice-num "
                                  style={{ fontSize: "40px" }}
                                >
                                  {dashboardData?.totalPlaidIdentityRequest
                                    ?.totalItems ?? "0"}
                                </h2>
                                <div className="d-flex">
                                  <svg
                                    width="28"
                                    height="19"
                                    viewBox="0 0 28 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M18.875 9.25C21.0787 11.6256 25.1753 16.0091 26.4375 17.5H1V1L10.625 13.375L18.875 9.25Z"
                                      fill="url(#paint0_linear1)"
                                    />
                                    <path
                                      d="M26.4375 17.5C25.1753 16.0091 21.0787 11.6256 18.875 9.25L10.625 13.375L1 1"
                                      stroke="white"
                                      stroke-width="2"
                                    />
                                    <defs>
                                      <linearGradient
                                        id="paint0_linear1"
                                        x1="13.7188"
                                        y1="3.0625"
                                        x2="12.6875"
                                        y2="17.5"
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop
                                          stop-color="white"
                                          stop-opacity="0.73"
                                          offset="0.1"
                                        />
                                        <stop
                                          offset="1"
                                          stop-color="white"
                                          stop-opacity="0"
                                        />
                                      </linearGradient>
                                    </defs>
                                  </svg>
                                  <div className="fs-14">
                                    {" "}
                                    <span
                                      style={{ color: "rgb(244, 107, 104)" }}
                                    >
                                      {" "}
                                      &nbsp; Upto{" "}
                                      {dashboardData?.totalPlaidIdentityRequest
                                        ?.progress ?? "0"}
                                      %
                                    </span>{" "}
                                    from the last week
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              {/* <RightSidebar /> */}

              <div
                className="col-xl-12 col-xxl-12 col-lg-12"
                style={{ marginTop: "6pc" }}
              >
                <div className="filter cm-content-box box-primary">
                  <div className="content-title">
                    <div className="cpa  fs-24">
                      <h3 className="card-title fs-26">Recent organization</h3>
                    </div>
                    <div className="input-group search-area  searchbar">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search here..."
                      />
                      <span className="input-group-text">
                        <a href="javascript:void(0)">
                          <i className="flaticon-381-search-2"></i>
                        </a>
                      </span>
                    </div>
                  </div>
                  <div className="cm-content-body form excerpt">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-responsive-sm mb-0">
                          <thead>
                            <tr>
                              <th className="">Organization Name</th>
                              <th>Email</th>
                              <th>phone</th>
                              <th>Role</th>
                              <th>Created Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {recentOrg != null &&
                              recentOrg?.length > 0 &&
                              recentOrg.map((org, index) => (
                                <tr key={index}>
                                  <td>
                                    <b>{org.name}</b>
                                  </td>
                                  <td>{org.email}</td>
                                  <td>{org.phonePrimary}</td>
                                  <td>{org.roleName}</td>
                                  <td>
                                    {" "}
                                    {format(
                                      new Date(org.createdDate),
                                      "yyyy-MM-dd"
                                    )}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Toaster position="bottom-right" reverseOrder={true} />
        <div className="footer">
          <div className="copyright">
            <p>
              Copyright © Designed &amp; Developed by{" "}
              <a href="#" target="_blank">
                Vixo
              </a>{" "}
              {currentYear}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashbaord;
