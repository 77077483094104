import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AdminNavbar from "../Navbar/AdminNavbar";
import Sidebar from "../Header/Sidebar";
import "./Call.css";
import axios from "axios";
import moment from "moment";
import { VixoAPI } from "../../settings";
import { ToastContainer, toast } from "react-toastify";
import DateComponent from "../VixoFormats/Date";
import Spinner from "../../Spinner";
import { OpenBankingType } from "../Layout/Lookup";
import { format } from "date-fns";
import {useNavigate } from "react-router-dom";
import { CheckAllowComponentAndRedirect } from "../../Authentication";
import { APIHeader } from "../../APICallWrapper";
import { HasLoggedInUser } from "../../APICallWrapper";
const OpenBanking = () => {
  const [searchQuery, setSearchQuery] = useState("");

  const [selectedOption, setSelectedOption] = useState("1");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedOptionorgnization, setselectedOptionorgnization] =
    useState(null);
  const [openBankingData, setgetopenBankingData] = useState([]);
  const [AllOpenBanking, SetAllOpenBanking] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleDateRange();
  }, [selectedOption]);

  useEffect(() => {
    getOpenBankingData(selectedOptionorgnization);
  }, [selectedOptionorgnization, startDate, endDate]);

  useEffect(() => {
    LoadLookups();
    CheckAllowComponentAndRedirect(ComponentName,navigate);
  }, []);
  const [OpenBankingRoles, setOpenBankingRoles] = useState([]);

  async function LoadLookups() {
    try {
      OpenBankingType()
        .then((result) => {
          setOpenBankingRoles(result);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (ex) {
      console.error(
        ex + " setOpenBankingRoles Role Lookup Call is not successfull"
      );
    }
  }
  const handleDateRange = () => {
    const today = new Date();
    switch (selectedOption) {
      case "1":
        // This Month
        setStartDate(new Date(today.getFullYear(), today.getMonth(), 1));
        setEndDate(new Date(today.getFullYear(), today.getMonth() + 1, 0));
        break;
      case "2":
        // Last Month
        setStartDate(new Date(today.getFullYear(), today.getMonth() - 1, 1));
        setEndDate(new Date(today.getFullYear(), today.getMonth(), 0));
        break;
      case "3":
        // Last Three Months
        setStartDate(new Date(today.getFullYear(), today.getMonth() - 3, 1));
        setEndDate(new Date(today.getFullYear(), today.getMonth(), 0));
        break;
      case "4":
        // Last Six Months
        setStartDate(new Date(today.getFullYear(), today.getMonth() - 6, 1));
        setEndDate(new Date(today.getFullYear(), today.getMonth(), 0));
        break;
      case "5":
        // Last Nine Months
        setStartDate(new Date(today.getFullYear(), today.getMonth() - 9, 1));
        setEndDate(new Date(today.getFullYear(), today.getMonth(), 0));
        break;
      case "6":
        // This Year
        setStartDate(new Date(today.getFullYear(), 0, 1));
        setEndDate(new Date(today.getFullYear(), 11, 31));
        break;
      case "8":
        // Custom Date, do nothing as the date picker will handle it
        break;
      case "9":
        // All Time, set the earliest and latest possible dates
        setStartDate(new Date(2010, 0, 1));
        setEndDate(today);
        break;
      default:
    }
  };
  const handleDropdownChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
  };
  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleOrganizationDropdownChange = (event) => {
    setselectedOptionorgnization(
      event.target.value === "0" ? null : event.target.value
    );
  };
  const navigate = useNavigate();
  const ComponentName='Open Bank';
  function getOpenBankingData(selectedOptionorgnization) {
    if (HasLoggedInUser()) {
      try {
        setLoading(true);

        const apiUrl = `${VixoAPI.AdminDashboardAPI}getOpenBanking`;

        const requestData = {
          startCreatedDate: moment(startDate, "YY MMM DO").format("YYYY-MM-DD"),
          endCreatedDate: moment(endDate, "YY MMM DO").format("YYYY-MM-DD"),
          openBankingType: selectedOptionorgnization,
        };

        axios
          .post(apiUrl, requestData, { headers: APIHeader().headers })
          .then((response) => {
            const responseData = response.data;

            if (responseData.statusCode === 200) {
              setgetopenBankingData(responseData.data);
            } else {
              toast.error(responseData.Message);
            }
          })
          .catch((error) => {
            // Handle error

            toast.error("An error occurred while fetching data.");
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
        console.error("Error:", error);
        toast.error("An error occurred.");
      }
    } else {
      navigate("/");
    }
  }

  //
  return (
    <>
      <AdminNavbar />
      <Sidebar />
      <Spinner show={loading} />
      <div className="content-body">
        <div className="container-fluid">
          <div className="row invoice-card-row  mt-4">
            <div style={{ textAlignLast: "center" }}>
              <img
                id="logo"
                src="image/3.png"
                style={{ width: "300px" }}
                alt="images"
              />
            </div>
            {/* months with date dropdown */}
            <div className="d-flex align-items-center gap-1 my-3">
              <div className="start-date-picker d-flex align-items-center gap-1">
                {(selectedOption === "8" ||
                  selectedOption === "1" ||
                  selectedOption === "2" ||
                  selectedOption === "3" ||
                  selectedOption === "4" ||
                  selectedOption === "5" ||
                  selectedOption === "6") && (
                  <>
                    <label style={{ color: "white", fontSize: "14px" }}>
                      Start Date:{" "}
                    </label>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      disabled={selectedOption !== "8"}
                      dateFormat="MM-dd-yy"
                    />
                  </>
                )}
              </div>

              <div className="end-date-picker d-flex align-items-center gap-1">
                {(selectedOption === "8" ||
                  selectedOption === "1" ||
                  selectedOption === "2" ||
                  selectedOption === "3" ||
                  selectedOption === "4" ||
                  selectedOption === "5" ||
                  selectedOption === "6") && (
                  <>
                    <label style={{ color: "white", fontSize: "14px" }}>
                      End Date:{" "}
                    </label>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      disabled={selectedOption !== "8"}
                      dateFormat="MM-dd-yy"
                    />
                  </>
                )}
              </div>

              <div className="row text-center" style={{ marginLeft: "2rem" }}>
                <div className="text-end">
                  <select
                    className="dropdowntabmob h-80 rounded form-control"
                    value={selectedOption}
                    onChange={handleDropdownChange}
                  >
                    <option value="1">This Month</option>
                    <option value="2">Last Month</option>
                    <option value="3">Last Three Month</option>
                    <option value="4">Last Six Month</option>
                    <option value="5">Last Nine Month</option>
                    <option value="6">This Year</option>
                    <option value="8">Custom Date</option>
                    <option value="9">All Time</option>
                  </select>
                </div>
              </div>
            </div>
            <br></br>
            <div className="filter cm-content-box box-primary">
              <div className="content-title" style={{ padding: "5px" }}>
                <div className="cpa  fs-24">
                  <h3 className="card-title fs-26">Open Banking</h3>
                </div>

                {/* orgnization dropdown */}
                <div className="d-flex align-items-center justify-content-between">
                  <div
                    className="row text-center"
                    style={{ marginLeft: "2rem" }}
                  >
                    <div className="text-end">
                      <select
                        style={{ alignContent: "end", border: "20%" }}
                        className="dropdowntabmob h-80  rounded form-control"
                        value={selectedOptionorgnization}
                        onChange={handleOrganizationDropdownChange}
                      >
                        {OpenBankingRoles != null &&
                          OpenBankingRoles.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.displayValue}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div
                  className="input-group search-area"
                  style={{ width: "16%" }}
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here..."
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                  />
                  <span className="input-group-text">
                    <a
                      href="javascript:void(0)"
                      style={{ textDecoration: "none" }}
                    >
                      <i className="flaticon-381-search-2"></i>
                    </a>
                  </span>
                </div>
              </div>
            </div>

            <div className="filter cm-content-box box-primary">
              <div
                className="form excerpt"
                style={{ overflowY: "scroll", height: "470px" }}
              >
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-responsive-sm mb-0">
                      <thead>
                        <tr>
                          <th>Date</th>

                          <th>Customer Name</th>
                          <th>Open Banking Type</th>
                          <th>Bank Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {openBankingData && openBankingData.length > 0 ? (
                          openBankingData
                            .filter((item) => {
                              const customerName = item.customerName
                                ? item.customerName.toLowerCase()
                                : "";
                              const name = item.name
                                ? item.name.toLowerCase()
                                : "";
                              const institutionName = item.institutionName
                                ? item.institutionName.toLowerCase()
                                : "";
                              const createdDate = item.createdDate
                                ? item.createdDate.toLowerCase()
                                : "";

                              return (
                                customerName.includes(
                                  searchQuery.toLowerCase()
                                ) ||
                                createdDate.includes(
                                  searchQuery.toLowerCase()
                                ) ||
                                name.includes(searchQuery.toLowerCase()) ||
                                institutionName.includes(
                                  searchQuery.toLowerCase()
                                )
                              );
                            })
                            .map((item, index) => (
                              <tr key={index}>
                                <td>
                                  {/* <DateComponent value={item.createdDate} /> */}
                                  {format(
                                    new Date(item.createdDate),
                                    "yyyy-MM-dd"
                                  )}
                                </td>
                                <td>{item.customerName}</td>

                                <td>{item.name} </td>
                                <td>{item.institutionName}</td>
                              </tr>
                            ))
                        ) : (
                          <tr>
                            <td colSpan="4" className="text-center fs-4">
                              No data found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OpenBanking;
