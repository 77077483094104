import React, { useState, useEffect } from "react";
import Sidebar from "../Header/Sidebar";
import { VixoAPI } from "../../settings";
import axios from "axios";
import Spinner from "../../Spinner";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGears } from "@fortawesome/free-solid-svg-icons";
import InputMask from "react-input-mask";
import AdminNavbar from "../Navbar/AdminNavbar";
import { toast } from "react-toastify";
import { APIHeader } from "../../APICallWrapper";
import { HasLoggedInUser } from "../../APICallWrapper";
function EditUser() {
  const [isChecked, setIsChecked] = useState([false, false]);
  const [User, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [phoneIsValid, setPhoneIsValid] = useState(true);
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [oldEmail, setOldEmail] = useState("");
  const [userInfo, setUserInfo] = useState({
    id: "",
    organizationId: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    // emailIsConfirmed: true,
    // phoneIsConfirmed: true,
    // isActive: true,
    // isAdmin: true,
    // organizationName: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    LoadData();
  }, []);

  const LoadData = () => {
    const loggedInUser = localStorage.getItem("User");
    var userObject = JSON.parse(loggedInUser);
    if (userObject != null) {
      
      setUserInfo(userObject);
     setOldEmail(userObject.email);
    } else {
      localStorage.clear();
      navigate("/");
    }
  };

  const handleUserInfoChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({
      ...userInfo,
      [name]: value,
    });

    if (name === "email") {
      validateEmail(value);
    }

    if (name === "phone") {
      validatePhone(value);
    }
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailIsValid(emailPattern.test(email));
  };

  const validatePhone = (phone) => {
    const phonePattern = /^\(\d{3}\) \d{3}-\d{4}$/;
    setPhoneIsValid(phonePattern.test(phone));
  };

  const handleUpdateUser = async (e) => {
      if (HasLoggedInUser()) {
        e.preventDefault();
        setLoading(true);

        const data = {
          ...userInfo,
          OldEmail: oldEmail,
        };

        try {
          const UserUpdate = `${VixoAPI.AdminDashboardAPI}update?Id=${User.id}`;
          const response = await axios.put(UserUpdate, data, {
            headers: {
              headers: APIHeader().headers,
              e,
            },
          });

          if (response.data.statusCode === 200) {
            setUser(response.data.data);
            localStorage.setItem("User", JSON.stringify(response.data.data));
            toast.success(response.message);
          } else {
            toast.error(response.data.message);
          }
        } catch (error) {
          toast.error("Failed to update user");
          console.error(error);
        } finally {
          setLoading(false);
        }
      } else {
        navigate("/");
      }
  };

  return (
    <>
      <div id="main-wrapper">
        <AdminNavbar />
        <Sidebar />

        <Spinner show={loading} />
        <div className="content-body">
          <div className="container-fluid">
            <div className="col-xl-12 col-xxl-12 col-lg-12">
              <br></br>
              <div className="filter cm-content-box box-primary">
                <div className="content-title">
                  <div className="fs-24">
                    <div className="py-3 bg-transparent">
                      <h5 className="mb-0 d-inline">Setting</h5>
                      <FontAwesomeIcon
                        icon={faGears}
                        className="fa-2x"
                        style={{ width: "25px" }}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="cm-content-body form excerpt"
                  style={{ overflowY: "scroll", height: "600px" }}
                >
                  <div className="card-body">
                    <div className="py-3 px-3 bg-transparent">
                      <h5 className="mb-0 d-inline">User Profile</h5>
                    </div>

                    <div className="py-3 bg-transparent">
                      <fieldset>
                        <legend className="user-info">
                          Personal Information
                        </legend>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="firstName" className="form-label">
                                First Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="firstName"
                                placeholder="Enter your First Name"
                                name="firstName"
                                value={userInfo.firstName}
                                onChange={handleUserInfoChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="lastName" className="form-label">
                                Last Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="lastName"
                                placeholder="Enter your Last Name"
                                name="lastName"
                                value={userInfo.lastName}
                                onChange={handleUserInfoChange}
                              />
                            </div>
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="email" className="form-label">
                                Email
                              </label>
                              <input
                                type="text"
                                className={`form-control ${
                                  !emailIsValid ? "is-invalid" : ""
                                }`}
                                id="email"
                                placeholder="example@vixolink.com"
                                name="email"
                                value={userInfo.email}
                              />
                              {!emailIsValid && (
                                <span className="error">
                                  Enter a valid email address.
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="phone" className="form-label">
                                Phone
                              </label>
                              <InputMask
                                mask="(999) 999-9999"
                                type="tel"
                                name="phone"
                                className={`form-control ${
                                  !phoneIsValid ? "is-invalid" : ""
                                }`}
                                id="phone"
                                placeholder="Phone"
                                value={userInfo.phone}
                                onChange={handleUserInfoChange}
                              />
                              {!phoneIsValid && (
                                <span className="error">
                                  Enter a valid phone number exactly 10 digits.
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </fieldset>

                      <form >
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group float-end">
                              <br />
                              <div className="col-md-12 container text-center">
                                <button
                                  type="submit"
                                  className="btn btn-secondary"
                                  disabled={!phoneIsValid || !emailIsValid}
                                  style={{ background: "#94618e" }}
                                  onClick={handleUpdateUser}
                                >
                                  Save Changes
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer">
          <div className="copyright">
            <p>
              Copyright © Designed & Developed by{" "}
              <a href="#" target="_blank">
                Vixo
              </a>{" "}
              2023
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditUser;
