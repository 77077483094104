import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AdminNavbar from "../Navbar/AdminNavbar";
import Sidebar from "../Header/Sidebar";
import "./Call.css";
import moment from "moment";
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { VixoAPI } from "../../settings";
import { ToastContainer, toast } from "react-toastify";
import DateComponent from "../VixoFormats/Date";
import Spinner from "../../Spinner";
import { format } from "date-fns";
import { CheckAllowComponentAndRedirect } from "../../Authentication";
import { APIHeader } from "../../APICallWrapper";
import { HasLoggedInUser } from "../../APICallWrapper"; 
const BillableCall = () => {
  const navigate = useNavigate();
  const ComponentName='Billabele Call';

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedValue, setSelectedValue] = useState();
  const [selectedOption, setSelectedOption] = useState("1");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [selectedOptionorgnization, setselectedOptionorgnization] = useState('-1');
  const [allBillableCall, setAllBillableCall] = useState([]);
 const [AllOrganizations, SetAllOrganizations] = useState([]);


  // dropdowns
   const handleSearchInputChange = (e) => {
     setSearchQuery(e.target.value);
   };

 
 useEffect(() => {
  CheckAllowComponentAndRedirect(ComponentName,navigate);
   GetAllOrganizationsFromAPI();
 }, []);


 useEffect(() => {
   handleDateRange();
  
 }, [selectedOption]);


      useEffect(() => {

              getBillableCallsAPI();
      }, [selectedOptionorgnization,startDate,endDate]);

        const handleDateRange = () => {
          const today = new Date();
          switch (selectedOption) {
            case "1":
              // This Month
              setStartDate(new Date(today.getFullYear(), today.getMonth(), 1));
              setEndDate(
                new Date(today.getFullYear(), today.getMonth() + 1, 0)
              );
              break;
            case "2":
              // Last Month
              setStartDate(
                new Date(today.getFullYear(), today.getMonth() - 1, 1)
              );
              setEndDate(new Date(today.getFullYear(), today.getMonth(), 0));
              break;
            case "3":
              // Last Three Months
              setStartDate(
                new Date(today.getFullYear(), today.getMonth() - 3, 1)
              );
              setEndDate(new Date(today.getFullYear(), today.getMonth(), 0));
              break;
            case "4":
              // Last Six Months
              setStartDate(
                new Date(today.getFullYear(), today.getMonth() - 6, 1)
              );
              setEndDate(new Date(today.getFullYear(), today.getMonth(), 0));
              break;
            case "5":
              // Last Nine Months
              setStartDate(
                new Date(today.getFullYear(), today.getMonth() - 9, 1)
              );
              setEndDate(new Date(today.getFullYear(), today.getMonth(), 0));
              break;
            case "6":
              // This Year
              setStartDate(new Date(today.getFullYear(), 0, 1));
              setEndDate(new Date(today.getFullYear(), 11, 31));
              break;
            case "8":
              // Custom Date, do nothing as the date picker will handle it
              break;
            case "9":
              // All Time, set the earliest and latest possible dates
              setStartDate(new Date(2010,0,1));
              setEndDate(today);
              break;
            default:
              
          }
        };

   const handleDropdownChange = (event) => {
     const selectedValue = event.target.value;
     setSelectedOption(selectedValue);
   };

   const handleOrganizationDropdownChange = (event) => {
     setselectedOptionorgnization(event.target.value);
   };
 
 function SetOrganizationsData(APIResponseData)
 {
  if (APIResponseData != null && APIResponseData.length>0)
  {
    var lOrgnizations=[];
    const Lookups = {
      Id: -1,
      Display: 'All Organizations'
    };
    lOrgnizations.push(Lookups);
      APIResponseData.forEach((element) => {
        var isExists = lOrgnizations.some((p) => p.Id === element.id);
        if(isExists===false)
        {
          const Lookup = {
            Id: element.id,
            Display: element.name,
          };
        lOrgnizations.push(Lookup);
        }

      });
      SetAllOrganizations(lOrgnizations);
  }
 }


function GetAllOrganizationsFromAPI()
{
  if(HasLoggedInUser())
    {
 try {
   setLoading(true);
   const SetDataById = `${VixoAPI.AdminDashboardAPI}getAllOrganizationsLookup`;
   //setLoading(true);
   axios
     .get(SetDataById, {headers:APIHeader().headers})
     .then((response) => {
       var response = response.data;
       if (response.statusCode === 200) {
     
      
            SetOrganizationsData(response.data);
            
         toast.success(response.message);
       } else {
         //setError(response.message);
       }
     })
     .catch((error) => {
       //setLoading(false);
       throw error;
     })
     .finally(() => {
       setLoading(false);
     });
 } catch (error) {
   setLoading(false);
   throw error;
 }
  //Get Orgnizations from OrganiationAPI
    }else
    {
      navigate("/");
    }

  
}
  function getBillableCallsAPI() {
     if (HasLoggedInUser()) {
       try {
         setLoading(true);

         const apiUrl = `${VixoAPI.AdminDashboardAPI}getBillableCalls`;

         const requestData = {
           startDate: moment(startDate, "YY MMM DO").format("YYYY-MM-DD"),
           endDate: moment(endDate, "YY MMM DO").format("YYYY-MM-DD"),
           organizationID:
             selectedOptionorgnization === "-1"
               ? null
               : selectedOptionorgnization,
         };

         axios
           .post(apiUrl, requestData, { headers: APIHeader().headers })
           .then((response) => {
             const responseData = response.data;

             if (responseData.statusCode === 200) {
               setAllBillableCall(responseData.data);
             } else {
               toast.error(responseData.Message);
             }
           })
           .catch((error) => {
             toast.error("An error occurred while fetching data.");
           })
           .finally(() => {
             setLoading(false);
           });
       } catch (error) {
         setLoading(false);
         console.error("Error:", error);
         toast.error("An error occurred.");
       }
     } else {
       navigate("/");
     }
  }



 
  return (
    <>
      <AdminNavbar />
      <Sidebar />
      <Spinner show={loading} />
      <div className="content-body">
        <div className="container-fluid">
          <div className="row invoice-card-row  mt-4">
            <div style={{ textAlignLast: "center" }}>
              <img id="logo" src="image/3.png" style={{ width: "300px" }} />
            </div>
            {/* months with date dropdown */}
            <div className="d-flex align-items-center gap-1 my-3">
              <div className="start-date-picker d-flex align-items-center gap-1">
                {(selectedOption === "8" ||
                  selectedOption === "1" ||
                  selectedOption === "2" ||
                  selectedOption === "3" ||
                  selectedOption === "4" ||
                  selectedOption === "5" ||
                  selectedOption === "6") && (
                  <>
                    <label style={{ color: "white", fontSize: "14px" }}>
                      Start Date:{" "}
                    </label>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      disabled={selectedOption !== "8"}
                      dateFormat="MM-dd-yy"
                    />
                  </>
                )}
              </div>

              <div className="end-date-picker d-flex align-items-center gap-1">
                {(selectedOption === "8" ||
                  selectedOption === "1" ||
                  selectedOption === "2" ||
                  selectedOption === "3" ||
                  selectedOption === "4" ||
                  selectedOption === "5" ||
                  selectedOption === "6") && (
                  <>
                    <label style={{ color: "white", fontSize: "14px" }}>
                      End Date:{" "}
                    </label>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      disabled={selectedOption !== "8"}
                      dateFormat="MM-dd-yy"
                    />
                  </>
                )}
              </div>

              <div className="row text-center" style={{ marginLeft: "2rem" }}>
                <div className="text-end">
                  <select
                    className="dropdowntabmob h-80 rounded form-control"
                    value={selectedOption}
                    onChange={handleDropdownChange}
                  >
                    <option value="1">This Month</option>
                    <option value="2">Last Month</option>
                    <option value="3">Last Three Month</option>
                    <option value="4">Last Six Month</option>
                    <option value="5">Last Nine Month</option>
                    <option value="6">This Year</option>
                    <option value="8">Custom Date</option>
                    <option value="9">All Time</option>
                  </select>
                </div>
              </div>
            </div>
            <br></br>
            <div className="filter cm-content-box box-primary">
              <div className="content-title" style={{ padding: "5px" }}>
                <div className="cpa  fs-24">
                  <h3 className="card-title fs-26">Billable Call</h3>
                </div>

                {/* orgnization dropdown */}
                <div className="d-flex align-items-center justify-content-between">
                  <div
                    className="row text-center"
                    style={{ marginLeft: "2rem" }}
                  >
                    <div className="text-end">
                      <div>
                        <select
                          style={{ alignContent: "end", border: "20%" }}
                          className="dropdowntabmob h-80 rounded form-control"
                          value={selectedOptionorgnization}
                          onChange={handleOrganizationDropdownChange}
                        >
                          {AllOrganizations.map((organization) => (
                            <option
                              key={organization.Id}
                              value={organization.Id}
                            >
                              {organization.Display}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="input-group search-area "
                  style={{ width: "16%" }}
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here..."
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                  />
                  <span className="input-group-text">
                    <a
                      href="javascript:void(0)"
                      style={{ textDecoration: "none" }}
                    >
                      <i className="flaticon-381-search-2"></i>
                    </a>
                  </span>
                </div>
              </div>
            </div>

            <div className="filter cm-content-box box-primary">
              <div
                className="form excerpt"
                style={{ overflowY: "scroll", height: "470px" }}
              >
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-responsive-sm mb-0">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Organizational Name</th>
                          <th>User ID</th>
                          <th>User Name</th>
                          <th>Event Name</th>
                          <th>Customer ID</th>
                          <th>Customer Name</th>
                          {/* <th>Description</th> */}
                          <th>Price</th>
                        </tr>
                      </thead>

                      <tbody>
                        {allBillableCall && allBillableCall.length > 0 ? (
                          allBillableCall
                            .filter((item) => {
                              const name = item.organizationName
                                ? item.organizationName.toLowerCase()
                                : "";
                              const userId = item.userId
                                ? item.userId.toString().toLowerCase()
                                : "";
                              const userName = item.userName
                                ? item.userName.toLowerCase()
                                : "";
                              const eventName = item.eventName
                                ? item.eventName.toLowerCase()
                                : "";
                              const customerId = item.customerId
                                ? item.customerId.toString().toLowerCase()
                                : "";
                              const createdDate = item.createdDate
                                ? item.createdDate.toLowerCase()
                                : "";
                             
                              const customerIdentity = item.customerIdentity
                                ? item.customerIdentity.toLowerCase()
                                : "";
                                 const price = item.price
                                   ? item.price.toString().toLowerCase()
                                   : "";
                              return (
                                name.includes(searchQuery.toLowerCase()) ||
                                price.includes(searchQuery.toLowerCase()) ||
                                userName.includes(searchQuery.toLowerCase()) ||
                                createdDate.includes(
                                  searchQuery.toLowerCase()
                                ) ||
                                eventName.includes(searchQuery.toLowerCase()) ||
                                customerId.includes(
                                  searchQuery.toLowerCase()
                                ) ||
                                customerIdentity.includes(
                                  searchQuery.toLowerCase()
                                ) ||
                                userId.includes(searchQuery.toLowerCase())
                              );
                            })
                            .map((item, index) => (
                              <tr key={index}>
                                <td>
                                  {/* <DateComponent value={item.createdDate} /> */}
                                  {format(
                                    new Date(item.createdDate),
                                    "yyyy-MM-dd"
                                  )}
                                </td>
                                <td>{item.organizationName}</td>
                                <td>{item.userId}</td>
                                <td>{item.userName}</td>
                                <td>{item.eventName}</td>
                                <td>{item.customerId}</td>
                                <td>
                                  {item.customerIdentity
                                    ? item.customerIdentity
                                    : "N/A"}
                                </td>
                                {/* <td>{item.description}</td> */}
                                <td>${item.price}.00</td>
                              </tr>
                            ))
                        ) : (
                          <tr>
                            <td colSpan="9" className="text-center fs-3">
                              No data found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BillableCall;
