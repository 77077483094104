import React, { useState, useEffect } from "react";
import { Nav, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";

function AdminNavbar() {
  const navigate = useNavigate();
  const handleLogout = async (e) => {
    e.preventDefault();
    localStorage.clear();
    navigate("/");
  };

  return (
    <>
      <nav
        class="navbar navbar-expand-lg container"
        style={{ background: "rgba(226, 226, 245, 0.11)", borderRadius: "1pc" }}
      >
        <div class=" top-navbar-right ms-3 ms-auto">
          <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
            <li class="nav-item">
              <Nav.Item>
                <Link
                  className="projects m-2 nav-link"
                  to="/General"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faGear} style={{ color: "#94618e" }} />
                </Link>
              </Nav.Item>
            </li>
            <li class="nav-item">
              <Nav.Item>
                <Link
                  className="m-2 nav-link projects"
                  onClick={handleLogout}
                  title="Logout"
                >
                  <FontAwesomeIcon
                    icon={faRightFromBracket}
                    style={{ color: "#94618e" }}
                  />
                </Link>
              </Nav.Item>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default AdminNavbar;
