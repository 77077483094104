import React, { useState, useEffect } from "react";
import Sidebar from "../Header/Sidebar";
import axios from "axios";
import { VixoAPI } from "../../settings";
// import "./AllUser.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserGear,
  faPenToSquare,
  faGears,
} from "@fortawesome/free-solid-svg-icons";
import Spinner from "../../Spinner";
import { ToastContainer, toast } from "react-toastify";
import AdminNavbar from "../Navbar/AdminNavbar";
import AddADminUser from "./UserModal/AddAdminUser";
import EditUser from "./UserModal/EditAdminUser";
import moment from "moment";
import {useNavigate } from "react-router-dom";
import { CheckAllowComponentAndRedirect } from "../../Authentication";
import { APIHeader } from "../../APICallWrapper";
import { HasLoggedInUser } from "../../APICallWrapper";
const Usermanagement = () => {
  const [loading, setLoading] = useState(false);
const currentYear = moment().format("YYYY");

const navigate = useNavigate();
const ComponentName='User Management';

  // Load All Organization API Call

  const [UserData, setUserData] = useState(null);
  const [showEditModal, setEditModalShow] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const [showAddModal, setAddModalShow] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleAddModalOpen = () => {
    setAddModalShow(true);
  };

  const handleEditModalOpen = (selectedUser) => {
    if (selectedUser) {
      setEditUser(selectedUser);
    } else {
      setEditUser(selectedUser);
    }
    setEditModalShow(true);
  };

  const handleUserDeletion = (userToDelete) => {
   
      // Delete the user from the usersArray
      setUserData(UserData.filter((user) => user.id !== userToDelete.id));
      // Close the modal
      handleEditModalClose();
   
  };

  const handleAddModalClose = (addedUser) => {
    if (addedUser) {
      userAdded(addedUser);
    }
    setAddModalShow(false);
  };

  function userAdded(addedUser) {
    setUserData((current) => [...current, addedUser]);
  }

  const handleEditModalClose = (editedUser) => {
    setEditModalShow(false);
    if (editedUser) {
      userUpdated(editedUser);
    }
  };
  const userUpdated = (updatedUser) => {
    // Find the index of the User you want to update
    const indexToUpdate = UserData.findIndex((user) => user.id === updatedUser.id
    );

    if (indexToUpdate !== -1) {
      // Create a copy of the data array and update the specific customer
      const updatedData = [...UserData];
      updatedData[indexToUpdate] = updatedUser;

      // Update the state with the new array
      setUserData(updatedData);
    }
  };
  useEffect(() => {
    LoadUser();
    CheckAllowComponentAndRedirect(ComponentName,navigate);
  }, []);
  function LoadUser() {
     if (HasLoggedInUser()) {
       try {
         setLoading(true);
         const SetDataById = `${VixoAPI.AdminDashboardAPI}getAdminUserall`;
         //setLoading(true);
         axios
           .get(SetDataById, {
             headers: APIHeader().headers,
           })
           .then((response) => {
             var response = response.data;
             if (response.statusCode === 200) {
               var Statdata = JSON.parse(JSON.stringify(response.data));
               setUserData(Statdata.reverse());
               toast.success(response.message);
             } else {
               //setError(response.message);
             }
           })
           .catch((error) => {
             //setLoading(false);
             throw error;
           })
           .finally(() => {
             setLoading(false);
           });
       } catch (error) {
         setLoading(false);
         throw error;
       }
     } else {
       navigate("/");
     }
  }
  return (
    <>
      <ToastContainer />

      <Spinner show={loading} />
      <div id="main-wrapper">
        <AdminNavbar />
        <Sidebar />
        <div className="content-body">
          <div className="container-fluid">
            <div className="row invoice-card-row  mt-4">
              <div className="col-xl-12 col-xxl-12 col-lg-12">
                <br></br>
                <div className="filter cm-content-box box-primary">
                  <div className="content-title">
                    <div className="fs-24">
                      <div className="py-3 bg-transparent">
                        <h5 className="mb-0 d-inline">Setting</h5>
                        <FontAwesomeIcon
                          icon={faGears}
                          className="fa-2x "
                          style={{ width: "25px" }}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="cm-content-body form excerpt"
                    style={{ overflowY: "scroll", height: "470px" }}
                  >
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-12 mx-auto">
                          <div className=" d-flex align-items-center justify-content-between col-6 py-3 px-3 fs-4 fw-bold col-md-auto col-lg-12">
                            <h5 className="mb-0 d-inline">Users</h5>
                            <div className="input-group search-area searchbar">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search here..."
                                value={searchQuery}
                                onChange={handleSearchInputChange}
                              />
                              <span className="input-group-text">
                                <a
                                  href="javascript:void(0)"
                                  style={{ textDecoration: "none" }}
                                >
                                  <i className="flaticon-381-search-2"></i>
                                </a>
                              </span>
                            </div>
                          </div>
                          <div
                            className="col-6 py-3 px-3 col-md-auto col-lg-2 float-md-end my-2"
                            style={{ marginTop: "-43px" }}
                          >
                            <button
                              style={{ backgroundColor: " rgb(148, 97, 142)" }}
                              className="btn btn-primary col-md-auto"
                              onClick={handleAddModalOpen}
                            >
                              ADD USER
                            </button>
                            {/* Pass showModal and handleModalClose as props to AddUser */}
                            <AddADminUser
                              showModal={showAddModal}
                              handleModalClose={handleAddModalClose}
                            />
                            <EditUser
                              showModal={showEditModal}
                              handleModalClose={handleEditModalClose}
                              editUser={editUser}
                              onDeleteUser={handleUserDeletion} // Pass the callback here
                            />
                          </div>
                        </div>
                      </div>
                      <div className="container">
                        <div className="table-responsive">
                          <table className="table table-responsive-sm mb-0">
                            <thead>
                              <tr>
                                <th className="">Name</th>
                                <th>Email</th>
                                <th>phone</th>
                                <th>Role</th>
                                <th>Edit</th>
                              </tr>
                            </thead>
                            <tbody>
                              {UserData && UserData.length > 0 ? (
                                UserData.filter((item) => {
                                  const name = item.name
                                    ? item.name.toLowerCase()
                                    : "";
                                  const email = item.email
                                    ? item.email.toLowerCase()
                                    : "";
                                  const phone = item.phone
                                    ? item.phone.toLowerCase()
                                    : "";
                                  const roleName = item.roleName
                                    ? item.roleName.toLowerCase()
                                    : "";

                                  return (
                                    name.includes(searchQuery.toLowerCase()) ||
                                    email.includes(searchQuery.toLowerCase()) ||
                                    phone.includes(searchQuery.toLowerCase()) ||
                                    roleName.includes(searchQuery.toLowerCase())
                                  );
                                }).map((user, userIndex) => (
                                  <tr key={userIndex}>
                                    <td>
                                      {" "}
                                      <FontAwesomeIcon icon={faUserGear} />
                                      {user.name}
                                    </td>
                                    <td>{user.email}</td>
                                    <td>{user.phone}</td>
                                    <td>{user.roleName}</td>
                                    <td>
                                      {user.viewAccess}{" "}
                                      <FontAwesomeIcon
                                        icon={faPenToSquare}
                                        className="fa-1x"
                                        onClick={() =>
                                          handleEditModalOpen(user)
                                        }
                                      />{" "}
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="4" className="text-center fs-4">
                                    No data found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="copyright">
            <p>
              Copyright © Designed &amp; Developed by{" "}
              <a href="#" target="_blank">
                Vixo
              </a>{" "}
              {currentYear}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Usermanagement;
