import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AdminNavbar from "../Navbar/AdminNavbar";
import Sidebar from "../Header/Sidebar";
import "./Call.css";
import moment from "moment";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VixoAPI } from "../../settings";
import { ToastContainer, toast } from "react-toastify";
import DateComponent from "../VixoFormats/Date";
import Spinner from "../../Spinner";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { CheckAllowComponentAndRedirect } from "../../Authentication";
import { APIHeader, HasLoggedInUser, ExpireToken } from "../../APICallWrapper";

const Features = () => {
  const [searchQuery, setSearchQuery] = useState("");

  const [loading, setLoading] = useState(false);
  const [selectedOptionorgnization, setselectedOptionorgnization] =
    useState("");

  const [FeatureData, setFeatureData] = useState([]);
  const [AllOrganizations, SetAllOrganizations] = useState([]);

  const navigate = useNavigate();
  const ComponentName = "Recent Activities";

  const [activeFeatures, setActiveFeatures] = useState(false);

  const handleToggle = (id) => {
    setActiveFeatures((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  // dropdowns
  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    CheckAllowComponentAndRedirect(ComponentName, navigate);
    GetAllOrganizationsFromAPI();
  }, []);

  useEffect(() => {
    if (selectedOptionorgnization) {
      getFeature(selectedOptionorgnization);
    }
  }, [selectedOptionorgnization]);

  const handleOrganizationDropdownChange = (event) => {
    setselectedOptionorgnization(event.target.value);
  };

  useEffect(() => {
    // Fetch organizations data when component mounts
    GetAllOrganizationsFromAPI();
  }, []); // Empty dependency array to run once on mount

  useEffect(() => {
    GetAllOrganizationsFromAPI();
  }, [selectedOptionorgnization]);

  const setOrganizationsData = (apiResponseData) => {
    if (apiResponseData != null && apiResponseData.length > 0) {
      const organizations = [];
      apiResponseData.forEach((element) => {
        // Check if organization with the same ID already exists in the list
        const isExists = organizations.some((org) => org.Id === element.id);
        if (!isExists) {
          // Add organization to the list if not already exists
          organizations.push({
            Id: element.id,
            Display: element.name,
          });
        }
      });
      // Set the list of organizations in the state
      SetAllOrganizations(organizations);

      // Set default selected organization ID if not already set
      if (!selectedOptionorgnization && organizations.length > 0) {
        
        setselectedOptionorgnization(organizations[0].Id); // Set the ID of the first organization by default
      }
    }
  };

  function GetAllOrganizationsFromAPI() {
    if (HasLoggedInUser()) {
      try {
        setLoading(true);
        const SetDataById = `${VixoAPI.AdminDashboardAPI}getAllOrganizationsLookup`;
        //setLoading(true);
        axios
          .get(SetDataById, {
            headers: APIHeader().headers,
          })
          .then((response) => {
            var response = response.data;
            if (response.statusCode === 200) {
              setOrganizationsData(response.data);

              toast.success(response.message);
            } else {
              //setError(response.message);
            }
          })
          .catch((error) => {
            //setLoading(false);
            throw error;
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
        throw error;
      }
    } else {
      navigate("/");
    }
    //Get Orgnizations from OrganiationAPI
  }



  function getFeature(Organizationid) {
    if (HasLoggedInUser()) {
      var Config = APIHeader();
      try {
        setLoading(true);

        const apiUrl = `${
          VixoAPI.AdminDashboardAPI
        }getOrganizationFeatures?OrganizationID=${parseInt(Organizationid)}`;

        axios
          .get(apiUrl, {
            headers: Config.headers,
          })
          .then((response) => {            const responseData = response.data;

            if (responseData.statusCode === 200) {
              setFeatureData(responseData.data);
            } else {
              toast.error(responseData.Message);
            }
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.status &&
              error.response.status === 401
            ) {
              ExpireToken();
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
        console.error("Error:", error);
        toast.error("An error occurred.");
      }
    } else {
      navigate("/");
    }
  }

    function UpdateFeature() {
      if (HasLoggedInUser()) {
        var Config = APIHeader();
        try {
          setLoading(true);

          const apiUrl = VixoAPI.AdminDashboardAPI +"updateOrganizationFeatuers";
         
          axios
            .put(
              apiUrl,
                FeatureData,
              {
                headers: Config.headers,
              }
            
            )
            .then((response) => {
              const responseData = response.data;

              if (responseData.statusCode === 200) {
                toast.success(responseData.message);
              } else {
                toast.error(responseData.Message);
              }
            })
            .catch((error) => {
              if (
                error.response &&
                error.response.status &&
                error.response.status === 401
              ) {
                ExpireToken();
              }
            })
            .finally(() => {
              setLoading(false);
            });
        } catch (error) {
          setLoading(false);
          console.error("Error:", error);
          toast.error("An error occurred.");
        }
      } else {
        navigate("/");
      }
    }

      
      const handleCheckboxChange = (index) => {
        const updatedFeatureData = [...FeatureData];
        const item = updatedFeatureData[index];
        item.isEnabled = !item.isEnabled;
        setFeatureData(updatedFeatureData);

      };
  return (
    <>
      <AdminNavbar />
      <ToastContainer/>
      <Sidebar />
      <Spinner show={loading} />
      <div className="content-body">
        <div className="container-fluid">
          <div className="row invoice-card-row  mt-4">
            <div style={{ textAlignLast: "center" }}>
              <img
                id="logo"
                src="image/3.png"
                style={{ width: "300px" }}
                alt=""
              />
            </div>

            <br></br>
            <div className="filter cm-content-box box-primary">
              <div className="content-title" style={{ padding: "5px" }}>
                <div className="cpa  fs-24">
                  <h3 className="card-title fs-26">Features</h3>
                </div>

                {/* orgnization dropdown */}
                <div className="d-flex align-items-center justify-content-between">
                  <div
                    className="row text-center"
                    style={{ marginLeft: "2rem" }}
                  >
                    <div className="text-end">
                      <div>
                        <select
                          style={{ alignContent: "end", border: "20%" }}
                          className="dropdowntabmob h-80 rounded form-control"
                          value={selectedOptionorgnization}
                          onChange={handleOrganizationDropdownChange}
                        >
                          {AllOrganizations.map((organization) => (
                            <option
                              key={organization.Id}
                              value={organization.Id}
                            >
                              {organization.Display}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="input-group search-area "
                  style={{ width: "16%" }}
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here..."
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                  />
                  <span className="input-group-text">
                    <a
                      href="javascript:void(0)"
                      style={{ textDecoration: "none" }}
                    >
                      <i className="flaticon-381-search-2"></i>
                    </a>
                  </span>
                </div>
              </div>
            </div>

            <div className="filter cm-content-box box-primary">
              <div
                className="form excerpt"
                style={{ overflowY: "scroll", height: "470px" }}
              >
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-end my-2">
                    <button
                      type="button"
                      className="btn updateBtn btn-info custom-button"
                      onClick={UpdateFeature}
                      style={{
                        borderRadius: "20px",
                        fontWeight: "500",
                        color: "white",
                        marginBlock: "10px",
                      }}
                      disabled={loading}
                    >
                      Update Features
                    </button>
                  </div>
                  {FeatureData !== null && FeatureData.length > 0 ? (
                    FeatureData.map((item, index) => (
                      <div
                        key={item.id}
                        className="row py-1 border-bottom border-primary"
                      >
                        <div className="col-6">
                          <p className="fs-5 text-white">{item.featureName}</p>
                        </div>
                        <div className="Editor col-6">
                          <div className="Toggle_btn">
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={item.isEnabled}
                                onChange={() => handleCheckboxChange(index)}
                                id={`flexCheckChecked-${index}`}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="text-white text-center">No features available</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Features;
