import React, { useState, useEffect } from "react";

import "react-datepicker/dist/react-datepicker.css";
import AdminNavbar from "../Navbar/AdminNavbar";
import Sidebar from "../Header/Sidebar";
import "./Call.css";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import EmailEditer from "../HtmlEditor/EmailEditer";
import { VixoAPI } from "../../settings";
import { APIHeader } from "../../APICallWrapper";
import { HasLoggedInUser } from "../../APICallWrapper";
import Spinner from "../../Spinner";
import { EmailEventType } from "../../Lookup";
import { Link, useNavigate } from "react-router-dom";
import { CheckAllowComponentAndRedirect } from "../Auth/RolebaseUi";


const EmailEdite = () => {
  const [loading, setLoading] = useState(false);
  const [IsActiveEmail, setIsActiveEmail] = useState(false);

  const navigate = useNavigate();
  const [ShowVixoEmail, setShowVixoEmail] = useState({
    subject: "",
    sms: "",
    detail: "",
  });

  const [VixoEmailId, setVixoEmailId] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
 const [EmailEventTypeLookup, setEmailEventTypeLookup] = useState([]);
  //  lookup
  useEffect(() => {
    LoadLookups();
  }, []);
  async function LoadLookups() {
    try {
      EmailEventType()
        .then((result) => {
          setEmailEventTypeLookup(result);
       
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (ex) {
      console.error(ex + "EmailEventType Lookup Call is not successfull");
    }
  }

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleToggle = () => {
    setIsActiveEmail(!IsActiveEmail);
  };

  useEffect(() => {
    // This will run when the component mounts
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id"); // Get the value of 'id' from the URL query string

    if (id) {
      setVixoEmailId(id);
      fetchGetEmailById(id);
    }
  }, []);

  useEffect(() => {
    fetchGetEmailById(VixoEmailId);
  }, []);

  function fetchGetEmailById(vixoId) {

    if (HasLoggedInUser()) {
      if(!vixoId)return;
      const OrgAPIPath = `${VixoAPI.AdminDashboardAPI}GetEmailById?ID=${vixoId}`;
      setLoading(true);
      axios
        .get(OrgAPIPath, {
          headers: APIHeader().headers,
        })
        .then((response) => {
          const responseData = response.data;
          if (responseData.statusCode === 200) {
            setShowVixoEmail(responseData.data);
            setIsActiveEmail(responseData.data.isActive);
            setSelectedOption(responseData.data.eventType);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      navigate("/");
    }
  }

  const handleUpdate = () => {
    if (HasLoggedInUser()) {
      setLoading(true);
      const data = {
        id: VixoEmailId,
        subject: ShowVixoEmail.subject,
        sms: ShowVixoEmail.sms,
        isActive: IsActiveEmail,
        detail: ShowVixoEmail.detail,
        eventType: parseInt(selectedOption),
        emailEventTypeName:
          EmailEventTypeLookup.find(
            (item) => item.id === parseInt(selectedOption)
          )?.displayValue || "string",
      };
      const OrgAPIPath = `${VixoAPI.AdminDashboardAPI}UpdateEmailbyID?ID=${VixoEmailId}`;

      axios
        .put(OrgAPIPath, data, {
          headers: APIHeader().headers,
        })
        .then((response) => {
          const responseData = response.data;
          if (responseData.statusCode === 200) {
            toast.success(responseData.message);
          } else {
            toast.error(responseData.message);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      navigate("/");
    }
  };

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setShowVixoEmail((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };

  const handleEditorDataChange = (newDetail) => {
    setShowVixoEmail((prevData) => ({
      ...prevData,
      detail: newDetail,
    }));
  };
  return (
    <>
      <AdminNavbar />
      <ToastContainer />

      <Sidebar />
      <Spinner show={loading} />
      <div className="content-body">
        <div className="container-fluid">
          <div className="row invoice-card-row  mt-4">
            <div style={{ textAlignLast: "center" }}>
              <img id="logo" src="image/3.png" style={{ width: "300px" }} />
            </div>

            <br></br>
            <div className="filter cm-content-box box-primary">
              <div className="content-title" style={{ padding: "5px" }}>
                <div className="cpa  fs-24">
                  <h3 className="card-title fs-26">Edit Email</h3>
                </div>
              </div>
            </div>

            <div className="filter cm-content-box box-primary">
              <div
                className="form excerpt"
                style={{ overflowY: "scroll", height: "470px" }}
              >
                <div className="card-body">
                  <div className="form-group">
                    <label>Subject</label>
                    <input
                      type="text"
                      name="subject"
                      className="form-control"
                      value={ShowVixoEmail.subject}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>SMS</label>
                    <input
                      type="text"
                      name="sms"
                      className="form-control"
                      value={ShowVixoEmail.sms}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>EmailEventType</label>
                    <select
                      className="dropdowntabmob h-80 rounded form-control"
                      value={selectedOption}
                      onChange={handleSelectChange}
                    >
                      {EmailEventTypeLookup != null &&
                        EmailEventTypeLookup.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.displayValue}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="table-responsive">
                    <div className="Html_Editor mt-2 ">
                      <EmailEditer
                        data={ShowVixoEmail.detail}
                        onDataChange={handleEditorDataChange}
                      />
                    </div>
                    <div className="Editor my-2">
                      <div className="Toggle_btn">
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={IsActiveEmail}
                            onClick={handleToggle}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="btn updateBtn btn-info custom-button"
                      onClick={handleUpdate}
                      style={{
                        borderRadius: "20px",
                        fontWeight: "500",
                        color: "white",
                        marginBlock: "10px",
                      }}
                    >
                      Update Email
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailEdite;
