import React, { useState, useEffect } from "react";
import Sidebar from "../Header/Sidebar";
import RightSidebar from "../Layout/RightSidebar";
import { VixoAPI } from "../../settings";
import axios from "axios";
import Spinner from "../../Spinner";
import { format } from "date-fns";
import { PaymentStatusType } from "../../Lookup";
import { Toast } from "react-bootstrap";
import AdminNavbar from "../Navbar/AdminNavbar";
import { toast } from "react-toastify";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import * as signalR from "@microsoft/signalr";
import {useNavigate } from "react-router-dom";
import { CheckAllowComponentAndRedirect } from "../../Authentication";
import { APIHeader } from "../../APICallWrapper";
import { HasLoggedInUser } from "../../APICallWrapper";
import {selectNotification,addNotification,} from "../Notifications/Slices/sendNotificationSlice";
function Payment() {
  const [isChecked, setIsChecked] = useState([false, false]);

  const handleCheckboxChange = (index) => {
    const newCheckboxes = [...isChecked];
    newCheckboxes[index] = !newCheckboxes[index];
    setIsChecked(newCheckboxes);
  };

 const currentYear = moment().format("YYYY");

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    LoadPaymentRequest();
    LoadLookups();
    CheckAllowComponentAndRedirect(ComponentName,navigate);
  }, []);
  const [PaymentStatus, setPaymentStatus] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [notifications, setNotifications] = useState({});
const notification = useSelector(selectNotification);
const dispatch = useDispatch();
  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${VixoAPI.NotificationAPI}notificationHub`) // Adjust the URL based on your server setup
      .build();

    connection
      .start()
      .catch((error) => console.error("SignalR Connection Error:", error));

    connection.on("ReceiveNotification", (receivedNotification) => {
      const { organiationID, cutomerId, notificationType, referenceID } =
        receivedNotification;

      sendNotificationToRedux(receivedNotification);

      // Handle the notification data as needed in your React application
    });

    function sendNotificationToRedux(receivedNotification) {
      setNotifications(receivedNotification);
      dispatch(addNotification(receivedNotification));
      console.log("After dispatch AddNotification Func"(receivedNotification));
    }

    return () => {
      if (connection.state === signalR.HubConnectionState.Connected) {
        connection.stop();
      }
    };
  }, [dispatch]);
  const navigate = useNavigate();
  const ComponentName='FundingRequest';
  useEffect(() => {
    if (notifications != null && notification.notificationType === 29) {
      GotNewAlert(notifications);
    }
  }, [notifications]);

  function GotNewAlert(notification) {
    if (
      notification != null &&
      notification.referenceID != null &&
      notification.notificationType === 29
    ) {
      LoadPaymentRequest();
      console.log("LoadPaymentRequest api");
    }
  }
  async function LoadLookups() {
    
    try {
      PaymentStatusType()
        .then((result) => {
          setPaymentStatus(result);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (ex) {
      console.error(ex + " Payment Status Lookup Call is not successfull");
    }
  }
  const [PaymentData, setPaymentData] = useState([]);
  function LoadPaymentRequest() {
 if (HasLoggedInUser()) {
   try {
     setLoading(true);
     const SetDataById = `${VixoAPI.FundingAPI}getDashboardPaymentApprovalRequests`;
     //setLoading(true);
     axios
       .get(SetDataById, { headers: APIHeader().headers })
       .then((response) => {
         var response = response.data;
         if (response.statusCode === 200) {
           var Statdata = JSON.parse(JSON.stringify(response.data));
           setPaymentData(Statdata.reverse());
           toast.success(response.success);
         } else {
           //setError(response.message);
         }
       })
       .catch((error) => {
         //setLoading(false);
         throw error;
       })
       .finally(() => {
         setLoading(false);
       });
   } catch (error) {
     setLoading(false);
     throw error;
   }
 }else{
  navigate("/");
 }
  }
function UpdateExistingElement(Payment)
{
  const updatedArray = PaymentData.map((obj) =>
      obj.id === Payment.id ? { ...obj, ...Payment } : obj
    );

setPaymentData(updatedArray);

}
  const handleUpdatePaymentStatus = async (Payment,PaymentStatus) => {
 if (HasLoggedInUser()) {

    setLoading(true);
  const requestData={

    approvalRequestId: Payment.id,
    paymentRequestStatus : parseInt(PaymentStatus)
  }
    try {
      const apiUrl = `${VixoAPI.FundingAPI}UpdateOrganizationPaymentStatus`;
      const response = await axios.post(apiUrl, requestData, {headers:APIHeader().headers}); 
      if (response.data.statusCode === 200) {
        UpdateExistingElement(response.data.data);
      } else {
      }
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setLoading(false);
    }
  }else{
    navigate("/");
  }
  };
  

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };
  return (
    <>
      <div id="main-wrapper">
        <AdminNavbar />
        <Sidebar />
        <Spinner show={loading} />
        <div className="content-body">
          <div className="container-fluid">
            <div className="row invoice-card-row mt-4">
              <div className="col-lg-9 col-xl-12">
                <div style={{ textAlignLast: "center" }}>
                  <img id="logo" src="image/3.png" style={{ width: "300px" }} />
                </div>
                <br></br>
                <div className="filter cm-content-box box-primary">
                  <div className="content-title">
                    <div className="cpa  fs-24">
                      <h3 className="card-title fs-26"> Funding Request </h3>
                    </div>
                    <div className="input-group search-area searchbar">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search here..."
                        value={searchQuery}
                        onChange={handleSearchInputChange}
                      />
                      <span className="input-group-text">
                        <a href="javascript:void(0)">
                          <i className="flaticon-381-search-2"></i>
                        </a>
                      </span>
                    </div>
                  </div>
                  <div
                    className="cm-content-body form excerpt"
                    style={{ overflowY: "scroll", height: "600px" }}
                  >
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-responsive-sm mb-0">
                          <thead>
                            <tr>
                              <th className="">Organization Name</th>
                              <th>Requested User</th>
                              <th>Email</th>
                              <th>requestedDate</th>
                              <th>Status</th>
                            </tr>
                          </thead>

                          <tbody>
                            {PaymentData && PaymentData.length > 0 ? (
                              PaymentData.filter((item) => {
                                const name = item.organizationName
                                  ? item.organizationName.toLowerCase()
                                  : "";
                                const requestedUserName = item.requestedUserName
                                  ? item.requestedUserName.toLowerCase()
                                  : "";
                                const requestedUserEmail =
                                  item.requestedUserEmail
                                    ? item.requestedUserEmail.toLowerCase()
                                    : "";
                                const requestedDate = item.requestedDate
                                  ? item.requestedDate.toLowerCase()
                                  : "";

                                return (
                                  name.includes(searchQuery.toLowerCase()) ||
                                  requestedUserEmail.includes(
                                    searchQuery.toLowerCase()
                                  ) ||
                                  requestedDate.includes(
                                    searchQuery.toLowerCase()
                                  ) ||
                                  requestedUserName.includes(
                                    searchQuery.toLowerCase()
                                  )
                                );
                              }).map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    <b>{item.organizationName}</b>
                                  </td>
                                  <td>{item.requestedUserName}</td>
                                  <td>{item.requestedUserEmail}</td>
                                  <td>
                                    {" "}
                                    {format(
                                      new Date(item.requestedDate),
                                      "yyyy-MM-dd"
                                    )}
                                  </td>
                                  <select
                                    className="form-select text-bg-light"
                                    name="PaymentStatus"
                                    value={item.requestStatus}
                                    onChange={(e) => {
                                      handleUpdatePaymentStatus(
                                        item,
                                        e.target.value
                                      );
                                    }}
                                  >
                                    {PaymentStatus != null &&
                                      PaymentStatus.map((item) => (
                                        <option key={item.id} value={item.id}>
                                          {item.displayValue}
                                        </option>
                                      ))}
                                  </select>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="4" className="text-center fs-4">
                                  No data found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="copyright">
            <p>
              Copyright © Designed &amp; Developed by{" "}
              <a href="#" target="_blank">
                Vixo
              </a>{" "}
              {currentYear}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Payment;
