import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faPeopleGroup,
  faRightFromBracket,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { usePopper } from "react-popper";
import  dropdown  from "./dropdown.css" 

export const Dropdown = ({ handleLogout, referenceElement }) => {
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "top", // Positions the Dropdown below the button
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 10], // Adjust for spacing between the button and Dropup
        },
      },
    ],
  });

  return (
    <ulZ
      className="dropdwon-options shadow"
      ref={setPopperElement}
      style={{
        ...styles.popper,
        zIndex: 1000,
        borderRadius: "15px",
        display: "inline-table",
        color: "white"
      }}
      {...attributes.popper}
    >
      <li className=" mt-3 mx-3">
        {" "}
        <Link style={{ color: "rgb(1,1,1)" , textDecoration:"none" }} to={"/General"}>
          <FontAwesomeIcon icon={faUser} style={{ paddingRight: "10px" }} />
          Profile
        </Link>
      </li>
      <li className="mx-3">
        {" "}
        <Link style={{ color: "rgb(1,1,1)",  textDecoration:"none" }} to={"/ResetPassword"}>
          {" "}
          <FontAwesomeIcon
            icon={faLock}
            style={{ paddingRight: "10px" }}
          />{" "}
          Reset Password{""}
        </Link>
      </li>
      <li className="mx-3">
        {" "}
        <Link style={{ color: "rgb(1,1,1)" ,  textDecoration:"none" }} to={"/UserManagement"}>
          <FontAwesomeIcon
            icon={faPeopleGroup}
            style={{ paddingRight: "10px" }}
          />{" "}
          User
        </Link>
      </li>
      <li className="mb-3 mx-3">
        {" "}
        <Link onClick={handleLogout} style={{ color: "rgb(1,1,1)" ,  textDecoration:"none" }}>
          {" "}
          <FontAwesomeIcon
            icon={faRightFromBracket}
            rotation={180}
            style={{ paddingLeft: "10px" }}
          />
          Log-out
        </Link>{" "}
      </li>
    </ulZ>
  );
};

export default Dropdown;
