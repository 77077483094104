import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../Header/Sidebar";
import axios from "axios";
import { VixoAPI } from "../../settings";
import "./AllUser.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserGear,
  faArrowDownShortWide,
  faLandMineOn,
  faEllipsisVertical,
  faCircleCheck,
  faCircleXmark,
  faHandshakeAngle,
  faEye,
  faEyeSlash,
   faCopy,
   faSitemap
} from "@fortawesome/free-solid-svg-icons";
import { Dropdown, DropdownButton } from "react-bootstrap";
import Spinner from "../../Spinner";
import { ToastContainer, toast } from "react-toastify";
import RightSidebar from "../Layout/RightSidebar";
import AdminNavbar from "../Navbar/AdminNavbar";
import { Modal, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import {
  selectNotification,
  addNotification,
} from "../Notifications/Slices/sendNotificationSlice";
import * as signalR from "@microsoft/signalr";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
// import { selectNotification } from "../Notifications/Slices/sendNotificationSlice";
import { id } from "date-fns/locale";
import OTPVerification from "../ReuseableComponents/OTPVerification";
import { useSelector, useDispatch } from "react-redux";
import { Toast } from "bootstrap";
import moment from "moment";
import Sparkles from "react-sparkle";
import AddPartner from "./UserModal/AddPartner";
import { APIHeader } from "../../APICallWrapper";
import { HasLoggedInUser } from "../../APICallWrapper";
const AllUser = () => {
  // const notification = useSelector(selectNotification);
  const [isHamburgerActive, setHamburgerActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const buttonRef = useRef(null);
  const toggleHamburger = () => {
    setHamburgerActive(!isHamburgerActive);
    const menuToggle = document.getElementById("main-wrapper");
    if (isHamburgerActive) {
      menuToggle.classList.remove("menu-toggle", "show");
    } else {
      menuToggle.classList.add("menu-toggle", "show");
    }
  };
  const [Step, setStep] = useState(0);
  const [DeleteOrganizationAction] = useState("Delete Organization");
  const [GiveCredit] = useState("Give Credit");
  const [Support] = useState("Support");
  const [DebugSupport] = useState("Debug Support");
  const [organizationData, setOrganizationData] = useState([]);
  const [UserData, setUserData] = useState(null);
  const [openOrganizationId, setOpenOrganizationId] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationAction, setConfirmationAction] = useState("");
  const [selectedOrganization, setSelectedOrganization] = useState(null);
const [showModalPartner, setshowModalPartner] = useState(false);
  const [FreeCredit, setFreeCredit] = useState(null);
  const OTPClosed = () => setShouldShowOTP(false);
  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [notifications, setNotifications] = useState({});
  const notification = useSelector(selectNotification);
  // const [OTPClosed, setOTPClosed] = useState(false);
  const [OTPAction, setOTPAction] = useState("");
  const [OTPOrganizationID, setOTPOrganizationID] = useState("");
  const [ShouldShowOTP, setShouldShowOTP] = useState(false);
   const [modalEmail, setModalEmail] = useState("");
const dispatch = useDispatch();
  const navigate = useNavigate();
   const currentYear = moment().format("YYYY");
  //handleShowConfirmationModal
  const handleShowConfirmationModal = (action, orgId) => {
    setConfirmationAction(action);
    setOpenOrganizationId(orgId);
    setShowConfirmationModal(true);
  };

  const handleConfirmAction = () => {
    if (confirmationAction === "disable") {
      handleOrganizationisActive(openOrganizationId, true);
    } else if (confirmationAction === "enable") {
      handleOrganizationisActive(openOrganizationId, false);
    }

    // Close the modal after handling the action
    handleCloseConfirmationModal();
  };

   const handleAddModalOpen = () => {
    setshowModalPartner(true);
   
   };
   const handleAddModalClose = () => {
   setshowModalPartner(false);
    LoadOrganization();
   };

  // for notification
   const sparkleStyle = {
     fontSize: "1.5em",
     marginRight: "0.5em",
     color: "#4d4d4d",
   };

  function GotNewAlert(notifications) {
    if (notifications != null && notifications.notificationType == 20) {
      const organizationID = notifications.organiationID;
      const title = notifications.title;
      const description = notifications.detail;
      const notificationSound = new Audio(
        "NotificationSound/Notification1.mp3"
      );
      notificationSound.addEventListener("loadeddata", () => {
        // Play notification sound
        notificationSound.play();
      });

      toast.success(
        <div>
          <span role="img" aria-label="sparkle" style={sparkleStyle}>
            ✨
          </span>
          <span style={{ color: "#000000" }}>{description}</span>
        </div>,
        {
          style: {
            color: "#ffffff", // Text color
          },
        }
      );
      LoadOrganization(); ////call get all organizaiton
    }
  }



useEffect(() => {
  const connection = new signalR.HubConnectionBuilder()
    .withUrl(`${VixoAPI.NotificationAPI}notificationHub`) // Adjust the URL based on your server setup
    .build();

  connection
    .start()
    .catch((error) => console.error("SignalR Connection Error:", error));

  connection.on("ReceiveNotification", (receivedNotification) => {
    // sendNotificationToRedux(receivedNotification);
    const { organiationID, cutomerId, notificationType, referenceID } =
      receivedNotification;
    // alert("received Notification From Dashboard.js Admin" , receivedNotification)
    sendNotificationToRedux(receivedNotification);

    // Handle the notification data as needed in your React application
  });

  function sendNotificationToRedux(receivedNotification) {
    console.log("Sending Notification to Redux:", receivedNotification);
    setNotifications(receivedNotification);
    dispatch(addNotification(receivedNotification));
    console.log("After dispatch AddNotification Func"(receivedNotification));
  }

  return () => {
    if (connection.state === signalR.HubConnectionState.Connected) {
      connection.stop();
    }
  };
}, [dispatch]);

useEffect(() => {
  if (notifications != null && notification.notificationType === 20) {
    GotNewAlert(notifications);
  }
}, [notifications]);






  function LoadOrganization() {
     if (HasLoggedInUser()) {
       try {
         setLoading(true);
         const SetDataById = `${VixoAPI.AdminDashboardAPI}getAllOrganizationsData`;
         //setLoading(true);
         axios
           .get(SetDataById, {
             headers: APIHeader().headers,
           })
           .then((response) => {
             var response = response.data;
             if (response.statusCode === 200) {
               var Statdata = JSON.parse(JSON.stringify(response.data));
               var filteredData = Statdata?.filter(
                 (item) => !item.isSoftDeleted
               );

               if (filteredData != null) {
                 setOrganizationData(filteredData.reverse());
               }

               setOrganizationData(filteredData);
               toast.success(response.message);
             } else {
               //setError(response.message);
             }
           })
           .catch((error) => {
             //setLoading(false);
             throw error;
           })
           .finally(() => {
             setLoading(false);
           });
       } catch (error) {
         setLoading(false);
         throw error;
       }
     } else {
       navigate("/");
     }
  }
  useEffect(() => {
    LoadOrganization();
  }, []);

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const iconStyle = {
    marginRight: "8px", // Adjust the margin as needed
  };

  function LoadUsers(OrgnizationID) {
     if (HasLoggedInUser()) {
       try {
         setLoading(true);
         const SetDataById =
           VixoAPI.UserAPI + "getallbyOrg?OrgnizationID=" + OrgnizationID;
         //setLoading(true);
         axios
           .get(SetDataById, { headers: APIHeader().headers })
           .then((response) => {
             var response = response.data;
             if (response.statusCode === 200) {
               var Statdata = JSON.parse(JSON.stringify(response.data));
               setUserData(Statdata);
               toast(Statdata);
             } else {
             }
           })
           .catch((error) => {
             //setLoading(false);
             throw error;
           })
           .finally(() => {
             setLoading(false);
           });
       } catch (error) {
         setLoading(false);
         throw error;
       }
     } else {
       navigate("/");
     }
  }

  const userDataRef = useRef(null);

  const handleOrganizationClick = (organizationId) => {
    setOpenOrganizationId((prevId) =>
      prevId === organizationId ? null : organizationId
    );

    if (userDataRef.current) {
      userDataRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
    LoadUsers(organizationId);
  };

  const handleOrganizationIconClick = (organizationId) => {
    setOpenOrganizationId((prevId) =>
      prevId === organizationId ? null : organizationId
    );
    LoadUsers(organizationId);
  };
  const handleOrganizationisActive = (organizationId, newStatus) => {
    if (HasLoggedInUser()) {
      setLoading(true);
      const updateConfirmationStatusUrl =
        VixoAPI.AdminDashboardAPI + "disableOrganization";
      try {
        axios
          .post(updateConfirmationStatusUrl, {
            headers: APIHeader().headers,
            OrganizationID: organizationId,
            isOrganizationDisable: newStatus,
          })
          .then((response) => {
            if (response.data.statusCode === 200) {
              const updatedData = organizationData.map((org) =>
                org.id === organizationId
                  ? { ...org, isOrganizationDisable: newStatus }
                  : org
              );
              setOrganizationData(updatedData);
              toast(response.data.message);
            }
          })
          .catch((error) => {
            // Handle error
            console.error("Error updating confirmation status:", error);
          });
      } catch (apiError) {
        setLoading(false);
        // Handle API error
        console.error("API Error:", apiError);
      } finally {
        setLoading(false);
      }
    } else {
      navigate("/");
    }
  };

  function SupportCall(OrganiationID) {
    CreateSupportToken(OrganiationID, VixoAPI.DashBoard);
  }
  function DebugSupportCall(OrganiationID) {
    CreateSupportToken(OrganiationID, VixoAPI.LocalVixoDashboardDebug);
  }

  function CreateSupportToken(OrganizationID, BaseURL) {
    if (HasLoggedInUser()) {
      const OrganizationData = organizationData.find(
        (org) => org.id === OrganizationID
      );
      if (
        OrganizationData &&
        OrganizationData != null &&
        OrganizationData.email
      ) {
        var encodedemail = encodeURIComponent(OrganizationData.email);
        const SupportTokenPath =
          VixoAPI.AuthenticationAPI + "CreateAccessToken";

        const data = {
          UserName: OrganizationData.email,
          Password: null,
          AppType: 1,
        };
        setLoading(true);
        try {
          setLoading(true);
          axios
            .post(SupportTokenPath, data, { headers: APIHeader().headers })
            .then((response) => {
              if (response.data.statusCode === 200) {
                toast(response.data.message);
                const newData = response.data.data;
                //  LocalVixoDashboard
                const loginUrl = `${BaseURL}SupportToken?SupportToken=${encodeURIComponent(
                  newData
                )}`;
                window.open(loginUrl, "_blank");
              }
            })
            .catch((error) => {
              // Handle error
              console.error("Error updating confirmation status:", error);
            });
        } catch (apiError) {
          setLoading(false);
          // Handle API error
          console.error("API Error:", apiError);
        } finally {
          setLoading(false);
        }
      }
    } else {
      navigate("/");
    }
  }

  const handleUserIsActive = (UserId, newStatus) => {
    if (HasLoggedInUser()) {
      setLoading(true);
      const updateConfirmationStatusUrl = VixoAPI.UserAPI + "disableUser";

      try {
        axios
          .post(updateConfirmationStatusUrl, {
            headers: APIHeader().headers,
            uId: UserId,
            isUserDisable: newStatus,
          })
          .then((response) => {
            if (response.data.statusCode === 200) {
              const updatedData = UserData.map((user) =>
                user.id === UserId
                  ? { ...user, isUserDisable: newStatus }
                  : user
              );
              setUserData(updatedData);
              toast(response.data.message);
            }
          })
          .catch((error) => {
            // Handle error
            console.error("Error updating confirmation status:", error);
          });
      } catch (apiError) {
        // Handle API error
        console.error("API Error:", apiError);
      } finally {
        setLoading(false);
      }
    } else {
      navigate("/");
    }
  };
  //// Three Dots
useEffect(() => {
  const handleClickOutside = (event) => {
    if (buttonRef.current && !buttonRef.current.contains(event.target)) {
      // Click occurred outside of the dropdown, so close it
      setDropdownOpen(false);
    }
  };

  // Attach the event listener to the document
  document.addEventListener("mousedown", handleClickOutside);

  // Clean up the event listener on component unmount
  return () => {
    document.removeEventListener("mousedown", handleClickOutside);
  };
}, []); 

  

 

  const handleThreedotIconClick = (e, itemId) => {
    e.stopPropagation(); // Prevents the table row from closing the dropdown
    setSelectedItemId(itemId);
    setDropdownOpen(!dropdownOpen);
  };

  const [showGiveCredit, setShowGiveCredit] = useState(false);

  const handleCloseGiveCredit = () => setShowGiveCredit(false);

  // SoftDeleteOrganization
  function SoftDeleteOrganization(OrganizationID) {
    if (HasLoggedInUser()) {
      setLoading(true);
      try {
        setLoading(true);
        console.log("Deleting organization with ID:", OrganizationID);
        const response = axios
          .delete(`${VixoAPI.AdminDashboardAPI}SoftDeleteOrganizationbyId`, {
            headers: APIHeader().headers,
            params: {
              OrganizationID: OrganizationID,
            },
          })
          .then((response) => {
            response = response.data;
            if (response.statusCode === 200) {
              const updatedOrganizationData = organizationData.filter(
                (org) => org.id !== OrganizationID
              );

              toast.success(response.message);
              setOrganizationData(updatedOrganizationData);
            }
            setLoading(false);
          })
          .catch((error) => {
            toast.error("Error deleting organization", error);
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        throw error;
      }
    } else {
      navigate("/");
    }
  }

  // delete handleOTPDeleteOrganization
  const handleOTPActionStart = (organizationId, OTPAction) => {
    const organization = organizationData.find(
      (org) => org.id === organizationId || org.email === organizationId
    );
    if (organization) {
      ShowOTPPopup(OTPAction, organization.id, organization);
    }
  };

  function OTPVerified(IsOTPVerified, OrganizationID, Action) {
    if (IsOTPVerified && IsOTPVerified === true) {
      if (Action === DeleteOrganizationAction) {
        SoftDeleteOrganization(OrganizationID);
      } else if (Action === Support) {
        SupportCall(OrganizationID);
      } else if (Action === DebugSupport) {
        DebugSupportCall(OrganizationID);
      } else if (Action === GiveCredit) {
        setShowGiveCredit(true);
      }
      setShouldShowOTP(false);
    }
  }
  function ShowOTPPopup(Action, OranizationID, organization) {
    setSelectedOrganization(organization);
    setOTPOrganizationID(OranizationID);
    setOTPAction(Action);
    setShouldShowOTP(true);
    setStep(0);

    return true;
  }

  const handleFreeCreditChange = (e) => {
    const value = e.target.value;
    setFreeCredit(value);
  };

  const handleAddFreeCredit = (OrganizationID) => {
    if (HasLoggedInUser()) {
      const data = {
        OrganizationID: selectedOrganization.id,
        Credit: FreeCredit,
      };
      const FreeCreditpath = VixoAPI.AdminDashboardAPI + "addFreeCredit";

      setLoading(true);
      try {
        axios
          .post(FreeCreditpath, data, { headers: APIHeader().headers })
          .then((response) => {
            if (response.data.statusCode === 200) {
              toast.success(response.data.message);

              handleCloseGiveCredit();
              LoadOrganization();
            }
          })
          .catch((error) => {
            // Handle error
            console.error("Error updating confirmation status:", error);
          });
      } catch (apiError) {
        // Handle API error
        console.error("API Error:", apiError);
      } finally {
        setLoading(false);
      }
    } else {
      navigate("/");
    }
  };
// creat partner token 

 const createPartnerToken = async (username) => {
      if (HasLoggedInUser()) {
        const data = {
          UserName: username,
          appType: 4,
        };

        try {
          setLoading(true);
          const response = await axios.post(
            VixoAPI.AuthenticationAPI + "CreateAccessToken ",data, { headers: APIHeader().headers }
            
          );

          if (response.status === 200) {
            toast.success(response.data.message);
            LoadOrganization();
          }
        } catch (error) {
          setLoading(false);
          throw error;
        } finally {
          setLoading(false);
        }
      } else {
        navigate("/");
      }
 };


   const [tokenVisibility, setTokenVisibility] = useState({});

   const [copyToken, setcopyToken] = useState(false);
    
    
     const handleToggleToken = (itemId) => {
       setTokenVisibility((prevVisibility) => ({
         ...prevVisibility,
         [itemId]: !prevVisibility[itemId], // Toggle visibility for the itemId
       }));
     };

   

    const handleCopyToken = (accessToken) => {
      navigator.clipboard
        .writeText(accessToken)
        .then(() => {
           setcopyToken(true)
        setTimeout(() => {
          setcopyToken(false);
        }, 1000);
        })
        .catch((error) => {
          console.error("Unable to copy token to clipboard:", error);
          // You can show an error message to the user
        });
    };

 const renderAccessToken = (accessToken) => {
   if (!accessToken) {
     return ""; // Return an empty string if accessToken is null or undefined
   }

   const isVisible = tokenVisibility[accessToken];

   if (isVisible) {
     return accessToken; // Show full access token
   } else {
     const maskedToken =
       "*".repeat(accessToken.length - 4) + accessToken.slice(-4);
     return maskedToken; // Show masked token
   }
 };

 const [selectedOption, setSelectedOption] = useState("1");
  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };


  const handleNegativeBalance = (OrgId, newStatus) => {
     if (HasLoggedInUser()) {
       setLoading(true);
       const updateConfirmationStatusUrl =
         VixoAPI.AdminDashboardAPI + "updateOrgNegativeBalanceinAdm";
         const data ={
              OrganizationId: OrgId,
             IsNegativeBalance: newStatus,
         }
       try {
         axios
           .post(updateConfirmationStatusUrl,data, {
             headers: APIHeader().headers,
           })
           .then((response) => {
             if (response.data.statusCode === 200) {
               const updatedData = organizationData.map((org) =>
                 org.id === OrgId
                   ? { ...org, negativeBalanceAllow: newStatus }
                   : org
               );
               setOrganizationData(updatedData);
               toast(response.data.message);
             }
           })
           .catch((error) => {
             // Handle error
             console.error("Error updating confirmation status:", error);
           });
       } catch (apiError) {
         // Handle API error
         console.error("API Error:", apiError);
       } finally {
         setLoading(false);
       }
     } else {
       navigate("/");
     }
  };

  return (
    <>
      <ToastContainer />
      <div id="main-wrapper">
        {/* Enable And Disable Organization Modal Start */}
        <Modal
          show={showConfirmationModal}
          onHide={handleCloseConfirmationModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {confirmationAction === "disable" && (
              <p className="text-danger">
                {" "}
                Are you sure you want to Disable the organization?{" "}
                <FontAwesomeIcon icon={faLandMineOn} size="2x" />{" "}
              </p>
            )}
            {confirmationAction === "enable" && (
              <p className="text-success">
                {" "}
                Are you sure you want to Enable the organization?{" "}
                <FontAwesomeIcon icon={faLandMineOn} size="2x" />{" "}
              </p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseConfirmationModal}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleConfirmAction}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Enable And Disable Organization Modal End */}
        {/* Add Credit Modal Start */}
        <Modal show={showGiveCredit} onHide={handleCloseGiveCredit}>
          <Modal.Header closeButton>
            <Modal.Title>Giving Free Credit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Organization Name:{" "}
              {selectedOrganization ? selectedOrganization.name : ""}
            </p>
            <p>
              Organization Email:{" "}
              {selectedOrganization ? selectedOrganization.email : ""}
            </p>
            <div className="mb-3">
              <label htmlFor="Credit" className="form-label">
                Add Free Credit
              </label>
              <input
                type="number"
                className="form-control"
                id="FreeCredit"
                name="FreeCredit"
                onChange={(e) => {
                  handleFreeCreditChange(e);
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseGiveCredit}>
              Close
            </Button>
            <Button variant="primary" onClick={handleAddFreeCredit}>
              Give Free Credit
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Add Credit Modal End */}

        <AdminNavbar />
        <Sidebar />
        <ToastContainer />
        <Spinner show={loading} />
        <OTPVerification
          ShowOTPPopup={ShouldShowOTP}
          OTPClosed={OTPClosed}
          Action={OTPAction}
          OrganizationID={OTPOrganizationID}
          OTPVerified={OTPVerified}
          setStep={setStep}
          Step={Step}
        />
        <div className="content-body">
          <div className="container-fluid">
            <div className="row invoice-card-row  mt-4">
              <div style={{ textAlignLast: "center" }}>
                <img
                  id="logo"
                  src="image/3.png"
                  style={{ width: "300px" }}
                  alt="logo"
                />
              </div>
              <br></br>
              <div className="filter cm-content-box box-primary">
                <div className="content-title">
                  <div className="cpa  fs-24">
                    <h3 className="card-title fs-26">All Organization</h3>
                  </div>

                  {/* <div
                    className="row text-center"
                    style={{ marginLeft: "2rem" }}
                  >
                    <div className="text-end">
                      <select
                        className="dropdowntabmob h-80 rounded form-control"
                        value={selectedOption}
                        onChange={handleSelectChange}
                      >
                        <option value="1">Organizations</option>
                        <option value="2">Partners</option>
                      </select>
                    </div>
                  </div> */}

                  <div className="input-group search-area searchbar">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search here..."
                      value={searchQuery}
                      onChange={handleSearchInputChange}
                    />
                    <span className="input-group-text">
                      <a
                        href="javascript:void(0)"
                        style={{ textDecoration: "none" }}
                      >
                        <i className="flaticon-381-search-2"></i>
                      </a>
                    </span>
                  </div>
                </div>
                <div className="my-3 d-flex align-items-center justify-content-end">
                  <button
                    style={{ backgroundColor: " rgb(148, 97, 142)" }}
                    className="btn btn-primary col-md-auto"
                    onClick={() => {
                      handleAddModalOpen();
                    }}
                  >
                    Add Partner
                  </button>
                  {/* Pass showModal and handleModalClose as props to AddUser */}
                  <AddPartner
                    showModalPartner={showModalPartner}
                    handleModalClose={handleAddModalClose}
                  />
                </div>

                <div
                  className="cm-content-body form excerpt"
                  style={{ overflowY: "scroll", height: "470px" }}
                >
                  <div className="card-body">
                    <div className="">
                      <table className="table table-responsive-sm mb-0">
                        <thead>
                          <tr>
                            <th className="">Organization Name</th>
                            <th>Email</th>
                            <th>phone</th>
                            <th>Activity Date</th>

                            <th> Credit</th>
                            <th>Enabled</th>

                            <th>Email Confirmed</th>
                          </tr>
                        </thead>
                        <tbody>
                          {organizationData != null &&
                            organizationData
                              .filter((item) => {
                                const name = item.name
                                  ? item.name.toLowerCase()
                                  : "";
                                const email = item.email
                                  ? item.email.toLowerCase()
                                  : "";
                                const phonePrimary = item.phonePrimary
                                  ? item.phonePrimary.toLowerCase()
                                  : "";
                                const role = item.role
                                  ? item.role.toLowerCase()
                                  : "";

                                const isEnabled = item.isEnabled
                                  ? item.isEnabled.toString().toLowerCase()
                                  : "";

                                const Credit = item.availableCredit
                                  ? item.availableCredit
                                      .toString()
                                      .toLowerCase()
                                  : "";

                                return (
                                  name.includes(searchQuery.toLowerCase()) ||
                                  email.includes(searchQuery.toLowerCase()) ||
                                  phonePrimary.includes(
                                    searchQuery.toLowerCase()
                                  ) ||
                                  role.includes(searchQuery.toLowerCase()) ||
                                  Credit.includes(searchQuery.toLowerCase()) ||
                                  isEnabled.includes(searchQuery.toLowerCase())
                                );
                              })
                              .map((item, index) => (
                                <React.Fragment key={index}>
                                  <tr
                                    className={
                                      openOrganizationId === item.id
                                        ? "bg-secondary"
                                        : ""
                                    }
                                  >
                                    <td>
                                      <div className="d-flex align-items-center gap-2">
                                        <a
                                          href="#"
                                          onClick={() =>
                                            handleOrganizationClick(item.id)
                                          }
                                          className="text-decoration-none"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          title={item.id}
                                        >
                                          <b>
                                            {" "}
                                            <FontAwesomeIcon
                                              icon={faArrowDownShortWide}
                                              style={iconStyle}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleOrganizationIconClick(
                                                  item.id
                                                );
                                              }}
                                            />
                                            {item.name}
                                          </b>
                                        </a>
                                        <span className="">
                                          {item.isPartner === true ? (
                                            <FontAwesomeIcon
                                              icon={faHandshakeAngle}
                                            />
                                          ) : (
                                            <FontAwesomeIcon icon={faSitemap} />
                                          )}
                                        </span>
                                      </div>
                                    </td>
                                    <td>{item.email}</td>
                                    <td>{item.phonePrimary}</td>
                                    <td>
                                      {item.recentActivityDate ? (
                                        <Link
                                          to={`/recentactivities?id=${encodeURIComponent(
                                            item.id
                                          )}`}
                                          className="text-sm text-blue"
                                        >
                                          {moment(
                                            item.recentActivityDate
                                          ).format("YYYY-MM-DD HH:mm:ss")}
                                        </Link>
                                      ) : null}
                                    </td>
                                    <td className="text-center">
                                      {item.availableCredit}
                                    </td>
                                    <td style={{ display: "flex" }}>
                                      <input
                                        type="checkbox"
                                        checked={
                                          item.isOrganizationDisable == null ||
                                          item.isOrganizationDisable == false
                                        }
                                        onChange={() =>
                                          handleShowConfirmationModal(
                                            item.isOrganizationDisable
                                              ? "enable"
                                              : "disable",
                                            item.id
                                          )
                                        }
                                      />
                                      <Dropdown
                                        show={
                                          dropdownOpen &&
                                          selectedItemId === item.id
                                        }
                                        // ref={buttonRef}
                                      >
                                        <Dropdown.Toggle
                                          id={`dropdown-${index}`}
                                          style={{
                                            marginLeft: "10px",
                                            cursor: "pointer",
                                            backgroundColor: "black",
                                          }}
                                          onClick={(e) =>
                                            handleThreedotIconClick(e, item.id)
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon={faEllipsisVertical}
                                          />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu
                                          className="bg-light drop-manu"
                                          ref={buttonRef}
                                          S
                                        >
                                          <Dropdown.Item
                                            onClick={() =>
                                              handleOTPActionStart(
                                                item.id,
                                                GiveCredit
                                              )
                                            }
                                            className="text-dark"
                                          >
                                            Give Credit{" "}
                                            <img
                                              src="image/cashback.gif"
                                              alt="icon"
                                              style={{ width: "25px" }}
                                            />
                                          </Dropdown.Item>
                                          <hr></hr>
                                          <Dropdown.Item
                                            onClick={() =>
                                              handleOTPActionStart(
                                                item.email,
                                                Support
                                              )
                                            }
                                            className="text-dark"
                                          >
                                            Support{" "}
                                            <img
                                              src="image/support.gif"
                                              alt="icon"
                                              style={{ width: "25px" }}
                                            />
                                          </Dropdown.Item>
                                          <hr />
                                          <Dropdown.Item
                                            onClick={() =>
                                              handleOTPActionStart(
                                                item.id,
                                                DebugSupport
                                              )
                                            }
                                            className="text-dark"
                                          >
                                            Debug Support{" "}
                                            <img
                                              src="image/Debug Bug 2.png"
                                              alt="icon"
                                              style={{ width: "20px" }}
                                            />
                                          </Dropdown.Item>
                                          <hr />
                                          <Dropdown.Item
                                            onClick={() =>
                                              handleOTPActionStart(
                                                item.id,
                                                DeleteOrganizationAction
                                              )
                                            }
                                            className="text-dark"
                                          >
                                            Delete Organization
                                            <img
                                              src="image/bin.gif"
                                              alt="icon"
                                              style={{ width: "25px" }}
                                            />
                                          </Dropdown.Item>
                                          <hr />
                                          <Dropdown.Item>
                                            <button
                                              style={{
                                                backgroundColor:
                                                  "rgb(148, 97, 142)",
                                                fontSize: "12px",
                                                width: "100%",
                                              }}
                                              className="text-white px-2 py-2 rounded"
                                              onClick={() => {
                                                createPartnerToken(item.email);
                                              }}
                                            >
                                              Create Token
                                            </button>
                                          </Dropdown.Item>

                                          <Dropdown.Item>
                                            {item.accessToken !== null && (
                                              <div className="">
                                                {tokenVisibility[
                                                  item.accessToken
                                                ] ? (
                                                  <div className="d-flex align-items-center gap-2">
                                                    <div
                                                      className="cursor-pointer text-black"
                                                      onClick={() =>
                                                        handleToggleToken(
                                                          item.accessToken
                                                        )
                                                      }
                                                    >
                                                      <FontAwesomeIcon
                                                        icon={faEyeSlash}
                                                        fontSize={17}
                                                        color="black"
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title="Hide accessToken"
                                                      />
                                                    </div>
                                                    <div
                                                      className="cursor-pointer"
                                                      onClick={() =>
                                                        handleCopyToken(
                                                          item.accessToken
                                                        )
                                                      }
                                                    >
                                                      <FontAwesomeIcon
                                                        icon={faCopy}
                                                        fontSize={17}
                                                        color="black"
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title={
                                                          copyToken
                                                            ? "Copied to Clipboard"
                                                            : ""
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <div
                                                    className="cursor-pointer"
                                                    onClick={() =>
                                                      handleToggleToken(
                                                        item.accessToken
                                                      )
                                                    }
                                                  >
                                                    <FontAwesomeIcon
                                                      icon={faEye}
                                                      fontSize={17}
                                                      color="black"
                                                      data-toggle="tooltip"
                                                      data-placement="top"
                                                      title="Show accessToken"
                                                    />
                                                  </div>
                                                )}
                                                <span
                                                  className=""
                                                  style={{
                                                    whiteSpace: "pre-wrap",
                                                    wordBreak: "break-word",
                                                    display: "inline-block",
                                                    maxWidth:
                                                      "calc(50ch + 0.5rem)",
                                                    overflowWrap: "break-word",
                                                    color: "black",
                                                  }}
                                                >
                                                  {renderAccessToken(
                                                    item.accessToken
                                                  )}
                                                </span>
                                              </div>
                                            )}
                                          </Dropdown.Item>
                                          <hr />
                                          <Dropdown.Item
                                            className="text-dark"
                                            style={{
                                              display: "flex",
                                              gap: "8px",
                                            }}
                                          >
                                            Negative Balance
                                            <input
                                              type="checkbox"
                                              checked={
                                                item.negativeBalanceAllow ===
                                                true
                                              }
                                              onChange={() =>
                                                handleNegativeBalance(
                                                  item.id,
                                                  !item.negativeBalanceAllow
                                                )
                                              }
                                            />
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </td>

                                    <td className="text-center">
                                      {item.emailIsConfirmed !== null &&
                                      item.emailIsConfirmed !== "" ? (
                                        <FontAwesomeIcon
                                          icon={
                                            item.emailIsConfirmed
                                              ? faCircleCheck
                                              : faCircleXmark
                                          }
                                          style={{
                                            color: item.emailIsConfirmed
                                              ? "green"
                                              : "red",
                                            fontSize: "1.5em",
                                            backgroundColor: "white",
                                            borderRadius: "50%",
                                          }}
                                        />
                                      ) : null}
                                    </td>
                                    {/* <td className="text-center">
                                      {item.isPartner === true ? (
                                        <button
                                          style={{
                                            backgroundColor:
                                              " rgb(148, 97, 142)",
                                            fontSize: "12px",
                                          }}
                                          className="text-white px-2 py-2 rounded"
                                          onClick={() => {
                                            createPartnerToken(item.email);
                                          }}
                                        >
                                          Create Token
                                        </button>
                                      ) : null}
                                    </td>

                                    <td className="text-center">
                                      {item.isPartner === true &&
                                        item.accessToken !== null && (
                                          <div className="d-flex align-items-center gap-2">
                                            {tokenVisibility[
                                              item.accessToken
                                            ] ? (
                                              <>
                                                <div
                                                  className="cursor-pointer"
                                                  onClick={() =>
                                                    handleToggleToken(
                                                      item.accessToken
                                                    )
                                                  }
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faEyeSlash}
                                                    fontSize={17}
                                                    color="white"
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="Hide accessToken"
                                                  />
                                                </div>
                                                <div
                                                  className="cursor-pointer"
                                                  onClick={() =>
                                                    handleCopyToken(
                                                      item.accessToken
                                                    )
                                                  }
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faCopy}
                                                    fontSize={17}
                                                    color="white"
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title={
                                                      copyToken
                                                        ? "Copied to Clipboard"
                                                        : ""
                                                    }
                                                  />
                                                </div>
                                              </>
                                            ) : (
                                              <div
                                                className="cursor-pointer"
                                                onClick={() =>
                                                  handleToggleToken(
                                                    item.accessToken
                                                  )
                                                }
                                              >
                                                <FontAwesomeIcon
                                                  icon={faEye}
                                                  fontSize={17}
                                                  color="white"
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Show accessToken"
                                                />
                                              </div>
                                            )}
                                            <span>
                                              {renderAccessToken(
                                                item.accessToken
                                              )}
                                            </span>
                                          </div>
                                        )}
                                    </td> */}
                                  </tr>
                                </React.Fragment>
                              ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="filter cm-content-box box-primary"
                ref={userDataRef}
              >
                <div className="content-title">
                  <div className="cpa fs-24">
                    <h3 className="card-title fs-26">
                      Selected Org:{" "}
                      {openOrganizationId
                        ? organizationData.find(
                            (org) => org.id === openOrganizationId
                          )?.name || ""
                        : ""}{" "}
                      <br /> Users
                    </h3>
                  </div>
                  <div className="input-group search-area searchbar">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search here..."
                      value={searchQuery}
                      onChange={handleSearchInputChange}
                    />
                    <span className="input-group-text">
                      <a
                        href="javascript:void(0)"
                        style={{ textDecoration: "none" }}
                      >
                        <i className="flaticon-381-search-2"></i>
                      </a>
                    </span>
                  </div>
                </div>
                <div
                  className="cm-content-body form excerpt"
                  style={{ overflowY: "scroll", height: "470px" }}
                  ref={userDataRef}
                >
                  <div className="card-body">
                    {openOrganizationId ? (
                      <div className="table-responsive">
                        <table className="table table-responsive-sm mb-0">
                          <thead>
                            <tr>
                              <th className="">User Name</th>
                              <th>Email</th>
                              <th>phone</th>
                              <th>Role</th>
                              <th>Confirmation</th>
                            </tr>
                          </thead>
                          <tbody>
                            {UserData !== null &&
                              UserData.filter(
                                (user) =>
                                  user.organizationId === openOrganizationId
                              ).map((user, userIndex) => (
                                <tr key={userIndex}>
                                  <td>
                                    {" "}
                                    <FontAwesomeIcon
                                      icon={faUserGear}
                                      style={iconStyle}
                                    />
                                    {user.name}
                                  </td>
                                  <td>{user.email}</td>
                                  <td>{user.phone}</td>
                                  <td>{user.roleName}</td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      checked={
                                        user.isUserDisable == null ||
                                        user.isUserDisable == false
                                      }
                                      onChange={() =>
                                        handleUserIsActive(
                                          user.id,
                                          user.isUserDisable ? false : true
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <p className="text-danger text-md-center">
                        Please Select an Organization to View Users.
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="copyright">
            <p>
              Copyright © Designed &amp; Developed by{" "}
              <a href="#" target="_blank">
                Vixo
              </a>{" "}
              {currentYear}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllUser;
