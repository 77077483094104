import axios from "axios";
import { VixoAPI } from "../../settings";
export async function OpenBankingType() {
  try {
    const lookups = localStorage.getItem("OpenBankingType");
    if (lookups == null) {
      const APIPath = VixoAPI.LookupAPI + "getOpenBankingType";
      const response = await axios.get(APIPath);
      var apiResponse = response.data;
      if (apiResponse.statusCode == 200) {
        localStorage.setItem(
          "OpenBankingType",
          JSON.stringify(apiResponse.data)
        );
      }
    }
    const lookupsResponse = localStorage.getItem("OpenBankingType");
    return JSON.parse(lookupsResponse);
  } catch (error) {
    throw error;
  }
}


export async function VixoLinkOCRLookup(lookups) {
  try {
  
    if (lookups == null) {
      const APIPath = VixoAPI.LookupAPI + "getOcrTypes";
      const response = await axios.get(APIPath);
  
      var apiResponse = response.data;
      
      if (apiResponse.statusCode == 200) {
        localStorage.setItem("getOcrTypes", JSON.stringify(apiResponse.data));
      }
    }
    const lookupsResponse = localStorage.getItem("getOcrTypes");
    return JSON.parse(lookupsResponse);
  } catch (error) {
    throw error;
  }
}

