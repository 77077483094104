
const RoleLandingPages = [
  { 1: "/Dashboard" },
  { 2: "/AllOrganization" },
  { 4: "/billablecall" },
];

const AllowUIOnRoles = [
  { 1: ["*"] },
  {
    2: [
      "Dashboard",
      "All Organization",
      "Recent Activities",
      "Funding Request",
      "Exception Logs",
      "Relic",
      "Events Record",
      "User Management",
      "Open Bank",
      "Ocr",
      "Delete Organization",
    ],
  },
  { 4: ["Billabele Call", "Received Payment"] },
];

function getLandingPageByRoleId(roleId) {
  // Find the item based on the given role ID
  const foundItem = RoleLandingPages.find(
    (item) => Object.keys(item)[0] === roleId.toString()
  );

  // If the item is found, return the landing page associated with that role ID
  if (foundItem) {
    const roleId = Object.keys(foundItem)[0];
    return foundItem[roleId];
  } else {
    // If no item is found for the given role ID, return a default landing page or handle the case as appropriate
    return "/dashboard";
  }
}
function getComponentsByRoleId(roleId) {
  // Find the item based on the given role ID
  const foundItem = AllowUIOnRoles.find(
    (item) => Object.keys(item)[0] === roleId.toString()
  );

  // If the item is found, return the array of components associated with that role ID
  if (foundItem) {
    const roleId = Object.keys(foundItem)[0];
    return foundItem[roleId];
  } else {
    // If no item is found for the given role ID, return an empty array or handle the case as appropriate
    return [];
  }
}

export function IsComponentAllowToRole(ComponentName) {
  const loggedInUser = localStorage.getItem("User");
  var hasComponent = false;
  if (loggedInUser) {
    const userObject = JSON.parse(loggedInUser);

    if (userObject && userObject.role && AllowUIOnRoles) {
      const foundComponents = getComponentsByRoleId(userObject.role);

      if (foundComponents && foundComponents.length > 0) {
        return (
          foundComponents.includes(ComponentName) ||
          foundComponents.includes("*")
        );
      }
      return false;
    }
  }

  return false;
}
export function CheckAllowComponentAndRedirect(ComponentName, navigate) {
  const loggedInUser = localStorage.getItem("User");

  if (loggedInUser) {
    const userObject = JSON.parse(loggedInUser);
    var isComponentAllowed = IsComponentAllowToRole(ComponentName);
    if (!isComponentAllowed || isComponentAllowed == false) {
      var LandingPageForRole = getLandingPageByRoleId(userObject.role);
      navigate(LandingPageForRole);
    }
  }
}
