import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../Header/Sidebar";
import axios from "axios";
import { VixoAPI } from "../../settings";
import "../User/AllUser.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserGear,
  faArrowDownShortWide,
  faLandMineOn,
  faEllipsisVertical,
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Dropdown, DropdownButton } from "react-bootstrap";
import {useNavigate } from "react-router-dom";
import Spinner from "../../Spinner";
import { ToastContainer, toast } from "react-toastify";
import RightSidebar from "../Layout/RightSidebar";
import AdminNavbar from "../Navbar/AdminNavbar";
import { Modal, Button } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import moment from "moment";
// import { selectNotification } from "../Notifications/Slices/sendNotificationSlice";
import { id } from "date-fns/locale";
import OTPVerification from "../ReuseableComponents/OTPVerification";
import { CheckAllowComponentAndRedirect } from "../../Authentication";
import { APIHeader } from "../../APICallWrapper";
import { HasLoggedInUser } from "../../APICallWrapper";
const DeleteOrganizationby = () => {
  // const notification = useSelector(selectNotification);
  const [isHamburgerActive, setHamburgerActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const toggleHamburger = () => {
    setHamburgerActive(!isHamburgerActive);
    const menuToggle = document.getElementById("main-wrapper");
    if (isHamburgerActive) {
      menuToggle.classList.remove("menu-toggle", "show");
    } else {
      menuToggle.classList.add("menu-toggle", "show");
    }
  };
     const [PermanentlyDeleteOrganizationAction] = useState(
       "Permanently Delete Organization"
     );
      const [RestoreOrganization] = useState("Restore Organization");
  const [SoftDeletedOrganizationsData, setSoftDeletedOrganizationsData] =
    useState([]);
  const [UserData, setUserData] = useState(null);
  const [openOrganizationId, setOpenOrganizationId] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationAction, setConfirmationAction] = useState("");
  const [selectedOrganization, setSelectedOrganization] = useState(null);
const [Step, setStep] = useState(0);


  const [OTPAction, setOTPAction] = useState("");
  const [OTPOrganizationID, setOTPOrganizationID] = useState("");
  const [ShouldShowOTP, setShouldShowOTP] = useState(false);
 
 const currentYear = moment().format("YYYY");

  const buttonRef = useRef(null);

 
  const OTPClosed = () => setShouldShowOTP(false);
  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);
  const handleShowConfirmationModal = (action, orgId) => {
    setConfirmationAction(action);
    setOpenOrganizationId(orgId);
    setShowConfirmationModal(true);
  };

  const handleConfirmAction = () => {
    if (confirmationAction === "disable") {
      handleOrganizationisActive(openOrganizationId, true);
    } else if (confirmationAction === "enable") {
      handleOrganizationisActive(openOrganizationId, false);
    }

    // Close the modal after handling the action
    handleCloseConfirmationModal();
  };

  function AllSoftDeletedOrganizationsData() {
     if (HasLoggedInUser()) {
    try {
      setLoading(true);
      const setDataById = `${VixoAPI.AdminDashboardAPI}getAllSoftDeletedOrganizationsData`;
      //setLoading(true);
      axios
        .get(setDataById, { headers: APIHeader().headers })
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            var Statdata = JSON.parse(JSON.stringify(response.data));
            var filteredData = Statdata.filter(
              (organization) => organization.isSoftDeleted === true
            );
            setSoftDeletedOrganizationsData(filteredData.reverse());

            toast.success(response.message);
          } else {
            //setError(response.message);
          }
        })
        .catch((error) => {
          //setLoading(false);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      throw error;
    }
   } else{
    navigate("/")
  }
  }

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const iconStyle = {
    marginRight: "8px", // Adjust the margin as needed
  };

  const navigate = useNavigate();
  const ComponentName='Delete Organization';


  useEffect(() => {
    AllSoftDeletedOrganizationsData();
    CheckAllowComponentAndRedirect(ComponentName,navigate);
  }, []);

  

  function LoadUsers(OrgnizationID) {
     if (HasLoggedInUser()) {
    try {
      setLoading(true);
      const SetDataById =
        VixoAPI.UserAPI + "getallbyOrg?OrgnizationID=" + OrgnizationID;
      //setLoading(true);
      axios
        .get(SetDataById, {headers:APIHeader().headers})
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            var Statdata = JSON.parse(JSON.stringify(response.data));
            setUserData(Statdata);
            toast(Statdata);
          } else {
          }
        })
        .catch((error) => {
          //setLoading(false);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }else{
    navigate("/")
  }
  }

  const userDataRef = useRef(null);

  const handleOrganizationClick = (organizationId) => {
    setOpenOrganizationId((prevId) =>
      prevId === organizationId ? null : organizationId
    );

    if (userDataRef.current) {
      userDataRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
    LoadUsers(organizationId);
  };

  const handleOrganizationIconClick = (organizationId) => {
    setOpenOrganizationId((prevId) =>
      prevId === organizationId ? null : organizationId
    );
    LoadUsers(organizationId);
  };
  const handleOrganizationisActive = (organizationId, newStatus) => {
    if (HasLoggedInUser()) {
      setLoading(true);
      const updateConfirmationStatusUrl =
        VixoAPI.AdminDashboardAPI + "disableOrganization";
      try {
        axios
          .post(updateConfirmationStatusUrl, {
            headers: APIHeader().headers,
            OrganizationID: organizationId,
            isOrganizationDisable: newStatus,
          })
          .then((response) => {
            if (response.data.statusCode === 200) {
              const updatedData = SoftDeletedOrganizationsData.map((org) =>
                org.id === organizationId
                  ? { ...org, isOrganizationDisable: newStatus }
                  : org
              );
              setSoftDeletedOrganizationsData(updatedData);
              toast(response.data.message);
            }
          })
          .catch((error) => {
            // Handle error
            console.error("Error updating confirmation status:", error);
          });
      } catch (apiError) {
        setLoading(false);
        // Handle API error
        console.error("API Error:", apiError);
      } finally {
        setLoading(false);
      }
    } else {
      navigate("/");
    }
  };


  

 

  const handleThreedotIconClick = (e, itemId) => {
    e.stopPropagation(); // Prevents the table row from closing the dropdown
    setSelectedItemId(itemId);
    setDropdownOpen(!dropdownOpen);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
 
  
  const [FreeCredit, setFreeCredit] = useState(null);
  const handleFreeCreditChange = (e) => {
    const value = e.target.value;
    setFreeCredit(value);
  };

  const handleAddFreeCredit = (OrganizationID) => {
     if (HasLoggedInUser()) {
       const data = {
         OrganizationID: selectedOrganization.id,
         Credit: FreeCredit,
       };
       const FreeCreditpath = VixoAPI.AdminDashboardAPI + "addFreeCredit";

       setLoading(true);
       try {
         axios
           .post(FreeCreditpath, data, { headers: APIHeader().headers })
           .then((response) => {
             if (response.data.statusCode === 200) {
               toast.success(response.data.message);

               handleClose();
             }
           })
           .catch((error) => {
             // Handle error
             console.error("Error updating confirmation status:", error);
           });
       } catch (apiError) {
         // Handle API error
         console.error("API Error:", apiError);
       } finally {
         setLoading(false);
       }
     } else {
       navigate("/");
     }
  };

 

   useEffect(() => {
     const handleClickOutside = (event) => {
       if (buttonRef.current && !buttonRef.current.contains(event.target)) {
         // Click occurred outside of the dropdown, so close it
         setDropdownOpen(false);
       }
     };

     // Attach the event listener to the document
     document.addEventListener("mousedown", handleClickOutside);

     // Clean up the event listener on component unmount
     return () => {
       document.removeEventListener("mousedown", handleClickOutside);
     };
   }, []); 
  //
 
  
   function DeleteOrganization(OrganizationID) {
      if (HasLoggedInUser()) {
     setLoading(true);
     try {
       setLoading(true);
       console.log("Deleting organization with ID:", OrganizationID);
       const response = axios
         .delete(`${VixoAPI.AdminDashboardAPI}DeleteOrganizationbyId`, {
          headers:APIHeader().headers,
           params: {
             OrganizationID: OrganizationID,
           },
         })
         .then((response) => {
           response = response.data;
           if (response.statusCode === 200) {
             const updatedOrganizationData =
               SoftDeletedOrganizationsData.filter(
                 (org) => org.id !== OrganizationID
               );

             toast.success(response.message);
             setSoftDeletedOrganizationsData(updatedOrganizationData);
           }
           setLoading(false);
         })
         .catch((error) => {
           toast.error("Error deleting organization", error);
         })
         .finally(() => {
           setLoading(false);
         });
     } catch (error) {
       throw error;
     }
    }else{
      navigate("/")
    }
   }
 
  
  function RestoreSoftDeleteOrganization(organizationId) {
      if (HasLoggedInUser()) {
        setLoading(true);
        axios
          .post(
            `${VixoAPI.AdminDashboardAPI}RestoreSoftDeleteOrganizationbyId?OrganizationID=${organizationId}`,
            { headers: APIHeader().headers }
          )
          .then((response) => {
            if (response.status === 200) {
              const updatedOrganizationData =
                SoftDeletedOrganizationsData.filter(
                  (org) => org.id !== organizationId
                );

              toast.success(response.message);
              setSoftDeletedOrganizationsData(updatedOrganizationData);
            } else {
            }
          })
          .catch((error) => {})
          .finally(() => {
            setLoading(false);
          });
      } else {
        navigate("/");
      }
  }
  // delete handleOTPDeleteOrganization
  const handleOTPActionStart = (organizationId, OTPAction) => {
    const organization = SoftDeletedOrganizationsData.find(
      (org) => org.id === organizationId || org.email === organizationId
    );
    if (organization) {
      ShowOTPPopup(OTPAction, organization.id, organization);
    }
  };

  function OTPVerified(IsOTPVerified, OrganizationID, Action) {
    if (IsOTPVerified && IsOTPVerified == true) {
      if (Action === PermanentlyDeleteOrganizationAction) {
        DeleteOrganization(OrganizationID);
      } else if (Action === RestoreOrganization) {
        RestoreSoftDeleteOrganization(OrganizationID);
      }
      setShouldShowOTP(false);
    }
  }
  function ShowOTPPopup(Action, OranizationID, organization) {
    setSelectedOrganization(organization);
    setOTPOrganizationID(OranizationID);
    setOTPAction(Action);
    setShouldShowOTP(true);
  setStep(0);
    return true;
  }
  return (
    <>
      <ToastContainer />
      <div id="main-wrapper">
        {/* Enable And Disable Organization Modal Start */}
        <Modal
          show={showConfirmationModal}
          onHide={handleCloseConfirmationModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {confirmationAction === "disable" && (
              <p className="text-danger">
                {" "}
                Are you sure you want to Disable the organization?{" "}
                <FontAwesomeIcon icon={faLandMineOn} size="2x" />{" "}
              </p>
            )}
            {confirmationAction === "enable" && (
              <p className="text-success">
                {" "}
                Are you sure you want to Enable the organization?{" "}
                <FontAwesomeIcon icon={faLandMineOn} size="2x" />{" "}
              </p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseConfirmationModal}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleConfirmAction}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Enable And Disable Organization Modal End */}

        {/* Add Credit Modal Start */}

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Giving Free Credit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Organization Name:{" "}
              {selectedOrganization ? selectedOrganization.name : ""}
            </p>
            <p>
              Organization Email:{" "}
              {selectedOrganization ? selectedOrganization.email : ""}
            </p>
            <div className="mb-3">
              <label htmlFor="Credit" className="form-label">
                Add Free Credit
              </label>
              <input
                type="number"
                className="form-control"
                id="FreeCredit"
                name="FreeCredit"
                onChange={(e) => {
                  handleFreeCreditChange(e);
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleAddFreeCredit}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Add Credit Modal End */}

        <AdminNavbar />
        <Sidebar />
        <ToastContainer />

        <Spinner show={loading} />
        <OTPVerification
          ShowOTPPopup={ShouldShowOTP}
          OTPClosed={OTPClosed}
          Action={OTPAction}
          OrganizationID={OTPOrganizationID}
          OTPVerified={OTPVerified}
          setStep={setStep}
          Step={Step}
        />
        <div className="content-body">
          <div className="container-fluid">
            <div className="row invoice-card-row  mt-4">
              <div style={{ textAlignLast: "center" }}>
                <img id="logo" src="image/3.png" style={{ width: "300px" }} />
              </div>
              <br></br>
              <div className="filter cm-content-box box-primary">
                <div className="content-title">
                  <div className="cpa  fs-24">
                    <h3 className="card-title fs-26">Deleted Organization</h3>
                  </div>
                  <div className="input-group search-area searchbar">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search here..."
                      value={searchQuery}
                      onChange={handleSearchInputChange}
                    />
                    <span className="input-group-text">
                      <a
                        href="javascript:void(0)"
                        style={{ textDecoration: "none" }}
                      >
                        <i className="flaticon-381-search-2"></i>
                      </a>
                    </span>
                  </div>
                </div>
                <div
                  className="cm-content-body form excerpt"
                  style={{ overflowY: "scroll", height: "470px" }}
                >
                  <div className="card-body">
                    <div className="">
                      <table className="table table-responsive-sm mb-0">
                        <thead>
                          <tr>
                            <th className="">Organization Name</th>
                            <th>Email</th>
                            <th>phone</th>

                            <th>Available Credit</th>
                            <th>Enabled</th>
                            <th>Email Confirmed</th>
                          </tr>
                        </thead>
                        <tbody>
                          {SoftDeletedOrganizationsData != null &&
                            SoftDeletedOrganizationsData.filter((item) => {
                              const name = item.name
                                ? item.name.toLowerCase()
                                : "";
                              const email = item.email
                                ? item.email.toLowerCase()
                                : "";
                              const phonePrimary = item.phonePrimary
                                ? item.phonePrimary.toLowerCase()
                                : "";
                              const role = item.role
                                ? item.role.toLowerCase()
                                : "";
                              const isEnabled = item.isEnabled
                                ? item.isEnabled.toString().toLowerCase()
                                : "";

                              return (
                                name.includes(searchQuery.toLowerCase()) ||
                                email.includes(searchQuery.toLowerCase()) ||
                                phonePrimary.includes(
                                  searchQuery.toLowerCase()
                                ) ||
                                role.includes(searchQuery.toLowerCase()) ||
                                isEnabled.includes(searchQuery.toLowerCase())
                              );
                            }).map((item, index) => (
                              <React.Fragment key={index}>
                                <tr
                                  className={
                                    openOrganizationId === item.id
                                      ? "bg-secondary"
                                      : ""
                                  }
                                >
                                  <td>
                                    <a
                                      href="#"
                                      onClick={() =>
                                        handleOrganizationClick(item.id)
                                      }
                                      className="text-decoration-none"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="top"
                                      title={item.id}
                                    >
                                      <b>
                                        {" "}
                                        <FontAwesomeIcon
                                          icon={faArrowDownShortWide}
                                          style={iconStyle}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleOrganizationIconClick(
                                              item.id
                                            );
                                          }}
                                        />
                                        {item.name}
                                      </b>
                                    </a>
                                  </td>
                                  <td>{item.email}</td>
                                  <td>{item.phonePrimary}</td>

                                  <td className="text-center">
                                    {item.availableCredit}
                                  </td>
                                  <td style={{ display: "flex" }}>
                                    <input
                                      type="checkbox"
                                      checked={
                                        item.isOrganizationDisable == null ||
                                        item.isOrganizationDisable == false
                                      }
                                      onChange={() =>
                                        handleShowConfirmationModal(
                                          item.isOrganizationDisable
                                            ? "enable"
                                            : "disable",
                                          item.id
                                        )
                                      }
                                    />
                                    <Dropdown
                                      show={
                                        dropdownOpen &&
                                        selectedItemId === item.id
                                      }
                                      
                                    >
                                      <Dropdown.Toggle
                                        id={`dropdown-${index}`}
                                        style={{
                                          marginLeft: "10px",
                                          cursor: "pointer",
                                          backgroundColor: "black",
                                        }}
                                        // ref={buttonRef}
                                        onClick={(e) =>
                                          handleThreedotIconClick(e, item.id)
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon={faEllipsisVertical}
                                        />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu className="bg-light" ref={buttonRef}>
                                        <Dropdown.Item
                                          onClick={() =>
                                            handleOTPActionStart(
                                              item.id,
                                              RestoreOrganization
                                            )
                                          }
                                          className="text-dark"
                                        >
                                          Restore Organization
                                          <img
                                            src="image/paper_4000851.png"
                                            alt="icon"
                                            style={{ width: "25px" }}
                                          />
                                        </Dropdown.Item>
                                        <hr></hr>
                                        <Dropdown.Item
                                          onClick={() =>
                                            handleOTPActionStart(
                                              item.id,
                                              PermanentlyDeleteOrganizationAction
                                            )
                                          }
                                          className="text-dark"
                                        >
                                          Permanently Delete
                                          <img
                                            src="image/bin.gif"
                                            alt="icon"
                                            style={{ width: "25px" }}
                                          />
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                    {/* {dropdownOpen && (
                                      <div
                                        style={{
                                          position: "fixed",
                                          top: 0,
                                          left: 0,
                                          width: "100%",
                                          height: "100%",
                                          zIndex: 999,
                                        }}
                                        onClick={handleclose}
                                      />
                                    )} */}
                                  </td>

                                  <td className="text-center">
                                    {item.emailIsConfirmed !== null &&
                                    item.emailIsConfirmed !== "" ? (
                                      <FontAwesomeIcon
                                        icon={
                                          item.emailIsConfirmed
                                            ? faCircleCheck
                                            : faCircleXmark
                                        }
                                        style={{
                                          color: item.emailIsConfirmed
                                            ? "green"
                                            : "red",
                                          fontSize: "1.5em",
                                          backgroundColor: "white",
                                          borderRadius: "50%",
                                        }}
                                      />
                                    ) : null}
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="copyright">
            <p>
              Copyright © Designed &amp; Developed by{" "}
              <a href="#" target="_blank">
                Vixo
              </a>{" "}
              {currentYear}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteOrganizationby;
