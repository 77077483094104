import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { VixoAPI } from "../../settings";
import Spinner from "../../Spinner";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserLock } from "@fortawesome/free-solid-svg-icons";
import { text } from "@fortawesome/fontawesome-svg-core";
import Signin from "./Signin";

export default function MutliFactor() {
  const [errorType, setErrorType] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  // Define success message style
  const successMessageStyle = {
    backgroundColor: "green", // Customize the background color as needed
    color: "white", // Customize the text color as needed
    padding: "10px", // Add padding for better visibility
    marginBottom: "10px", // Add margin to separate it from other content
  };
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({
    Otp: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [OTP, setVerifyOTP] = useState(false);
  const [UserId, setUserId] = useState(null);
  const [reloadDiv, setReloadDiv] = useState(false);
  
  const [passwordErrors, setPasswordErrors] = useState({
    length: "",
    digit: "",
    uppercase: "",
    lowercase: "",
    special: "",
  });
  const navigate = useNavigate();
  const handleUserInfoChange = (e) => {
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const validatePassword = (password) => {
    const errors = {
      length: "",
      digit: "",
    };
    setPasswordErrors(errors);
    return Object.values(errors).every((error) => error === "");
  };

  function setData(message) {
    toast.success(message);
    setErrorType(null);
  }

  function setError(message) {
    setErrorType(message);
  }

  useEffect(() => {
    LoadData();
  }, []);

  const LoadData = () => {
    const loggedInUser = localStorage.getItem("User");
    var userObject = JSON.parse(loggedInUser);
    if (userObject != null) {
      setUserId(userObject);
    } else {
      localStorage.clear();
      navigate("/");
    }
  };

  if (authenticated) {
    navigate("/Activity");
  }
  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    const data = {
      uid: UserId.id,
      otp: userInfo.Otp,
    };

    try {
      // var encodedPassword = userInfo.Otp;

      const AdminDashboardPath = VixoAPI.AdminDashboardAPI + "verifyOTP";
      setLoading(true);

      axios
        .post(AdminDashboardPath, data)
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            setData(response.data);
            toast.success(response.message);
            navigate("/Dashboard");
          } else {
            toast.error(response.message);
            navigate("/MutliFactor");
          }
        })
        .catch((error) => {
          setLoading(false);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      throw error;
    }
  };
  const [countdown, setCountdown] = useState(0);
  const [disabled, setDisabled] = useState(false);

  const handleResendOTP = async (e) => {
    e.preventDefault();

    if (disabled) {
      return;
    }
    startCountdown();
    try {
      const AdminDashboardPath =
        VixoAPI.AdminDashboardAPI +
        "createOTP?email=" +
        encodeURIComponent(UserId.email);
      setLoading(true);
      setDisabled(true);
      setReloadDiv(true);
      axios
        .get(AdminDashboardPath)
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            setData(response.data);
            toast.success(response.message);
            navigate("/MutliFactor");
          } else {
            toast.error(response.message);
            navigate("/MutliFactor");
          }
        })
        .catch((error) => {
          setLoading(false);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      throw error;
    }
  };

  const startCountdown = () => {
    setCountdown(60);

    const intervalId = setInterval(() => {
      setCountdown((prevCountdown) => {
        const newCountdown = prevCountdown - 1;

        if (newCountdown >= 0) {
          return newCountdown;
        } else {
          clearInterval(intervalId);
          setDisabled(false);
          setReloadDiv(true);
          return 0;
        }
      });
    }, 1000);

    setTimeout(() => {
      clearInterval(intervalId);
      setDisabled(false);
      setReloadDiv(true);
      setCountdown(0); // Reset countdown after it reaches zero
    }, 60000);
  };
  useEffect(() => {
    if (countdown > 0) {
      setReloadDiv(true);
      const intervalId = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [countdown]);
  return (
    <>
      <Spinner show={loading} />
      {successMessage && (
        <div
          className="alert alert-success"
          style={successMessageStyle}
          role="alert"
        >
          {successMessage}
        </div>
      )}
      <main className="authentication-content">
        <div
          className="container align-items-center justify-content-center vh-100 d-flex"
          style={{ overflow: "hidden", width: "35pc" }}
        >
          <div className="authentication-card">
            <div className="card shadow rounded-0 overflow-hidden">
              <div className="row g-0">
                <div className="col-lg-12">
                  <div className="card-body p-4 p-sm-5">
                    <form className="form-body">
                      <span className="m-5">
                        {" "}
                        <img
                          src="image/3.png"
                          className="logo-icon"
                          alt="logo icon"
                          style={{ width: "90%" }}
                        />
                      </span>
                      <h2 className="fw-bold">Time-Base-One Time Password</h2>
                      <br />

                      {/* Password input */}
                      <label className="fw-bold mb-2 mt-2">
                        Enter Six Digits Code
                      </label>

                      <div className="input-group">
                        <span className="input-group-text">
                        <img src="image/OTP.gif" style={{width:"42px"}} />
                        </span>

                        <input
                          type={showPassword ? "text" : "password"}
                          name="Otp"
                          className={`form-control ${
                            passwordsMatch === false ? "is-invalid" : ""
                          }`}
                          placeholder="Enter OTP"
                          onChange={(e) => {
                            handleUserInfoChange(e);

                            // Validate password on input change
                            validatePassword(e.target.value);
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              handleVerifyOtp(e);
                            }
                          }}
                        />
                        <span
                          className="input-group-text"
                          style={{ cursor: "pointer" }}
                          onClick={() => toggleShowPassword()}
                        >
                          {showPassword ? (
                            <span role="img" aria-label="Visible">
                              👁️
                            </span>
                          ) : (
                            <span role="img" aria-label="Hidden">
                              🙈
                            </span>
                          )}
                        </span>
                      </div>
                      {passwordErrors.length && (
                        <div className="text-danger">
                          {passwordErrors.length}
                        </div>
                      )}
                      {passwordErrors.digit && (
                        <div className="text-danger">
                          {passwordErrors.digit}
                        </div>
                      )}
                      {passwordErrors.uppercase && (
                        <div className="text-danger">
                          {passwordErrors.uppercase}
                        </div>
                      )}
                      {passwordErrors.lowercase && (
                        <div className="text-danger">
                          {passwordErrors.lowercase}
                        </div>
                      )}
                      {passwordErrors.special && (
                        <div className="text-danger">
                          {passwordErrors.special}
                        </div>
                      )}
                      <br />
                      <div className="text-danger">
                        An OTP has been sent to your entered email. Please enter
                        the OTP to proceed
                      </div>
                      {/* Submit button */}
                      <div
                        style={{ textAlign: "end" }}
                        className={reloadDiv ? "reloaded" : ""}
                      >
                        <button
                          onClick={handleResendOTP}
                          disabled={loading || disabled}
                          className="btn btn-primary"
                        >
                          {disabled
                            ? `Resend OTP (${countdown}s)`
                            : "Resend OTP"}
                        </button>
                        {countdown > 0 && (
                          <p className="text-danger">
                            Resend available in {countdown} seconds
                          </p>
                        )}
                      </div>
                    
                      <button
                        className="btn btn-primary form-control"
                        onClick={handleVerifyOtp}
                        disabled={loading}
                      >
                        Verify
                      </button>
                    </form>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </main>
    </>
  );
}
