import React, { useState, useEffect } from "react";
import HtmlEditor from "../HtmlEditor/HtmlEditor";
import AdminNavbar from "../Navbar/AdminNavbar";
import Sidebar from "../Header/Sidebar";
import axios from "axios";
import { ToastContainer,toast } from "react-toastify";
import { VixoAPI } from "../../settings";
import { APIHeader } from "../../APICallWrapper";
import { HasLoggedInUser } from "../../APICallWrapper";
import { useNavigate } from "react-router-dom";
import { EmailEventType } from "../../Lookup";
import "react-toastify/dist/ReactToastify.css";
const CreateEmail = () => {
  const [subject, setSubject] = useState("");
  const [sms, setSms] = useState("");
  const [detail, setDetail] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [loading, setLoading] = useState(false);
   const navigate = useNavigate();
   const [selectedOption, setSelectedOption] = useState("");
 const [EmailEventTypeLookup, setEmailEventTypeLookup] = useState([]);


  //  lookup
   useEffect(() => {
   
     LoadLookups();
    
   }, []);
   async function LoadLookups() {
     try {
       EmailEventType()
         .then((result) => {
           setEmailEventTypeLookup(result);
            if (result.length > 0) {
              setSelectedOption(result[0].id);
            }
         })
         .catch((error) => {
           console.error("Error fetching data:", error);
         });
     } catch (ex) {
       console.error(ex + "EmailEventType Lookup Call is not successfull");
     }
   }
   const handleSelectChange = (e) => {
     setSelectedOption(e.target.value);
   };
  const handleToggle = () => {
    setIsActive(!isActive);
  };

  const handleCreateEmail = () => {
    setLoading(true);
    const data = {
      id: 0,
      Subject: subject,
      Sms: sms,
      Detail: detail,
      IsActive: isActive,
      eventType: parseInt(selectedOption),
      emailEventTypeName:
        EmailEventTypeLookup.find(
          (item) => item.id === parseInt(selectedOption)
        )?.displayValue || "string",
    };

    const OrgAPIPath = `${VixoAPI.AdminDashboardAPI}createEmailAdm`;
 if (HasLoggedInUser()) {
   axios
     .post(OrgAPIPath, data, {
       headers: APIHeader().headers,
     })
     .then((response) => {
       const responseData = response.data;
       if (responseData.statusCode === 200) {
         toast.success(responseData.message);
       } else {
         toast.error(responseData.message);
       }
     })
     .catch((error) => {
       toast.error(error.message);
     })
     .finally(() => {
       setLoading(false);
     });
 } else {
   navigate("/");
 }
  };

  return (
    <>
      <AdminNavbar />
      <ToastContainer />

      <Sidebar />

      <div className="content-body">
        <div className="container-fluid">
          <div className="row invoice-card-row mt-4">
            <div style={{ textAlignLast: "center" }}>
              <img id="logo" src="image/3.png" style={{ width: "300px" }} />
            </div>
            <br></br>
            <div className="filter cm-content-box box-primary">
              <div className="content-title" style={{ padding: "5px" }}>
                <div className="cpa  fs-24">
                  <h3 className="card-title fs-26">Create Emails</h3>
                </div>
              </div>
            </div>
            <div className="filter cm-content-box box-primary">
              <div
                className="form excerpt"
                style={{ overflowY: "scroll", height: "470px" }}
              >
                <div className="card-body">
                  <div className="form-group">
                    <label>Subject</label>
                    <input
                      type="text"
                      className="form-control"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>SMS</label>
                    <input
                      type="text"
                      className="form-control"
                      value={sms}
                      onChange={(e) => setSms(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>EmailEventType</label>
                    <select
                      className="dropdowntabmob h-80 rounded form-control"
                      value={selectedOption}
                      onChange={handleSelectChange}
                    >
                      {EmailEventTypeLookup != null &&
                        EmailEventTypeLookup.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.displayValue}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Details</label>
                    <HtmlEditor data={detail} onDataChange={setDetail} />
                  </div>
                  <div className="form-group my-2">
                    <div className="toggle-btn">
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={isActive}
                          onClick={handleToggle}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>

                  <button
                    type="button"
                    className="btn updateBtn btn-info custom-button"
                    onClick={handleCreateEmail}
                    style={{
                      borderRadius: "20px",
                      fontWeight: "500",
                      color: "white",
                      marginBlock: "10px",
                    }}
                    disabled={loading}
                  >
                    {loading ? "Creating..." : "Create Email"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateEmail;
