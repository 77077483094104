import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function APIHeader() {
 
  let config = {};

 
    const loggedInUser = localStorage.getItem("User");
    if (loggedInUser) {
      try {
        const userObject = JSON.parse(loggedInUser);
        if (userObject && userObject.authToken) {
          const accessToken = userObject.authToken; // Ensure this is the correct property name
          config = {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          };
        } else {
        //  navigate("/Signin");
        }
      } catch (error) {
        console.error("Error parsing user data: ", error);
      //  navigate("/Signin");
      }
    } else {
      //navigate("/Signin");
    }
 

  return config;
}

export function APIHeaderForLookup()
{
  const loggedInUser = localStorage.getItem("User");
  var userObject = JSON.parse(loggedInUser);
  if(userObject && userObject!=null && userObject.authToken!=null)
  {
    var accesstoken=  userObject.authToken;
    const config = {
        headers: {
        Authorization: `Bearer ${accesstoken}`
        }
    };
    return config;
}
}

export function HasLoggedInUser()
{
  const loggedInUser = localStorage.getItem("User");
  var userObject = JSON.parse(loggedInUser);
  if(userObject && userObject!=null && userObject.authToken!=null)
  {
    return true;
  }
  return false;
}

export function ExpireToken(navigate) {
  const User = localStorage.getItem("User");
  var userObject = JSON.parse(User);
  if (userObject && userObject != null) {
    var RedirectURL = '/';
    localStorage.removeItem("AuthUser");
    navigate(RedirectURL);
  } else {
    navigate("/");
  }
}