import { VixoAPI } from './settings';
import axios from 'axios';
import { APIHeader } from './APICallWrapper';
import { HasLoggedInUser } from './APICallWrapper';
import { useNavigate } from "react-router-dom";
  
export async function PaymentStatusType()
 
{
   

  try {
    const lookups = localStorage.getItem("PaymentStatusType");
    if (lookups == null) {
      const APIPath = VixoAPI.LookupAPI + "getVixoPaymentStatusTypes";
      const response = await axios.get(APIPath, {
        headers: APIHeader().headers,
      });
      var apiResponse = response.data;
      if (apiResponse.statusCode == 200) {
        localStorage.setItem(
          "PaymentStatusType",
          JSON.stringify(apiResponse.data)
        );
      }
    }
    const lookupsResponse = localStorage.getItem("PaymentStatusType");
    return JSON.parse(lookupsResponse);
  } catch (error) {
    throw error;
  }
   
}
export async function AdmRoleTypes()
{
   

  try {
    const lookups = localStorage.getItem("AdminUserRoleTypes");
    if (lookups == null) {
      const APIPath = VixoAPI.LookupAPI + "getAdmRoleTypes";
      const response = await axios.get(APIPath, {
        headers: APIHeader().headers,
      });
      var apiResponse = response.data;
      if (apiResponse.statusCode == 200) {
        localStorage.setItem(
          "AdminUserRoleTypes",
          JSON.stringify(apiResponse.data)
        );
        // return JSON.parse(JSON.stringify(apiResponse.data));
      }
    }
    const lookupsResponse = localStorage.getItem("AdminUserRoleTypes");
    return JSON.parse(lookupsResponse);
  } catch (error) {
    throw error;
  }

          
}


// lokup for email event type 

export async function EmailEventType() {
   
  
     try {
       const lookups = localStorage.getItem("EmailEventType");
       if (lookups == null) {
         const APIPath = VixoAPI.LookupAPI + "getEmailEventTypes";
         const response = await axios.get(APIPath, {
           headers: APIHeader().headers,
         });
         var apiResponse = response.data;
         if (apiResponse.statusCode == 200) {
           localStorage.setItem(
             "EmailEventType",
             JSON.stringify(apiResponse.data)
           );
           // return JSON.parse(JSON.stringify(apiResponse.data));
         }
       }
       const lookupsResponse = localStorage.getItem("EmailEventType");
       return JSON.parse(lookupsResponse);
     } catch (error) {
       throw error;
     }
   
}