import React from "react";
import Spinner from "../../../Spinner";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import InputMask from "react-input-mask";
import { VixoAPI } from "../../../settings";
import axios from "axios";
import { ValidateEmailGlobal, ValidatePhoneGlobal } from "../../Validation";
import { toast } from "react-toastify";
import { AdmRoleTypes } from "../../.././Lookup";
import { APIHeader } from "../../../APICallWrapper";
import { HasLoggedInUser } from "../../../APICallWrapper";
const AddADminUser = ({ showModal, handleModalClose }) => {
  const [authenticated, setauthenticated] = useState(null);
  const [User, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorType, setErrorType] = useState(null);
const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [phoneIsValid, setPhoneIsValid] = useState(true);
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [emailError, setEmailError] = useState("");
  const [roleError, setRoleError] = useState("");
  const [AdmRoles, setAdmRoles] = useState([]);

  //   useEffect(() => {
  //     const loggedInUser = localStorage.getItem("User");
  //     if (loggedInUser) {
  //       try {
  //         setUser(JSON.parse(loggedInUser));
  //         setauthenticated(true);
  //         LoadLookups();

  //       } catch (exception) {
  //         setauthenticated(false);
  //       }
  //     }
  //   }, []);

  //  function LoadLookups()
  //  {
  //   try{
  //    var userRolesLookup=UserRoleTypes();
  //    setUserRoles(userRolesLookup);
  //   }catch(ex)
  //   {

  //     console.error(ex+" User Role Lookup Call is not successfull");
  //   }

  //  }

  useEffect(() => {
    LoadLookups();
  }, []);
  async function LoadLookups() {
    try {
      AdmRoleTypes()
        .then((result) => {
          setAdmRoles(result);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (ex) {
      console.error(ex + " setAdmRoles Role Lookup Call is not successfull");
    }
  }
  const [currentUser, setCurrentUser] = useState({
    id: 0,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    role: 0,
  });
  const [sendInvitation, setSendInvitation] = useState(false);

  function clearData() {
    setCurrentUser({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      role: 0,
    });
  }
  function setData(data) {
    handleModalClose(data);
  }

  function setError(message) {
    setErrorType(message);
  }
  const isButtonDisabled =
    currentUser.role === "0" || // Check if the role is set to "0"
    !currentUser.firstName || // Check if the first name is empty
    !currentUser.lastName || // Check if the last name is empty
    !currentUser.email || // Check if the email is empty
    !currentUser.phone || // Check if the phone is empty
    !phoneIsValid || // Check if the phone is valid (based on validation logic)
    !emailIsValid; // Check if the email is valid (based on validation logic)

  const clearForm = () => {
    setCurrentUser({
      id: 0,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      role: 0,
    });
    setPhoneIsValid(true);
    setEmailIsValid(true);
    setEmailError("");
    setRoleError("");
    setErrorType("");
  };

  const validatePhone = (phone) => {
    const isValid = ValidatePhoneGlobal(phone);
    return isValid;
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    setPhone(value);
    setPhoneIsValid(validatePhone(value));
  };
  const validateRole = (role) => {
    if (role === "0") {
      setRoleError("Role is required");
      return false;
    } else {
      setRoleError("");
      return true;
    }
  };
  const handleAddSubmit = async (e) => {
    if (HasLoggedInUser()) {
      e.preventDefault();
      const isRoleValid = validateRole(currentUser.role);

      if (!phoneIsValid || !emailIsValid || !isRoleValid) {
        return;
      }

      const data = {
        Id: 0,
        organizationId: 21,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        email: currentUser.email,
        phone: currentUser.phone,
        userRole: currentUser.role, //currentUser.role,

        isActive: true,
        phoneIsConfirmed: true,
        emailIsConfirmed: true,
        password: "",
      };

      try {
        const OrganizationInvitePath = VixoAPI.AdminDashboardAPI + "createUser";
        setLoading(true);

        axios
          .post(OrganizationInvitePath, data, {
            headers: APIHeader().headers,
          })
          .then((response) => {
            var response = response.data;
            if (response.statusCode === 200) {
              setData(response.data);
              toast.success(response.message);
              clearData();
            } else {
              setError(response.message);
            }
          })
          .catch((error) => {
            setLoading(false);
            throw error;
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        throw error;
      }
    } else {
      navigate("/");
    }
  };
  function clearData() {
    setCurrentUser({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      role: 0,
    });
  }
  function setData(data) {
    handleModalClose(data);
  }
  const validateEmail = (email) => {
    const isValid = ValidateEmailGlobal(email);

    if (!isValid) {
      setEmailError("Enter a valid email address");
    } else {
      setEmailError("");
    }

    return isValid;
  };
  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setEmailIsValid(validateEmail(value));
  };
  return (
    <>
      <Spinner show={loading} />
      <Modal
        show={showModal}
        onHide={() => {
          // Add onHide handler
          clearForm(); // Clear the form when the modal is hidden
          handleModalClose();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title className="mx-auto">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center">
                  <span className="m-0">
                    {" "}
                    <img
                      src="image/3.png"
                      className="logo-icon  d-block mx-auto"
                      alt="logo icon"
                      style={{
                        width: "150px",
                        height: "70px",
                        display: "flex",
                        justifyContent: "center",
                        alignItemstems: "center",
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <div className="modal-body">
              <h5 className="text-center">Add User</h5>
              <div className="mb-3">
                <label htmlFor="role" className="form-label">
                  Role
                </label>
                <select
                  className="form-select"
                  id="role"
                  name="role"
                  value={currentUser.role}
                  onChange={(e) => {
                    setCurrentUser({
                      ...currentUser,
                      role: e.target.value,
                    });
                    validateRole(e.target.value);
                  }}
                >
                   <option value={0}>Select Role</option>
                  <option value={1}>Admin</option>
                  <option value={2}>Tech Support</option>
                  <option value={3}>Sales Support</option>
                  <option value={4}>Investor</option>
                </select>
                {roleError && (
                  <div className="invalid-feedback">{roleError}</div>
                )}
              </div>
              <div className="mb-3 row">
                <div className="col">
                  <label htmlFor="firstName" className="form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    name="firstName"
                    value={currentUser ? currentUser.firstName : ""}
                    onChange={(e) => {
                      // Handle form field changes
                      setCurrentUser({
                        ...currentUser,
                        firstName: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="col">
                  <label htmlFor="lastName" className="form-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    name="lastName"
                    value={currentUser ? currentUser.lastname : ""}
                    onChange={(e) => {
                      // Handle form field changes
                      setCurrentUser({
                        ...currentUser,
                        lastName: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  name="email"
                  value={currentUser ? currentUser.email : ""}
                  onChange={(e) => {
                    // Handle form field changes
                    setCurrentUser({
                      ...currentUser,
                      email: e.target.value,
                    });
                    handleEmailChange(e);
                  }}
                  style={{ borderColor: emailIsValid ? "initial" : "red" }}
                />
                {emailIsValid ? null : (
                  <span className="error">Enter a valid email address.</span>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Phone
                </label>
                <InputMask
                  // mask="(999) 999-9999"
                  type="tel"
                  name="phone"
                  className="form-control"
                  id="phone"
                  placeholder="(xxx) xxx-xxxx"
                  value={currentUser ? currentUser.phone : ""}
                  onChange={(e) => {
                    // Handle form field changes
                    setCurrentUser({
                      ...currentUser,
                      phone: e.target.value,
                    });
                    handlePhoneChange(e);
                  }}
                  style={{ borderColor: phoneIsValid ? "initial" : "red" }}
                />
              </div>
              {phoneIsValid ? null : (
                <span className="error">
                  Enter a valid phone number exactly 10 digits.
                </span>
              )}

              {currentUser ? (
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Status:
                  </label>
                  <span>
                    <FontAwesomeIcon
                      className="p-1"
                      style={{
                        backgroundColor: "#fcb43e",
                        color: "white",
                        fontSize: "20px",
                        borderRadius: "50%",
                        height: "14px",
                        width: "14px",
                      }}
                      icon={faLink}
                    />
                  </span>
                  <span style={{ marginLeft: "2px" }}>
                    {currentUser.status}
                  </span>
                </div>
              ) : (
                ""
              )}
              <div
                className="card-footer py-3 bg-transparent error-container"
                style={{ color: "red" }}
              >
                {errorType && (
                  <div className="" role="alert">
                    {errorType}
                  </div>
                )}
              </div>
              <div className="text-center">
                {/* <button
                      type="button"
                      className="btn btn-primary mx-2"
                      onClick={inviteLink}
                    >
                      &nbsp;&nbsp;&nbsp; Invite &nbsp;&nbsp;&nbsp;
                    </button> */}

                {/* <button
                      type="button"
                      className="btn btn-primary mx-2"
                      disabled
                    >
                      &nbsp;&nbsp;&nbsp; Invite &nbsp;&nbsp;&nbsp;
                    </button> */}

                <button
                  type="button"
                  className="btn btn-primary mx-2"
                  onClick={handleAddSubmit}
                  disabled={isButtonDisabled}
                >
                  &nbsp;&nbsp;&nbsp; Save &nbsp;&nbsp;&nbsp;
                </button>
              </div>
            </div>
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddADminUser;
