import React, { useEffect, useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const EmailEditer = ({ data, onDataChange }) => {
   const editorRef = useRef();

   const editorData = Array.isArray(data) ? data.join("") : data;

   useEffect(() => {
     if (editorRef.current && editorRef.current.editor) {
       const editorInstance = editorRef.current.editor;
       const currentData = editorInstance.getData();

       if (currentData !== editorData) {
         editorInstance.setData(editorData);
       }
     }
   }, [data, editorData]);

  return (
   <CKEditor
      editor={ClassicEditor}
      data={editorData}
      onReady={(editor) => {
        console.log('Editor is ready to use!', editor);
        editorRef.current = { editor };
      }}
      onChange={(event, editor) => {
        const newData = editor.getData();
        console.log({ event, editor, newData });
        if (onDataChange) {
          onDataChange(newData);
        }
      }}
      onBlur={(event, editor) => {
        console.log('Blur.', editor);
      }}
      onFocus={(event, editor) => {
        console.log('Focus.', editor);
      }}
    />

  );
};

export default EmailEditer;
