import React from "react";
import { Modal} from "react-bootstrap";
import { RingLoader } from "react-spinners";
//import './Spinner.css';


const Spinner = ({show}) => {
  if (!show) {
    return null;
  }

  return (
    <>
        <Modal show={show} className="d-flex align-items-center modelcolor" backdrop="static" keyboard={false}>
          <Modal.Body className="d-flex justify-content-center"  >
    
          <img src="image/spinner.gif" style={{width:"130px"}} />
          </Modal.Body>
        </Modal>
    </>
  );
};

export default Spinner;
