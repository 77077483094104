import React, { useState, useEffect } from "react";
import Sidebar from "../Header/Sidebar";
import RightSidebar from "../Layout/RightSidebar";
import { VixoAPI } from "../../settings";
import axios from "axios";
import Spinner from "../../Spinner";
import AdminNavbar from "../Navbar/AdminNavbar";
import HtmlEditor from "../HtmlEditor/HtmlEditor";
import { ToastContainer, toast } from "react-toastify";
import { APIHeader } from "../../APICallWrapper";
import { HasLoggedInUser } from "../../APICallWrapper";
import { useNavigate } from "react-router-dom";
function Consent() {
  const [loading, setLoading] = useState(false);
  const [IsConset, setIsConset] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [showContractData, setShowContractData] = useState("");
const navigate = useNavigate();
  const [vixocontaractId, setVixoContaractId] = useState(0);

  const handleToggle = () => {
    setIsConset(!IsConset);
    setShowEditor(!showEditor);
  };

  useEffect(() => {
    fetchContractData();
  }, []);

  function fetchContractData() {
     if (HasLoggedInUser()) {
       const OrgAPIPath = `${VixoAPI.AdminDashboardAPI}getVixoContractinAdm`;
       setLoading(true);
       axios
         .get(OrgAPIPath, {
           headers: APIHeader().headers,
         })
         .then((response) => {
           const responseData = response.data;
           if (responseData.statusCode === 200) {
             const contractData = responseData.data[0].contract;
             const contractId = responseData.data[0].id;
             const isActive = responseData.data[0].isActive;
             setVixoContaractId(contractId);
             setShowContractData(contractData);
             setIsConset(isActive);
           }
         })
         .catch((error) => {
           toast.error(error.message);
         })
         .finally(() => {
           setLoading(false);
         });
     } else {
       navigate("/");
     }
  }

  const handleUpdate = () => {
        if (HasLoggedInUser()) {
          const data = {
            Id: vixocontaractId,
            Contracts: showContractData,
          };
          const OrgAPIPath = `${VixoAPI.AdminDashboardAPI}updateVixoContractinAdm`;
          setLoading(true);
          axios
            .post(OrgAPIPath, data, {
              headers: APIHeader().headers,
            })
            .then((response) => {
              const responseData = response.data;
              if (responseData.statusCode === 200) {
                setShowContractData(responseData.data.contract);
                toast.success(responseData.message);
              } else {
                toast.error(responseData.message);
              }
            })
            .catch((error) => {
              toast.error(error.message);
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          navigate("/");
        }
  };

  const handleEditorDataChange = (newData) => {
    setShowContractData(newData);
  };

  return (
    <>
      <div id="main-wrapper">
        <AdminNavbar />
        <Sidebar />
        <Spinner show={loading} />
        <div className="content-body">
          <div className="container-fluid">
            <div className="row invoice-card-row mt-4">
              <div className="col-lg-9 col-xl-12">
                <div style={{ textAlignLast: "center" }}>
                  <img id="logo" src="image/3.png" style={{ width: "300px" }} />
                </div>
                <br></br>
                <div className="filter cm-content-box box-primary">
                  <div className="content-title flex-row">
                    <div className="cpa fs-24">
                      <h3 className="card-title fs-26">Vixo Consent </h3>
                    </div>
                    <div className="Editor">
                      <div className="Toggle_btn">
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={IsConset}
                            onClick={handleToggle}
                          />
                          
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div
                    className="cm-content-body form excerpt"
                    style={{ overflowY: "scroll", height: "420px" }}
                  >
                    <div className="table-responsive">
                      <div className="Html_Editor mt-2 ">
                        <HtmlEditor
                          data={showContractData}
                          onDataChange={handleEditorDataChange}
                        />
                      </div>

                      <button
                        style={{ backgroundColor: " rgb(148, 97, 142)" }}
                        className="btn btn-primary col-md-auto m-3  "
                        onClick={handleUpdate}
                      >
                        Update Consent
                      </button>
                    </div>
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Consent;
