// import React, { useState } from 'react';
// import { validateEmail } from './EmailValidator';
// import { validatePhone } from './PhoneValidator';
// import { validatePassword } from './PasswordValidator';


export function ValidateEmailGlobal(email) {
    // A more comprehensive email regular expression
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  
    // Additional checks for common email format rules
    const isValidFormat = emailRegex.test(email);
  
    // Check if the email ends with ".com" (case-insensitive)
    const endsWithCom = email.toLowerCase().endsWith('.com');
  
    // Return true only if both format and ".com" checks pass
    return isValidFormat && endsWithCom;
  }
  
  export function ValidatePhoneGlobal(phone) {
    // Remove all non-digit characters
    const cleanedPhone = phone.replace(/\D/g, '');
  
    // Check if the cleaned phone number has exactly 10 digits
    if (/^\d{10}$/.test(cleanedPhone)) {
      return true; // Phone number has exactly 10 digits
    } else {
      return false; // Phone number does not meet the criteria
    }
  }
  
  export function ValidatePasswordGlobal(password) {
    // Minimum 8 characters
    if (password.length < 8) {
      return false;
    }
  
    // At least 1 uppercase letter
    if (!/[A-Z]/.test(password)) {
      return false;
    }
  
    // At least 1 lowercase letter
    if (!/[a-z]/.test(password)) {
      return false;
    }
  
    // At least 1 digit
    if (!/\d/.test(password)) {
      return false;
    }
  
    // At least 1 special character (you can customize this character set)
    if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password)) {
      return false;
    }
  
    // If all criteria are met
    return true;
  }
  
  
  // function PasswordInput() {
  //   const [password, setPassword] = useState('');
  //   const [showPassword, setShowPassword] = useState(false);
  
  //   const togglePasswordVisibility = () => {
  //     setShowPassword(!showPassword);
  //   };
  
  //   const handlePasswordChange = (e) => {
  //     setPassword(e.target.value);
  //   };
  
  //   const passwordValidationMessage = validatePassword(password);
  
  //   if (isEmailValid) {
  //     console.log('Email is valid.');
  //   } else {
  //     console.error('Email is not valid.');
  //   }
  
  //   if (isPhoneValid) {
  //     console.log('Phone number is valid.');
  //   } else {
  //     console.error('Phone number is not valid.');
  //   }
  
  //   if (isPasswordValid) {
  //     console.log('Password is valid.');
  //   } else {
  //     console.error('Password is not valid.');
  //   }
  
  //   return (
  //     <div>
  //       <label>Password:</label>
  //       <input
  //         type={showPassword ? 'text' : 'password'}
  //         value={password}
  //         onChange={handlePasswordChange}
  //       />
  //       <span
  //         className="input-group-text"
  //         style={{ cursor: 'pointer' }}
  //         onClick={togglePasswordVisibility}
  //       >
  //         {showPassword ? (
  //           <span role="img" aria-label="Visible">
  //             👁️
  //           </span>
  //         ) : (
  //           <span role="img" aria-label="Hidden">
  //             🙈
  //           </span>
  //         )}
  //       </span>
  //       <div>{passwordValidationMessage}</div>
  //     </div>
  //   );
  // }
  
  // export default PasswordInput;
  