import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { VixoAPI } from "../../settings";
import Spinner from "../../Spinner";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";

const OTPVerification = ({
  ShowOTPPopup,
  OTPClosed,
  Action,
  OrganizationID,
  OTPVerified,
  setStep,
  Step,
}) => {
  const [loading, setLoading] = useState(false);
  const [UserEmail, setUserEmail] = useState(null);
  const [OTP, setOTP] = useState(null);
  const [emailError, setEmailError] = useState("");
  const [showOtp, setshowOtp] = useState(false);
  const [otpMatch, setotpMatch] = useState(null);
  const [passwordErrors, setPasswordErrors] = useState({
    length: "",
    digit: "",
    uppercase: "",
    lowercase: "",
    special: "",
  });

  const toggleShowPassword = () => {
    setshowOtp(!showOtp);
  };

  //  send otp
  const SendOtp = async (e) => {
    var encodedemail = encodeURIComponent(UserEmail);
    try {
      const SigninAPIPath = `${VixoAPI.AdminDashboardAPI}createOTP?email=${encodedemail}`;
      console.log("SigninAPIPath", SigninAPIPath);
      setLoading(true);

      const response = await axios.get(SigninAPIPath);
    

      if (response.status === 200) {
        toast.success(response.data.message);
        setStep(1);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Resend otp
  const ResendOtp = async (e) => {
    var encodedemail = encodeURIComponent(UserEmail);
    try {
      const SigninAPIPath = `${VixoAPI.AdminDashboardAPI}createOTP?email=${encodedemail}`;
      console.log("SigninAPIPath", SigninAPIPath);
      setLoading(true);

      const response = await axios.get(SigninAPIPath);


      if (response.status === 200) {
       toast.success(response.data.message);
        
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOTPChange = (e) => {
    const OTPValue = e.target.value;

    setOTP("");

    setOTP(OTPValue);
  };
 useEffect(()=>{
  LoadUser();
 })
  function LoadUser(){
    const loggedInUser = localStorage.getItem("User");
    var userObject = JSON.parse(loggedInUser);
    const localEmail = userObject.email;
    setUserEmail(localEmail);
  }

  

  useEffect(() => {
    setStep(0);
  }, []);
  // otp verication
  const handleVerifyOtp = (e) => {
    const loggedInUser = localStorage.getItem("User");
    var userObject = JSON.parse(loggedInUser);
   
    const data = {
      uid: userObject.id,
      otp: OTP,
    };

    try {
      const OtpVerifyOrganization = VixoAPI.AdminDashboardAPI + "verifyOTP";

      console.log("OtpVerifyOrganization", OtpVerifyOrganization);
      setLoading(true);
      console.log("data", data);

      axios
        .post(OtpVerifyOrganization, data)
        .then((response) => {
          var response = response.data;
          console.log("response", response);
          if (response.statusCode === 200) {
            OTPVerified(true, OrganizationID, Action);
            toast.success(response.message);
            setOTP("");
            setUserEmail("")
          } else {
            toast.error(response.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      <Modal show={ShowOTPPopup} onHide={OTPClosed}>
        <Spinner show={loading} />
        <Modal.Header closeButton>
          <Modal.Title>{Action}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Step === 0 && (
            <div className="d-flex flex-column emaildiv">
              <label htmlFor="organizationEmail" style={{ color: "white" }}>
                Organization Email:
              </label>

              <input
                style={{
                  borderColor: "white",
                  marginBlock: "4px",
                  borderRadius: "4px",
                  padding: "4px",
                  backgroundColor: "black",
                  color: "white",
                }}
                type="text"
                name="email"
                value={UserEmail}
                className={`form-control mt-2 mb-2 ${
                  emailError ? "is-invalid" : ""
                }`}
                placeholder="Enter Email Address"
                readOnly
              />
              {UserEmail && (
                <div className="invalid-feedback">{emailError}</div>
              )}
            </div>
          )}
          {Step === 1 && (
            <>
              <div className="d-flex flex-column emaildiv">
                <label htmlFor="organizationEmail" style={{ color: "white" }}>
                  Organization Email:
                </label>

                <input
                  style={{
                    borderColor: "white",
                    marginBlock: "4px",
                    borderRadius: "4px",
                    padding: "4px",
                    backgroundColor: "black",
                    color: "white",
                  }}
                  type="text"
                  name="email"
                  readOnly
                  value={UserEmail}
                  className={`form-control mt-2 mb-2 ${
                    emailError ? "is-invalid" : ""
                  }`}
                  placeholder="Enter Email Address"
                />
                {UserEmail && (
                  <div className="invalid-feedback">{emailError}</div>
                )}
              </div>

              <div className="OTP otpdiv">
                <label style={{ color: "white" }} className="fw-bold mb-2 mt-2">
                  Enter Six Digits Code
                </label>

                <div className="input-group">
                  <span className="input-group-text">
                    <img src="image/OTP.gif" style={{ width: "42px" }} />
                  </span>

                  <input
                    type={showOtp ? "text" : "password"}
                    name="Otp"
                    value={OTP}
                    className={`form-control ${
                      otpMatch === false ? "is-invalid" : ""
                    }`}
                    placeholder="Enter OTP"
                    onChange={handleOTPChange}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleVerifyOtp(e);
                      }
                    }}
                  />
                  <span
                    className="input-group-text"
                    style={{ cursor: "pointer" }}
                    onClick={() => toggleShowPassword()}
                  >
                    {showOtp ? (
                      <span role="img" aria-label="Visible">
                        👁️
                      </span>
                    ) : (
                      <span role="img" aria-label="Hidden">
                        🙈
                      </span>
                    )}
                  </span>
                </div>
                {passwordErrors.length && (
                  <div className="text-danger">{passwordErrors.length}</div>
                )}
                {passwordErrors.digit && (
                  <div className="text-danger">{passwordErrors.digit}</div>
                )}
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {Step === 0 && (
            <>
              <Button variant="secondary" onClick={OTPClosed}>
                Cancel
              </Button>
              <Button
                onClick={() => SendOtp()}
                className="d-flex align-items-center gap-1"
                variant="danger"
              >
                Send Otp
                {/* <img src="image/button.gif" style={{ width: "20px" }} /> */}
              </Button>
            </>
          )}
          {Step === 1 && (
            <>
              
              <Button variant="danger" onClick={() => ResendOtp()}>
                Resend Otp
              </Button>
              <Button variant="secondary" onClick={OTPClosed}>
                Cancel
              </Button>
              <Button
                onClick={() => handleVerifyOtp()}
                className="d-flex align-items-center gap-1"
                variant="success"
              >
                Verifiy Otp
                {/* <img src="image/button.gif" style={{ width: "20px" }} /> */}
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OTPVerification;