// store.js
import { configureStore } from "@reduxjs/toolkit";
import sendNotificationSlice from "../Slices/sendNotificationSlice";
const store = configureStore({
  reducer: {
    // Add other reducers
    notification:sendNotificationSlice
  },
});

export default store;
