import React, { useEffect} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashbaord from "./Component/Layout/Dashboard";
import AllOrganization from "./Component/User/AllOrganizations";
import PaymentUser from "./Component/User/PaymentUser";
import General from "./Component/User/General";
import UserManagement from "./Component/User/UserManagement";
import ResetPassword from "./Component/User/ResetPassword";
import ExceptionLogs from "./Component/ExceptionLogs";
import AdminLayout from "./Component/User/Layout/AdminLayout";
import Resetpassword from "./Component/Auth/ResetPassword";
import Signin from "./Component/Auth/Signin";
import MutliFactor from "./Component/Auth/MutliFactor";
import RightSidebar from "./Component/Layout/RightSidebar";
import EventsRecord from "./Component/EventsRecord";
import ForgetPassword from "./Component/Auth/ForgetPassword";
import Event from "./Component/Relic/Event";
import { useDispatch } from "react-redux";
import { VixoAPI } from "./settings";
import * as signalR from "@microsoft/signalr";
import addNotification from "./Component/Notifications/Slices/sendNotificationSlice";
import BillableCall from "./Component/Call/BillableCall";
import ReceivedPayments from "./Component/Call/ReceivedPayments";
import OpenBankingOCR from "./Component/Call/OpenBankOCR";
import VixoLinkOCR from "./Component/Call/VixoLinkOCR";
import DeletedOrganization from "./Component/Call/DeletedOrganization";
import RecentActivities from "./Component/Call/RecentActivities";
import VixoEmails from "./Component/Call/VixoEmails";
import EmailEdite from "./Component/Call/EmailEdite";
import CreateEmail from "./Component/Call/CreateEmail";
import Consent from "./Component/Consent/Conset";
import Features from "./Component/Call/Features";
const App = () => {
  // Signal R Connection is here
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   const connection = new signalR.HubConnectionBuilder()
  //     .withUrl(`${VixoAPI.NotificationAPI}notificationHub`) // Adjust the URL based on your server setup
  //     .build();

  //   connection
  //     .start()
  //     .catch((error) => console.error("SignalR Connection Error:", error));

  //   connection.on("ReceiveNotification", (receivedNotification) => {
  //     // sendNotificationToRedux(receivedNotification);
  //     const { organiationID, cutomerId, notificationType, referenceID } =
  //       receivedNotification;
  //       alert("received Notification From App.js Admin" , receivedNotification)
  //     sendNotificationToRedux(receivedNotification);

  //     // Handle the notification data as needed in your React application
  //   });

  //   function sendNotificationToRedux(receivedNotification) {
  //     console.log("Sending Notification to Redux:", receivedNotification);
  //     dispatch(addNotification(receivedNotification ));
  //     console.log('After dispatch AddNotification Func');
  //   }

  //   return () => {
  //     if (connection.state === signalR.HubConnectionState.Connected) {
  //       connection.stop();
  //     }
  //   };
  // }, [dispatch]);
  return (
    <>
      <Routes>
        <Route>
          <Route path="/" Component={Signin} />
          <Route path="/MutliFactor" Component={MutliFactor} />
        </Route>
        <Route path="/Dashboard" element={<Dashbaord />} />
        <Route path="/RightSidebar" element={<RightSidebar />} />
        <Route path="/AllOrganization" element={<AllOrganization />} />
        <Route path="/recentactivities" element={<RecentActivities />} />
        <Route path="/FundingRequest" element={<PaymentUser />} />
        <Route path="/General" element={<General />} />
        <Route path="/features" element={<Features />} />
        <Route path="/UserManagement" element={<UserManagement />} />
        <Route path="/vixoemail" element={<VixoEmails />} />
        <Route path="/CreateEmail" element={<CreateEmail />} />
        <Route path="/emailedit" element={<EmailEdite />} />
        <Route path="/ResetPassword" element={<ResetPassword />} />
        <Route path="/Adminnavbar" element={<ResetPassword />} />
        <Route path="/ExceptionLogs" element={<ExceptionLogs />} />
        <Route path="/Reset" element={<Resetpassword />} />
        <Route path="/forget" element={<ForgetPassword />} />
        <Route path="/Relic" element={<Event />} />
        <Route path="/EventsRecord" element={<EventsRecord />} />
        <Route path="/billablecall" element={<BillableCall />} />
        <Route path="/receivedpayment" element={<ReceivedPayments />} />
        <Route path="/openbank" element={<OpenBankingOCR />} />
        <Route path="/Ocrs" element={<VixoLinkOCR />} />
        <Route path="/deleteorganization" element={<DeletedOrganization />} />
        <Route path="/Consent" element={<Consent />} />
      </Routes>
    </>
  );
};
export default App;
