import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { VixoAPI } from "../../settings";
import Spinner from "../../Spinner";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";


export default function ResetPassword() {
  const [errorType, setErrorType] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  // Define success message style
  const successMessageStyle = {
    backgroundColor: 'green', // Customize the background color as needed
    color: 'white',           // Customize the text color as needed
    padding: '10px',          // Add padding for better visibility
    marginBottom: '10px',     // Add margin to separate it from other content
  };
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({
    password: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(null);
  const [passwordErrors, setPasswordErrors] = useState({
    length: "",
    digit: "",
    uppercase: "",
    lowercase: "",
    special: "",
  });
 
  // Define the 'key' state to store the value from the URL
  const [key, setKey] = useState(null);

  // Extract 'key' from the URL query parameters when the component mounts
  const location = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const keyFromParams = params.get("key");
    if (keyFromParams) {
      setKey(keyFromParams);
    }
  }, [location]);

  const handleUserInfoChange = (e) => {
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckPasswordMatch = () => {
    const isPasswordValid =
      userInfo.password === userInfo.confirmPassword;
    setPasswordsMatch(isPasswordValid);
  };

  useEffect(() => {
    handleCheckPasswordMatch();
  }, [userInfo.password, userInfo.confirmPassword]);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    try {
      var encodedPassword = userInfo.password;
      var encodedKey = encodeURIComponent(key);

      const ResetAPIPath = `${VixoAPI.AdminDashboardAPI}resetAdminPassword?Password=${encodedPassword}&Key=${encodedKey}`;
      setLoading(true);
      axios
        .get(ResetAPIPath)
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            setData(response.message);
          } else {
            setError(response.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      throw error;
    }
  };

 

  const validatePassword = (password) => {
    const errors = {
      length: "",
      digit: "",
      uppercase: "",
      lowercase: "",
      special: "",
    };

    if (password.length < 8) {
      errors.length = "Password must be at least 8 characters long.";
    }

    if (!/[0-9]/.test(password)) {
      errors.digit = "Password must contain at least one digit.";
    }

    if (!/[A-Z]/.test(password)) {
      errors.uppercase = "Password must contain at least one uppercase letter.";
    }

    if (!/[a-z]/.test(password)) {
      errors.lowercase = "Password must contain at least one lowercase letter.";
    }

    if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password)) {
      errors.special = "Password must contain at least one special character.";
    }

    setPasswordErrors(errors);
    return Object.values(errors).every((error) => error === "");
  };

  function setData(message) {
    // Display success message
    toast.success(message);
    setErrorType(null); // Clear error message
  }
 

  function setError(message) {
    setErrorType(message);
  }

  return (
    <>
      {/* {errorType && <ErrorComponent errorType={errorType} />} */}
      <Spinner show={loading} />
      {successMessage && (
        <div className="alert alert-success" style={successMessageStyle} role="alert">
          {successMessage}
        </div>
      )}
      <main className="authentication-content">
        <div
          className="container-fluid d-flex align-items-center justify-content-center vh-100"
          style={{ overflow: "hidden" }}
        >
          <div className="authentication-card">
            <div className="card shadow rounded-0 overflow-hidden">
              <div className="row g-0">
                <div className="col-lg-12">
                  <div className="card-body p-4 p-sm-5">
                    <form className="form-body">
                      <span className="m-5">
                        {" "}
                        <img
                          src="image/3.png"
                          className="logo-icon"
                          alt="logo icon"
                          style={{ width: "90%"}}
                        />
                      </span>
                      <h2 className="fw-bold">Reset Password</h2>
                      <br />

                      {/* Password input */}
                      <label className="fw-bold mb-2 mt-2">Password</label>

                      <div className="input-group">
                        <input
                          type={showPassword ? "text" : "password"}
                          name="password"
                          value={userInfo.password}
                          className={`form-control ${
                            passwordsMatch === false ? "is-invalid" : ""
                          }`}
                          placeholder="Enter Password"
                          onChange={(e) => {
                            handleUserInfoChange(e);
                            handleCheckPasswordMatch();
                            // Validate password on input change
                            validatePassword(e.target.value);
                          }}
                        />
                        <span
                          className="input-group-text"
                          style={{ cursor: "pointer" }}
                          onClick={() => toggleShowPassword()}
                        >
                          {showPassword ? (
                            <span role="img" aria-label="Visible">
                              👁️
                            </span>
                          ) : (
                            <span role="img" aria-label="Hidden">
                              🙈
                            </span>
                          )}
                        </span>
                      </div>
                      {passwordErrors.length && (
                        <div className="text-danger">
                          {passwordErrors.length}
                        </div>
                      )}
                      {passwordErrors.digit && (
                        <div className="text-danger">
                          {passwordErrors.digit}
                        </div>
                      )}
                      {passwordErrors.uppercase && (
                        <div className="text-danger">
                          {passwordErrors.uppercase}
                        </div>
                      )}
                      {passwordErrors.lowercase && (
                        <div className="text-danger">
                          {passwordErrors.lowercase}
                        </div>
                      )}
                      {passwordErrors.special && (
                        <div className="text-danger">
                          {passwordErrors.special}
                        </div>
                      )}
                      <br />

                      {/* Confirm Password input */}
                      <label className="fw-bold mb-2 mt-2"> Confirm Password</label>

                      <div className="input-group">
                        <input
                          type={showConfirmPassword ? "text" : "password"}
                          name="confirmPassword"
                          value={userInfo.confirmPassword}
                          className={`form-control ${
                            passwordsMatch === false ? "is-invalid" : ""
                          }`}
                          placeholder="Confirm Password"
                          onChange={(e) => {
                            handleUserInfoChange(e);
                            handleCheckPasswordMatch();
                          }}
                        />
                        <span
                          className="input-group-text"
                          style={{ cursor: "pointer" }}
                          onClick={() => toggleShowConfirmPassword()}
                        >
                          {showConfirmPassword ? (
                            <span role="img" aria-label="Visible">
                              👁️
                            </span>
                          ) : (
                            <span role="img" aria-label="Hidden">
                              🙈
                            </span>
                          )}
                        </span>
                      </div>
                      {passwordsMatch === false && (
                        <div className="invalid-feedback">
                          Passwords do not match
                        </div>
                      )}
                      <br />

                      {/* Submit button */}
                      <button
                        className="btn btn-primary form-control"
                        onClick={handleResetPassword}
                        disabled={loading || !passwordsMatch}
                      >
                        Reset Password
                      </button>
                    </form>
                    <br />
                    <div className="col-md-12">
                      Do you have an account?&nbsp;&nbsp;
                      <Link to="/" style={{ textDecoration: "none" }}>
                        Sign In
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </main>
    </>
  );
}
