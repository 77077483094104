import React from "react";
import { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { VixoAPI } from "../../../settings";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import InputMask from "react-input-mask";
import Spinner from "../../../Spinner";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import { AdmRoleTypes } from "../../.././Lookup";
import { APIHeader } from "../../../APICallWrapper";
import { HasLoggedInUser } from "../../../APICallWrapper";
const EditAdminUser = ({
  showModal,
  handleModalClose,
  editUser,
  onDeleteUser,
}) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorType, setErrorType] = useState(null);
  const [showPermissionErrorModal, setShowPermissionErrorModal] =
    useState(false);
    const navigate = useNavigate();
  const [AdmRoles, setAdmRoles] = useState([]);
  const [sendInvitation, setSendInvitation] = useState(false);
  const [formValid, setFormValid] = useState(false);
   const [emailValid, setEmailValid] = useState(true);
  const [currentUser, setCurrentUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    role: "",
    roleName: 0,
  });

  useEffect(() => {
    const validateForm = () => {
      if (
        (currentUser.firstName !== editUser?.firstName &&
          currentUser.firstName !== "") ||
        (currentUser.lastName !== editUser?.lastName &&
          currentUser.lastName !== "") ||
        (currentUser.email !== editUser?.email && currentUser.email !== "") ||
        (currentUser.phone !== editUser?.phone && currentUser.phone !== "") ||
        (currentUser.role !== editUser?.role && currentUser.role !== 0)
      ) {
        setFormValid(true);
      } else {
        setFormValid(false);
      }
    };
    validateForm();
  }, [currentUser, editUser]);









     

  const setEditUser = (e) => {
    const { name, value } = e.target;
    setCurrentUser({
      ...currentUser,
      [name]: value,
    });
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    try {
      const ForgotAPIPath = VixoAPI.AdminDashboardAPI + "forgot";
      setLoading(true);
      axios
        .get(ForgotAPIPath, {
          params: {
            email: currentUser.email,
          },
        })
        .then((response) => {
          var responseData = response.data;
          if (responseData.statusCode === 200) {
            toast.success(responseData.message);
          } else {
            setError(responseData.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      throw error;
    }
  };
  useEffect(() => {
    LoadLookups();
  }, []);

  async function LoadLookups() {
    try {
      AdmRoleTypes()
        .then((result) => {
          setAdmRoles(result);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (ex) {
      console.error(ex + " setAdmRoles Role Lookup Call is not successfull");
    }
  }
  useEffect(() => {
    if (showModal && editUser) {
      setCurrentUser({
        id: editUser.id,
        firstName: editUser.firstName,
        lastName: editUser.lastName,
        email: editUser.email,
        phone: editUser.phone,
        role: editUser.role,
      });
    } else {
      setCurrentUser({
        id: 0,
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        role: 0,
        roleName:"",
      });
    }
  }, [showModal, editUser]);

  const handleUpdateUser = async (e) => {
         if (HasLoggedInUser()) {
           e.preventDefault();
           const roleId = Number(currentUser.role);
           const selectedRole = AdmRoles.find((role) => role.id === roleId);

           if (!selectedRole) {
             setErrorType("Invalid role selected");
             return;
           }
           const data = {
             Id: editUser.id,
             organizationId: editUser.organizationId,
             firstName: currentUser.firstName,
             lastName: currentUser.lastName,
             email: currentUser.email,
             oldEmail: editUser.email,
             phone: currentUser.phone,
             role: selectedRole && selectedRole.id,
             organizationName: "",
             phoneIsConfirmed: true,
             emailIsConfirmed: true,
             password: "",
             name: "",
             roleName: selectedRole && selectedRole.displayValue,
             key: "",
           };
           try {
             const UserUpdate =
               VixoAPI.AdminDashboardAPI + "update?Id=" + editUser.id;
             setLoading(true);
             await axios
               .put(UserUpdate, data, {
                 headers: APIHeader().headers,
               })
               .then((response) => {
                 var response = response.data;
                 if (response.statusCode === 200) {
                   setData(response.data);
                   handleModalClose(response.data);
                   toast.success(response.message);
                 } else {
                   setError(response.message);
                 }
               })
               .catch((error) => {
                 setLoading(false);
                 throw error;
               })
               .finally(() => {
                 setLoading(false);
               });
           } catch (error) {
             throw error;
           }
         } else {
           navigate("/");
         }
  };
  function setData(data) {
    handleModalClose(data);
  }

  function setError(message) {
    setErrorType(message);
  }

  const confirmDelete = () => {
    onDeleteUser(editUser);
    setShowDeleteConfirmation(false);
    handleModalClose();
  };

  const handleDeleteUser = async (userToDelete) => {
     if (HasLoggedInUser()) {
       try {
         if (userToDelete.isAdmin) {
           confirmDelete();
           // handleModalClose();
         } else {
           const response = await axios.delete(
             `${VixoAPI.AdminDashboardAPI}deleteById`,
             {
               headers: APIHeader().headers,

               params: {
                 Id: userToDelete.id,
               },
             }
           );

           if (response.data.statusCode === 200) {
             // If the deletion was successful, update the state to reflect the change

             onDeleteUser(currentUser);
             toast.error(response.message);
             // handleEditModalClose();
             handleModalClose();
           } else {
             // Handle errors, show an alert, or update the UI accordingly
             // alert(`Failed to delete user: ${response.data.message}`);
           }
         }
       } catch (error) {
         // Handle network errors or unexpected issues
         console.error("Error deleting user:", error);
         handleModalClose();
       }
     } else {
       navigate("/");
     }
  };

  return (
    <>
      <Spinner show={loading} />
      <ToastContainer />
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title className="mx-auto">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center">
                  <span className="m-0">
                    {" "}
                    <img
                      src="image/3.png"
                      className="logo-icon  d-block mx-auto"
                      alt="logo icon"
                      style={{
                        width: "150px",
                        height: "70px",
                        display: "flex",
                        justifyContent: "center",
                        alignItemstems: "center",
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <div className="modal-body">
              <h5 className="text-center">Edit User</h5>
              <div className="mb-3">
                <label htmlFor="role" className="form-label">
                  Role
                </label>

                <select
                  className="form-select"
                  id="role"
                  name="role"
                  value={currentUser.role}
                  onChange={(e) => {
                    setEditUser(e);
                  }}
                >
                     <option  value={0}>sleect role</option>
                  {AdmRoles.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.displayValue}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3 row">
                <div className="col">
                  <label htmlFor="firstName" className="form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    name="firstName"
                    value={currentUser.firstName}
                    onChange={(e) => {
                      setEditUser(e);
                    }}
                  />
                </div>
                <div className="col">
                  <label htmlFor="lastName" className="form-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    name="lastName"
                    value={currentUser.lastName}
                    onChange={(e) => {
                      setEditUser(e);
                    }}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="Email"
                  name="email"
                  value={currentUser.email}
                  onChange={(e) => {
                    setEditUser(e);
                  }}
                />
                {!emailValid && (
                  <span style={{ color: "red" }}>Invalid email address</span>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="phone" className="form-label">
                  Phone
                </label>
                <InputMask
                  mask="(999) 999-9999"
                  type="tel"
                  className="form-control"
                  id="phone"
                  placeholder="+1 123 1234567"
                  name="phone"
                  value={currentUser.phone}
                  onChange={(e) => {
                    setEditUser(e);
                  }}
                />
              </div>
              <div className="form-check mb-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="inviteCheckbox"
                  checked={sendInvitation}
                  onChange={(e) => setSendInvitation(e.target.checked)}
                />
                <label className="form-check-label" htmlFor="inviteCheckbox">
                  Send Invitation
                </label>
              </div>
              {editUser ? (
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Status:
                  </label>
                  <span>
                    <FontAwesomeIcon
                      className="p-1"
                      style={{
                        backgroundColor: "#fcb43e",
                        color: "white",
                        fontSize: "20px",
                        borderRadius: "50%",
                        height: "14px",
                        width: "14px",
                      }}
                      icon={faLink}
                    />
                  </span>
                  <span style={{ marginLeft: "2px" }}>{editUser.status}</span>
                </div>
              ) : (
                ""
              )}
              <div className="text-center">
                <button
                  type="button"
                  className="btn btn-primary mx-2"
                  onClick={handleUpdateUser}
                  disabled={!formValid}
                >
                  &nbsp;&nbsp;&nbsp; Update &nbsp;&nbsp;&nbsp;
                </button>
                <button
                  type="button"
                  className="btn btn-primary mx-2"
                  onClick={handleResetPassword}
                >
                  <Link
                    to=""
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    Reset Password
                  </Link>
                </button>
                <Button
                  variant="btn btn-primary mx-2"
                  onClick={() => handleDeleteUser(currentUser)}
                >
                  &nbsp;&nbsp; Delete User &nbsp;&nbsp;
                </Button>
              </div>
            </div>
          </p>
        </Modal.Body>
      </Modal>

      {/* delete confirmation modal */}

      <Modal
        show={showDeleteConfirmation}
        onHide={() => setShowDeleteConfirmation(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this user?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDeleteConfirmation(false)}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* show error modal  */}

      <Modal
        show={showPermissionErrorModal}
        onHide={() => setShowPermissionErrorModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Permission Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>You don't have permission to delete this user.</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => setShowPermissionErrorModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditAdminUser;
